import { actionType } from '../constants';
import store from '../configuration/Store';

// getting all conversations
const initialState = {
  conversations: {},
  conversation_ids: [],
  loading: false,
  error: null,
  currentDisplay: 'live',
  unansweredCount: 0,
  currentAssigned: 'all'
};

export const conversation = (state = initialState, action) => {
  switch (action.type) {
    case actionType.LOAD_CONVERSATIONS_REQUEST:
      if (action.clearConversations) {
        return {
          ...state,
          conversations: {},
          conversation_ids: [],
          loading: true,
          error: null
        };
      } else {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    case actionType.LOAD_CONVERSATIONS_SUCCESS: {
      let conversations = { ...state.conversations, ...action.payload };
      return {
        ...state,
        loading: false,
        conversations,
        conversation_ids: Object.keys(conversations)
      };
    }
    case actionType.REMOVE_CONVERSATION: {
      let conversations = { ...state.conversations };
      delete conversations[action.payload.conversationId];
      return {
        ...state,
        loading: false,
        conversations,
        conversation_ids: Object.keys(conversations)
      };
    }
    case actionType.LOAD_CONVERSATIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case actionType.FILTER_CONVERSATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionType.FILTER_CONVERSATIONS_SUCCESS: {
      let conversations = {};
      action.payload.each(c => {
        conversations[c.unique_id] = c;
      });
      return {
        ...state,
        loading: false,
        conversations: conversations,
        conversation_ids: Object.keys(conversations)
      };
    }
    case actionType.FILTER_CONVERSATIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case actionType.SET_CURRENT_DISPLAY:
      return {
        ...state,
        currentDisplay: action.payload
      };
    case actionType.SET_CURRENT_ASSIGNED:
      return {
        ...state,
        currentAssigned: action.payload
      };

    case actionType.UNANSWERED_COUNT: {
      let count = state.unansweredCount;
      count = count + { added: 1, removed: -1, modified: 0 }[action.payload];
      if ('Notification' in window && action.payload === 'added' && !document.hasFocus()) {
        // Notification.requestPermission();
        // new Notification('You have new chat message on Autochat.io Inbox', {
        //   body: `${count} new chat(s) are waiting for reply`,
        //   icon: 'https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png',
        //   tag: 'autochatio-unanswered',
        //   renotify: false
        // });
      }
      return {
        ...state,
        unansweredCount: count
      };
    }
    default:
      return state;
  }
};

// getting all messages
const initialMessageState = {
  messages: [],
  pendingMessagesId: [],
  error: null,
  loadingMessages: false,
  fileUploadStatus: { bytesTransferred: 0, totalBytes: 0 },
  addedProductsToCarousel: []
};

export const message = (state = { ...initialMessageState }, action) => {
  // console.log("conversation", action.type)
  switch (action.type) {
    case actionType.LOAD_MESSAGE_REQUEST:
      if (action.clearOldMessages) {
        return {
          ...state,
          messages: [],
          error: null,
          loadingMessages: true
        };
      } else {
        return {
          ...state,
          loadingMessages: true
        };
      }

    case actionType.LOAD_MESSAGE_SUCCESS: {
      // Check if pendingMessagesId array is empty which means
      // it is an initial fetch. if it's not
      // then check fetched Messages ids (array) if any of their ids exist
      // in pendingMessagesId array. if it exists remove that id from pendingMessagesId array
      // and don't add that message into the state.messages and if it doesn't exists
      // it means it's a message from the other user, add that message to state.messages
      if (state.pendingMessagesId.length === 0) {
        if (
          !action.payload[0].messageId ||
          state.messages.findIndex(message => message.messageId === action.payload[0].messageId) < 0
        ) {
          if (action.newMessages) {
            return {
              ...state,
              messages: [...action.payload, ...state.messages],
              loadingMessages: false
              // messages: [...action.payload,...state.messages, ]
            };
          } else {
            return {
              ...state,
              messages: [...state.messages, ...action.payload],
              loadingMessages: false
            };
          }
        } else {
          let newState = {
            ...state,
            loadingMessages: false
          };
          if (action.newMessages === false) {
            const messages = Array.from(state.messages);
            const fetchedMessages = Array.from(action.payload);
            if (
              messages.findIndex(message => message.messageId === action.payload[0].messageId) >= 0
            ) {
              fetchedMessages.forEach(msg => {
                const isPresent =
                  state.messages.findIndex(message => message.messageId === msg.messageId) >= 0;
                if (!isPresent) messages.push(msg);
              });
              newState = { ...newState, messages };
            } else {
              newState = { ...newState, messages: [...messages, ...action.payload] };
            }
          }
          return newState;
        }
      } else {
        const messages = Array.from(state.messages);
        const pendingMessagesId = Array.from(state.pendingMessagesId);
        const fetchedMessages = Array.from(action.payload);

        fetchedMessages.forEach(msg => {
          const foundedPendingMessageIndex = state.pendingMessagesId.findIndex(
            id => id === msg.messageId
          );
          const isMessagePresent =
            state.messages.findIndex(message => message.messageId === msg.messageId) >= 0;
          if (foundedPendingMessageIndex < 0 && !isMessagePresent) {
            messages.push(msg);
          } else {
            pendingMessagesId.splice(foundedPendingMessageIndex, 1);
          }
        });

        const returnedVal = {
          ...state,
          messages,
          pendingMessagesId: [...pendingMessagesId],
          loadingMessages: false
        };
        return returnedVal;
      }
    }
    case actionType.LOAD_MESSAGE_LOCAL: {
      // debugger;
      const messageId = action.payload[0].messageId;
      return {
        ...state,
        messages: [...action.payload, ...state.messages],
        pendingMessagesId: [...state.pendingMessagesId, messageId]
      };
    }

    case actionType.LOAD_MESSAGE_FAILED:
      return {
        ...state,
        error: action.payload,
        loadingMessages: false,
        messages: []
      };
    case 'RESET_PENDING_MESSAGES':
      return {
        ...state,
        pendingMessagesId: []
      };
    case 'SET_FILE_UPLOAD_BYTES':
      return {
        ...state,
        fileUploadStatus: {
          bytesTransferred: action.payload.bytesTransferred,
          totalBytes: action.payload.totalBytes
        }
      };
    case 'SET_PRODUCTS_TO_CAROUSEL':
      return {
        ...state,
        addedProductsToCarousel: action.payload
      };
    default:
      return state;
  }
};

// active conversation
const initialActiveState = {
  conversation: {},
  uniqueId: null,
  activeUsers: {},
  picUrl: '',
  name: ''
};

export const activeConversation = (state = initialActiveState, action) => {
  // console.log("active-conversation", action)

  switch (action.type) {
    case actionType.ACTIVE_CONVERSATION:
      return {
        ...state,
        conversation: action.payload,
        uniqueId: action.payload && action.payload.unique_id
      };
    default:
      return state;
  }
};

// active conversation
const initialActiveHeaderState = {
  conversation: {}
};
export const activeHeaderConversation = (state = initialActiveHeaderState, action) => {
  // console.log("conversation-activeheader", action)
  switch (action.type) {
    case actionType.ACTIVE_HEADER_CONVERSATION:
      return {
        ...state,
        conversation: action.payload
      };
    default:
      return state;
  }
};
const initialSearchProductsState = {
  products: {},
  loading: false,
  error: false
};
export const searchProducts = (state = initialSearchProductsState, action) => {
  switch (action.type) {
    case actionType.GET_SEARCH_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionType.GET_SEARCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
        loading: false,
        error: false
      };
    case actionType.GET_SEARCH_PRODUCTS_ERROR:
      return {
        ...state,
        error: true,
        loading: false
      };
    default:
      return state;
  }
};
const initialCustomerOrdersState = {
  orders: {},
  loading: false,
  error: false
};

export const customerOrders = (state = initialCustomerOrdersState, action) => {
  switch (action.type) {
    case actionType.GET_CUSTOMER_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionType.GET_CUSTOMER_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        loading: false,
        error: false
      };
    case actionType.GET_CUSTOMER_ORDERS_ERROR:
      return {
        ...state,
        error: true,
        loading: false
      };
    default:
      return state;
  }
};

/*
const initialHandOverState = {
    conversation: {},
    assignedTo: {}
};

export const handoverConversation = (state = initialActiveHeaderState, action) => {

    // console.log("handoverConversation-", action, state)

    switch (action.type) {
       case actionType.HAND_OVER_CONVERSATION_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actionType.UPDATE_CONVERSATION_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case actionType.UPDATE_CONVERSATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionType.LOAD_CONVERSATION_ASSIGNEE:
            //console.log("Conversation Assigned To:", action.payload)
            return{
                conversation: action.payload.conversation
            };
        default:
            return state
    }
}
*/

const initialStoreState = {
  store: null
};

export const storeData = (state = initialStoreState, action) => {
  switch (action.type) {
    case actionType.LOAD_STORE_STATUS: {
      return { ...state, store: action.payload };
    }
    default:
      return state;
  }
};

const cannedInitialState = {
  cannedMessages: [],
  // [
  //   { id: '1', short_code: 'welcome to the store', message: 'Hi, Welcome to the shopify store.' },
  //   {
  //     id: '2',
  //     short_code: 'Thanks for visiting store',
  //     message: 'Thanks for visiting our store, Please visit again.'
  //   },
  //   {
  //     id: '3',
  //     short_code: 'Please give some details',
  //     message: 'Please provide some details to proceed with the order.'
  //   }
  // ],
  showCannedResponse: false,
  editCannedResponse: false,
  editCannedResponseId: null,
  deleteLoading: false,
  editLoading: false,
  addCannedMessageToInput: null
};

export const cannedMessages = (state = cannedInitialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionType.GET_CANNED_MESSAGES:
      return { ...state, cannedMessages: payload || [] };
    case actionType.TOGGLE_CANNED_MESSAGES:
      return { ...state, showCannedResponse: !state.showCannedResponse };
    case actionType.EDIT_CANNED_MESSAGE:
      return { ...state, editCannedResponse: true, editCannedResponseId: payload };
    case actionType.CLOSE_EDIT_CANNED_MESSAGE:
      return { ...state, editCannedResponse: false, editCannedResponseId: null };
    case actionType.DELETE_CANNED_MESSAGE_START:
      return { ...state, deleteLoading: true };
    case actionType.DELETE_CANNED_MESSAGE_DONE: {
      let cannedMessages = [...state.cannedMessages];
      cannedMessages = cannedMessages.filter(cm => cm.id !== payload);
      return { ...state, cannedMessages, deleteLoading: false };
    }
    case actionType.EDIT_CANNED_MESSAGE_START: {
      return { ...state, editLoading: true };
    }
    case actionType.EDIT_CANNED_MESSAGE_DONE: {
      let cannedMessages = [...state.cannedMessages];
      cannedMessages = cannedMessages.map(cm => {
        if (cm.id === payload.id) return payload;
        return cm;
      });
      return {
        ...state,
        cannedMessages,
        editLoading: false,
        editCannedResponse: false,
        editCannedResponseId: null
      };
    }
    case actionType.ADD_CANNED_MESSAGE_DONE: {
      let cannedMessages = [payload, ...state.cannedMessages];
      return {
        ...state,
        cannedMessages,
        editLoading: false,
        editCannedResponse: false,
        editCannedResponseId: null
      };
    }
    case actionType.CLEAR_CANNED_LOADER:
      return { ...state, editLoading: false, deleteLoading: false };
    case actionType.ADD_CANNED_MESSAGE_TO_INPUT: {
      return { ...state, addCannedMessageToInput: payload };
    }
    default:
      return state;
  }
};
