import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

import './index.css';

export default class Teammate extends Component {
  render() {
    let teammate = this.props.teammate;

    return (
      <div
        className="settings-object-list-item"
        onClick={() => this.props.handleTeammate(teammate)}
      >
        <span className="icon-avator">
          <Image
            src="http://www.personalbrandingblog.com/wp-content/uploads/2017/08/blank-profile-picture-973460_640-300x300.png"
            alt="Smiley face"
            height={30}
            width={30}
            circle
          />
        </span>

        <div className="action-labels">
          <span className="primary">{teammate.name}</span>

          <span className="fa-Bodytext -small -secondary">{teammate.email}</span>
        </div>

        <div className="secondry-label">
          {teammate.admin ? (
            <span className="fa-Bodytext -small -secondary">COMPANY ADMIN</span>
          ) : null}
        </div>
      </div>
    );
  }
}
