import React from 'react';
import { Image } from 'react-bootstrap';

export const IntegrationsLogoPath = {
  FacebookIntegration: require('../public/logos/facebook_messenger_logo.png'),
  WebhookIntegration: require('../public/logos/webhook_logo.png'),
  SkypeIntegration: require('../public/logos/skype_logo.png'),
  LineIntegration: require('../public/logos/line_logo.png'),
  TelegramIntegration: require('../public/logos/telegram_logo.png'),
  FlockIntegration: require('../public/logos/flock_logo.png'),
  StrideIntegration: require('../public/logos/stride_logo.png'),
  KikIntegration: require('../public/logos/kik_logo.png'),
  SlackIntegration: require('../public/logos/slack_logo.png'),
  TwitterIntegration: require('../public/logos/twitter_logo.png'),
  DialogFlowIntegration: require('../public/logos/dialog_flow_logo.png'),
  AndroidSdkIntegration: require('../public/logos/android_logo.png'),
  IosSdkIntegration: require('../public/logos/ios_logo.png'),
  WebSdkIntegration: require('../public/logos/nodejs.png'),
  CiscoSparkIntegration: require('../public/logos/cisco_spark_logo.jpg'),
  AmazonLexIntegration: require('../public/logos/amazon_lex_logo.png'),
  GmailIntegration: require('../public/logos/gmail.jpg'),
  InboxIntegration: require('../public/logos/autochat_logo.svg'),
  UserProfile: require('../public/logos/user_profile_logo.png'),
  WhatsappIntegration: require('../public/logos/2000px-WhatsApp.svg_-1.png')
};

export const IntegrationImage = props => {
  return (
    <Image
      src={IntegrationsLogoPath[props.integrationName]}
      classname={props.className}
      circle
      responsive
    />
  );
};

export const ImageWithName = props => {
  return (
    <div className="out-integration-name avatar">
      {props.name && props.name[0] ? props.name[0].toUpperCase() : ''}
    </div>
  );
};

export const ProfileImage = props => {
  if (IntegrationsLogoPath[props.name]) {
    return (
      <Image
        src={IntegrationsLogoPath[props.name]}
        className={props.className}
        style={props.style}
        circle
      />
    );
  } else if (
    props.conversation &&
    props.conversation.data &&
    props.conversation.data.inbox &&
    props.conversation.data.inbox.user &&
    props.conversation.data.inbox.user.image
  ) {
    return (
      <Image
        src={props.conversation.data.inbox.user.image}
        className="out-integration-user"
        style={props.style}
        circle
      />
    );
  } else if (props.image !== null && props.image !== undefined) {
    return (
      <Image src={props.image} className="user-integration" style={props.style} circle={'true'} />
    );
  } else {
    return <ImageWithName name={props.name} circle />;
  }
};
