import React from 'react';

import './index.css';

export default class Switch extends React.Component {
  constructor(props) {
    super(props);

    this._handleChange = this._handleChange.bind(this);
  }

  render() {
    return (
      <div>
        <label className="switch">
          <input
            ref="switch"
            checked={this.props.isAdmin}
            onChange={this._handleChange}
            className="switch"
            type="checkbox"
          />
          <span className="slider round" />
        </label>
      </div>
    );
  }

  _handleChange() {
    this.props.setAdmin(!this.props.isAdmin);
  }
}
