export const getFilterCannedMessages = (message_text, cannedMessages = []) => {
  message_text = message_text.trim();
  cannedMessages = cannedMessages || [];
  if (!message_text) return cannedMessages;
  let message_words = message_text
    .trim()
    .split(' ')
    .filter(key => key.length > 0)
    .map(key => key.toLowerCase());
  message_words.map(word => {
    cannedMessages = cannedMessages.filter(cm =>
      (cm.short_code || '').toLowerCase().includes(word)
    );
  });
  return cannedMessages;
};
