import React, { Component } from 'react';
import Messages from './Messages';
import ComposeMessageContainer from './ComposeMessageContainer';
import ChatDetailHeader from './ChatDetailHeader';
import { connect } from 'react-redux';
import { getMessages, loadMessagesOnRequest } from '../actions/Conversation';

class MessageDetailContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showsize: false,
      showEmoji: true
    };
    //   size: '14px'
    // };
    this.handleChatScroll = this.handleChatScroll.bind(this);
    // this.handleSmallSizeClick = this.handleSmallSizeClick.bind(this);
    // this.handleMediumSizeClick = this.handleMediumSizeClick.bind(this);
    // this.handleLargeSizeClick = this.handleLargeSizeClick.bind(this);
  }
  handleChatScroll = e => {
    if (e.target.scrollTop === e.target.scrollHeight - e.target.clientHeight) {
      this.setState({ showsize: true, showEmoji: true });
    } else {
      this.setState({ showsize: false, showEmoji: false });
    }
    if (e.target.scrollTop == 0) {
      this.props.loadMessagesOnRequest(this.props.conversation.unique_id);
    }
  };
  scrollToBottom = behavior => {
    let scrollEl = document.querySelector('.chat-detail-body');
    scrollEl && scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' });
  };
  scrollButton() {
    setTimeout(() => this.scrollToBottom('smooth'), 2);
  }
  componentDidMount() {
    this.setState({ showsize: true });
  }
  componentWillUnmount() {
    let scrollEl = document.querySelector('.chat-detail-body');
    scrollEl && scrollEl.removeEventListener('scroll', () => {});
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.conversation &&
      this.props.conversation &&
      this.props.conversation.unique_id &&
      prevProps.conversation.unique_id !== this.props.conversation.unique_id
    ) {
      this.props.getMessages(this.props.conversation.unique_id);
    }
  }

  render() {
    let scrollBtn = document.querySelector('.scroll-down');
    let scrollEl = document.querySelector('.chat-detail-body');
    scrollBtn &&
      scrollEl &&
      scrollEl.addEventListener('scroll', () => {
        if (scrollEl.clientHeight - scrollEl.scrollHeight) {
          let scrolled_to_top =
            scrollEl.scrollTop <= Math.abs(scrollEl.scrollHeight - scrollEl.offsetHeight - 10);
          let scroll_style = 'block';
          if (!scrolled_to_top) scroll_style = 'none';
          scrollBtn.style.display = scroll_style;
        }
      });

    const { fileUploadStatus } = this.props;
    let progressStyle;
    if (fileUploadStatus.bytesTransferred !== fileUploadStatus.totalBytes) {
      let progressPercent = (
        (fileUploadStatus.bytesTransferred / fileUploadStatus.totalBytes) *
        100
      ).toFixed(1);
      progressStyle = {
        width: 100 + '%'
      };
    }

    if (
      this.props.conversation &&
      (this.props.conversation.inIntegration || this.props.conversation.data)
    ) {
      return (
        <div className="box full-height">
          <div className="chat-detail-header">
            <ChatDetailHeader
              conversation={this.props.conversation}
              uniqueId={this.props.uniqueId}
            />
          </div>
          <div className="chat-detail-body scroll-y" onScroll={this.handleChatScroll}>
            <Messages conversation={this.props.conversation} />
          </div>

          <div className="chat-detail-footer">
            <div
              className="scroll-down"
              onClick={e => {
                this.scrollButton();
              }}
            />
            <ComposeMessageContainer
              users={this.props.users}
              currentUser={this.props.currentUser}
              conversation={this.props.conversation}
              showsize={this.state.false}
              showEmoji={this.state.showEmoji}
            />
          </div>
          {fileUploadStatus.bytesTransferred !== fileUploadStatus.totalBytes ? (
            <div className="fileUploadProgress">
              <div className="progress ">
                <div
                  className="progress-bar progress-bar-striped bg-success progress-bar-animated text-center"
                  role="progressbar"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={progressStyle}
                >
                  <span className="progress-msg">Processing Uploaded File...</span>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      );
    } else if (this.props.conversation && this.props.conversation.notAvailable) {
      return <p className="errorMessage">There are no active conversations</p>;
    } else {
      return <p className="errorMessage">Select a conversation from left to see details</p>;
    }
  }
}

const mapStateToProps = state => {
  return {
    conversation: state.activeConversation.conversation,
    uniqueId: state.activeConversation.uniqueId,
    activeHeaderConversation: state.activeHeaderConversation.conversation,
    status: state.activeConversation.status,
    users: state.users.users,
    fileUploadStatus: state.message.fileUploadStatus
  };
};

export default connect(mapStateToProps, { getMessages, loadMessagesOnRequest })(
  MessageDetailContainer
);
