import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import TeammateOverview from './TeammateOverview';
import TeamsAccess from './TeamsAccess';

import './index.css';

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teammate: {},
      activeComponent: null
    };

    this.onHandleSelection = this.onHandleSelection.bind(this);
  }

  componentDidMount() {
    this.setState.teammate = this.props.teammate;

    this.setState({
      teammate: this.props.teammate
    });

    this.onHandleSelection(<TeammateOverview teammate={this.props.teammate} />);
  }

  onHandleSelection(component) {
    this.setState({ activeComponent: component });
  }

  render() {
    return (
      <div className="dashboard">
        <Row>
          <Col className="profile-column" ms={3} sm={3}>
            <div className="profile-options">
              <div
                onClick={() =>
                  this.onHandleSelection(<TeammateOverview teammate={this.props.teammate} />)
                }
              >
                Overview
              </div>
              <div
                onClick={() =>
                  this.onHandleSelection(<TeamsAccess teammate={this.props.teammate} />)
                }
              >
                Teams
              </div>
            </div>
          </Col>

          <Col
            ms={9}
            sm={9}
            style={{
              backgroundColor: '#fff',
              minHeight: 700
            }}
          >
            {this.state.activeComponent}
          </Col>
        </Row>
      </div>
    );
  }
}
