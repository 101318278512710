import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConversationTabs from './ConversationTabs';
import ConversationFilters from './ConversationFilters';
import { getStoreKey } from '../helpers/Common';
import { getConversations, unansweredConversations } from '../actions/Conversation';
import autochat from '../public/icons/main-icon-small.svg';
import CheckBox from './CheckBox/CheckBox';
// import './toggleSwitch.scss';
class ConversationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: this.props.isLogin,
      filters: {
        assignedTo: { name: 'all' },
        chat_type: 'all'
      },
      chat_only: false,
      live_visitors: false,
      loaded: false
    };
    this.changeChatOnlyCheckbox = this.changeChatOnlyCheckbox.bind(this);
    this.changeLiveVisitorsCheckbox = this.changeLiveVisitorsCheckbox.bind(this);
  }

  getFilters = () => {
    return {
      ...this.state.filters,
      chat_only: this.state.chat_only,
      live_visitors: this.state.live_visitors
    };
  };

  getFilteredConversations = inbox => {
    this.props.getConversations(inbox || this.props.inbox, this.getFilters());
  };

  componentDidMount() {
    let session = JSON.parse(localStorage.getItem(getStoreKey()));
    if (session != null && session.inbox != null) {
      this.getFilteredConversations(session.inbox);
    }
    this.setState({ loaded: true });
  }

  changeChatOnlyCheckbox = () => {
    let chat_only = !this.state.chat_only;
    this.setState({ chat_only }, () => {
      this.getFilteredConversations();
    });
    // if (checked) this.props.getConversations(this.props.inbox, this.state.liveObj);
    // else if (this.state.loaded) this.props.getConversations(this.props.inbox, this.state.allObj);
  };
  changeLiveVisitorsCheckbox = () => {
    let live_visitors = !this.state.live_visitors;
    this.setState({ live_visitors }, () => {
      this.getFilteredConversations();
    });
    // if (checked) this.props.getConversations(this.props.inbox, this.state.liveObj);
    // else if (this.state.loaded) this.props.getConversations(this.props.inbox, this.state.allObj);
  };

  changeFilters = ({ ...filters }) => {
    this.setState({ filters }, () => {
      this.getFilteredConversations();
    });
  };

  render() {
    return (
      <div className="full-height">
        <div className="chat-filter-box">
          <span className="inbox-logo">
            <img src={autochat}></img>
            {'  '}autochat.io
          </span>
          <div className="session-filter">
            <div className="conversation-filter-only-chat">
              <CheckBox checked={this.state.chat_only} onClick={this.changeChatOnlyCheckbox} />
              <span>Show Only Chats</span>
            </div>
            <div className="conversation-filter-live-visitors">
              <CheckBox
                checked={this.state.live_visitors}
                onClick={this.changeLiveVisitorsCheckbox}
              />
              <span>Live Shoppers</span>
            </div>
          </div>
          <ConversationFilters
            onChange={this.changeFilters}
            filters={this.state.filters}
            chatOnly={this.state.chat_only}
          />
        </div>
        <div className="chat-tabs-box">
          <ConversationTabs filters={this.getFilters()} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    //unansweredConversationsCount: state.conversation.unansweredCount,
    inbox: state.session.session.inbox || {}
  };
};
export default connect(mapStateToProps, { unansweredConversations, getConversations })(
  ConversationContainer
);
