import React from 'react';
import { stringify } from 'uuid';

export default class ContentEditable extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = null;
    this.state = {
      el: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.setEndOfContenteditable = this.setEndOfContenteditable.bind(this);
    this.onClick = this.onClick.bind(this);
    this.setCursor = this.setCursor.bind(this);
  }
  componentDidMount() {
    const el = document.querySelector('div.editableDiv');
    this.setState({ el: el });
  }
  shouldComponentUpdate(nextProps) {
    //debugger;
    return this.state.el.innerHTML !== nextProps.html || nextProps.clearInput === true;
  }
  setEndOfContenteditable() {
    let el = this.state.el;
    //el.focus();
    if (typeof window.getSelection != 'undefined' && typeof document.createRange != 'undefined') {
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange != 'undefined') {
      var textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  }
  componentDidUpdate(prevProps) {
    //console.log(document.getSelection());
    //debugger;
    const selection = document.getSelection();
    if (
      selection.focusNode != null &&
      selection.focusNode.tagName === 'DIV' &&
      selection.focusNode.className === 'attachments'
    ) {
      this.setEndOfContenteditable(this.state.el);
    }
    //TODO:
    // if (
    //   this.props.clearInput === true &&
    //   this.state.el !== '' &&
    //   this.state.el.innerHTML &&
    //   this.state.el.innerHTML !== ''
    // ) {
    //   // TODO change to setState
    //   let val = this.state.el;
    //   val.innerHTML = '';
    //   this.setState({ el: val });
    // }

    //
  }

  handleChange(e) {
    this.props.onChange(e.target.innerHTML, e.target.innerText);
  }
  onClick() {
    const selection = document.getSelection();
    // console.log("selection tagname",selection.focusNode.tagName);
    // console.log('base off set',selection.baseOffset,this.state.el.innerText.length);
    if (selection.focusNode != null && selection.focusNode.tagName === 'SPAN') {
      this.setEndOfContenteditable();
    } else if (selection.baseOffset === this.state.el.innerText.length) {
      this.setEndOfContenteditable();
    }
  }
  setCursor(e) {
    //console.log(e.target)
    if (e.target.innerText !== '') {
      this.setEndOfContenteditable();
    }
  }
  render() {
    return (
      <div
        contentEditable="true"
        placeholder={`${this.props.current === 'message' ? 'Type message' : 'Add a Note'}`}
        onInput={this.handleChange}
        onKeyDown={this.props.keyFunctions}
        dangerouslySetInnerHTML={{ __html: this.props.html }}
        className={this.props.className}
        spellCheck={false}
        // onBlur={this.setCursor}
        // onFocus={e => this.props.onFocus && this.props.onFocus(e)}
        onClick={this.onClick}
        id="writing-area"
        style={
          this.props.current === 'message'
            ? { width: '70%' }
            : { width: '65%', background: 'rgba(247, 247, 102, 0.3)' }
        }
      />
    );
  }
}
