import React from 'react';
import store from '../configuration/Store';
import { actionType } from '../constants';

export default function SubscriptionNotification({ notice }) {
  const onClick = e => {
    // if (['button'].includes(e.target.tagName.toLowerCase()))
    store.dispatch({ type: actionType.CLOSE_SUBSCRIPTION_NOTIFICATION });
  };
  return (
    <div className="subscription-notification">
      <div className={'notification-area'}>
        <div
          className="notification-content"
          dangerouslySetInnerHTML={{
            __html: notice
          }}
        ></div>
        <button className={'notification-close-button'} onClick={onClick}>
          x
        </button>
      </div>
    </div>
  );
}
