import {
  CREATE_TEAM_BEGIN,
  CREATE_TEAM_FAILURE,
  CREATE_TEAM_SUCCESS,
  GET_TEAMMATES_BEGIN,
  GET_TEAMMATES_FAILURE,
  GET_TEAMMATES_SUCCESS,
  GET_TEAMS_BEGIN,
  GET_TEAMS_FAILURE,
  GET_TEAMS_SUCCESS
} from '../actions';

// getting all teams
const initialTeamState = {
  teams: [],
  loading: false,
  error: null
};

export const teams = (state = initialTeamState, action) => {
  switch (action.type) {
    case GET_TEAMS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case GET_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        teams: action.payload.teams
      };

    case GET_TEAMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        teams: []
      };

    default:
      return state;
  }
};

// gettings all teams after creation
export const createdTeams = (state = initialTeamState, action) => {
  switch (action.type) {
    case CREATE_TEAM_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case CREATE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        teams: action.payload.teams
      };

    case CREATE_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        teams: []
      };

    default:
      return state;
  }
};

// gettings all teams after creation

const initialEditTeamState = {
  success: false,
  loading: false,
  error: null
};
export const editTeam = (state = initialEditTeamState, action) => {
  switch (action.type) {
    case CREATE_TEAM_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case CREATE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true
      };

    case CREATE_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        success: false
      };

    default:
      return state;
  }
};

// gettings all teammates

const initialTeammatesState = {
  teammates: [],
  loading: false,
  error: null
};
export const teammates = (state = initialTeammatesState, action) => {
  switch (action.type) {
    case GET_TEAMMATES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case GET_TEAMMATES_SUCCESS:
      return {
        ...state,
        loading: false,
        teammates: action.payload.teammates
      };

    case GET_TEAMMATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        teammates: []
      };

    default:
      return state;
  }
};
