export const actionType = {
  GET_SESSION_REQUEST: 'GET_SESSION_REQUEST',
  GET_SESSION_SUCCESS: 'GET_SESSION_SUCCESS',
  GET_SESSION_FAILED: 'GET_SESSION_FAILED',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_FAILED: 'LOGOUT_FAILED',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',

  USER_UPDATE_STARTED: 'USER_UPDATE_STARTED',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  USER_UPDATE_FAIL: 'USER_UPDATE_FAIL',
  CLEAR_USER_UPDATE: 'CLEAR_USER_UPDATE',

  GET_INBOXES_REQUEST_WITHOUT_SLUG: 'GET_INBOXES_REQUEST_WITHOUT_SLUG',
  GET_INBOXES_SUCCESS_WITHOUT_SLUG: 'GET_INBOXES_SUCCESS_WITHOUT_SLUG',
  GET_INBOXES_FAILED_WITHOUT_SLUG: 'GET_INBOXES_FAILED_WITHOUT_SLUG',

  SET_CURRENT_ASSIGNED: 'SET_CURRENT_ASSIGNED',

  LOAD_SECTIONS_REQUEST: 'LOAD_SECTIONS_REQUEST',
  LOAD_SECTIONS_SUCCESS: 'LOAD_SECTIONS_SUCCESS',
  LOAD_SECTIONS_FAILED: 'LOAD_SECTIONS_FAILED',
  ADD_SECTION_SUCCESS: 'ADD_SECTION_SUCCESS',
  ADD_SECTION_REQUEST: 'ADD_SECTION_REQUEST',
  ADD_SECTION_FAILED: 'ADD_SECTION_FAILED',

  CREATE_TODO_REQUEST: 'CREATE_TODO_REQUEST',
  CREATE_TODO_SUCCESS: 'CREATE_TODO_SUCCESS',
  CREATE_TODO_FAILED: 'CREATE_TODO_FAILED',

  LOAD_CONVERSATIONS_REQUEST: 'LOAD_CONVERSATIONS_REQUEST',
  LOAD_CONVERSATIONS_SUCCESS: 'LOAD_CONVERSATIONS_SUCCESS',
  LOAD_CONVERSATIONS_FAILED: 'LOAD_CONVERSATIONS_FAILED',
  UNANSWERED_COUNT: 'UNANSWERED_COUNT',

  ADD_CONVERSATION_SUCCESS: 'ADD_CONVERSATION_SUCCESS',
  ADD_CONVERSATION_REQUEST: 'ADD_CONVERSATION_REQUEST',
  ADD_CONVERSATION_FAILED: 'ADD_CONVERSATION_FAILED',

  REMOVE_CONVERSATION: 'REMOVE_CONVERSATION',

  FILTER_CONVERSATIONS_REQUEST: 'FILTER_CONVERSATIONS_REQUEST',
  FILTER_CONVERSATIONS_SUCCESS: 'FILTER_CONVERSATIONS_SUCCESS',
  FILTER_CONVERSATIONS_FAILED: 'FILTER_CONVERSATIONS_FAILES',

  LOAD_CONVERSATION_ASSIGNEE: 'LOAD_CONVERSATION_ASSIGNEE',

  HAND_OVER_CONVERSATION_REQUEST: 'UPDATE_CONVERSATION_REQUEST',
  HAND_OVER_CONVERSATION_SUCCESS: 'UPDATE_CONVERSATION_SUCCESS',
  HAND_OVER_CONVERSATION_FAILED: 'UPDATE_CONVERSATION_FAILED',

  LOAD_MESSAGE_REQUEST: 'LOAD_MESSAGE_REQUEST',
  LOAD_MESSAGE_SUCCESS: 'LOAD_MESSAGE_SUCCESS',
  LOAD_MESSAGE_FAILED: 'LOAD_MESSAGE_FAILED',
  LOAD_MESSAGE_LOCAL: 'LOAD_MESSAGE_LOCAL',
  ACTIVE_CONVERSATION: 'ACTIVE_CONVERSATION',
  ACTIVE_HEADER_CONVERSATION: 'ACTIVE_HEADER_CONVERSATION',

  FIRBASE_SIGNIN_REQUEST: 'FIRBASE_SIGNIN_REQUEST',
  FIRBASE_SIGNIN_SUCCESS: 'FIRBASE_SIGNIN_SUCCESS',
  FIRBASE_SIGNIN_FAILED: 'FIRBASE_SIGNIN_FAILED',

  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILED: 'GET_USERS_FAILED',

  GET_OUT_INTEGRATIONS_REQUEST: 'GET_OUT_INTEGRATIONS_REQUEST',
  GET_OUT_INTEGRATIONS_SUCCESS: 'GET_OUT_INTEGRATIONS_SUCCESS',
  GET_OUT_INTEGRATIONS_FAILED: 'GET_OUT_INTEGRATIONS_FAILED',

  SET_CURRENT_DISPLAY: 'SET_CURRENT_DISPLAY',
  UPDATE_CONVERSATION_STATUS_SUCCESS: 'UPDATE_CONVERSATION_STATUS_SUCCESS',

  SESSION_EXPIRY: 'SESSION_EXPIRY',

  GET_INBOXES_SUCCESS: 'GET_INBOXES_SUCCESS',
  GET_INBOXES_REQUEST: 'GET_INBOXES_REQUEST',
  GET_INBOXES_FAILED: 'GET_INBOXES_FAILED',

  GET_SEARCH_PRODUCTS_REQUEST: 'GET_SEARCH_PRODUCTS_REQUEST',
  GET_SEARCH_PRODUCTS_SUCCESS: 'GET_SEARCH_PRODUCTS_SUCCESS',
  GET_SEARCH_PRODUCTS_ERROR: 'GET_SEARCH_PRODUCTS_ERROR',

  GET_CUSTOMER_ORDERS_REQUEST: 'GET_CUSTOMER_ORDERS_REQUEST',
  GET_CUSTOMER_ORDERS_SUCCESS: 'GET_CUSTOMER_ORDERS_SUCCESS',
  GET_CUSTOMER_ORDERS_ERROR: 'GET_CUSTOMER_ORDERS_ERROR',

  GET_CURRENT_CUSTOMER_PENDING: 'GET_CURRENT_CUSTOMER_PENDING',
  GET_CURRENT_CUSTOMER_SUCCESS: 'GET_CURRENT_CUSTOMER_SUCCESS',
  GET_CURRENT_CUSTOMER_FAILED: 'GET_CURRENT_CUSTOMER_FAILED',
  GET_CURRENT_CUSTOMER_INITIAL: 'GET_CURRENT_CUSTOMER_INITIAL',

  SET_FILE_UPLOAD_BYTES: 'SET_FILE_UPLOAD_BYTES',

  SET_PRODUCTS_TO_CAROUSEL: 'SET_PRODUCTS_TO_CAROUSEL',

  LOAD_STORE_STATUS: 'LOAD_STORE_STATUS',
  TOGGLE_CANNED_MESSAGES: 'TOGGLE_CANNED_MESSAGES',
  EDIT_CANNED_MESSAGE: 'EDIT_CANNED_MESSAGE',
  CLOSE_EDIT_CANNED_MESSAGE: 'CLOSE_EDIT_CANNED_MESSAGE',
  DELETE_CANNED_MESSAGE_START: 'DELETE_CANNED_MESSAGE_START',
  DELETE_CANNED_MESSAGE_DONE: 'DELETE_CANNED_MESSAGE_DONE',
  EDIT_CANNED_MESSAGE_START: 'EDIT_CANNED_MESSAGE_START',
  EDIT_CANNED_MESSAGE_DONE: 'EDIT_CANNED_MESSAGE_DONE',
  ADD_CANNED_MESSAGE_DONE: 'ADD_CANNED_MESSAGE_DONE',
  GET_CANNED_MESSAGES: 'GET_CANNED_MESSAGES',
  CLEAR_CANNED_LOADER: 'CLEAR_CANNED_LOADER',
  ADD_CANNED_MESSAGE_TO_INPUT: 'ADD_CANNED_MESSAGE_TO_INPUT',

  CLOSE_SUBSCRIPTION_NOTIFICATION: 'CLOSE_SUBSCRIPTION_NOTIFICATION',
  LOAD_STORE_STATUS: 'LOAD_STORE_STATUS'
};

export const messageType = {
  CAROUSEL: 'carousel',
  IMAGE: 'image',
  Location: 'location',
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  DOCUMENT: 'DOCUMENT',
  FILES: 'FILES'
};
export const updateTime = {
  typing: 60,
  viewing: 180
};
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
