import React from 'react';
export default class DocumentView extends React.Component {
  componentDidMount() {
    //<p>{this.props.media.url.slice(this.props.media.url.lastIndexOf('.')+1,this.props.media.url.length).toUpperCase()}</p>
    /*
					<a href = {this.props.media.url} download> 
						<img src = "/thumbnails/down-arrow.svg" className = "downloadDocument"/>
					</a>
		*/
  }
  render() {
    return (
      <div className="documentView">
        <div className="document-thumbnail-preview">
          <a target="none" href={this.props.media.url}>
            <div className="mainDiv">
              <img
                src="/thumbnails/google-drive-pdf-file.svg"
                className="pdfImage"
                alt={'google drive thumbnail'}
              />
            </div>
            <div className="onHoverDiv">
              <img src="/thumbnails/pdf.svg" alt={'thumbnail'} />
              <p>
                {this.props.name ||
                  this.props.media.url.slice(
                    this.props.media.url.lastIndexOf('/') + 1,
                    this.props.media.url.length
                  )}
              </p>
            </div>
          </a>
          <div className="afterElement" />
        </div>
      </div>
    );
  }
}
