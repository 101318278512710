import { actionType } from '../constants';
import { usersPath } from '../helpers/Common';

// getting session
const initialState = {
  session: {},
  loading: false,
  error: null,
  logoutLoading: false,
  userUpdate: {},
  closeNotification: false
};

export const session = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_SESSION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionType.GET_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        session: action.payload
      };
    case actionType.GET_SESSION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        session: {}
      };
    case actionType.SESSION_EXPIRY:
      return {
        ...state,
        error: action.payload
      };
    case actionType.LOGOUT_REQUEST:
      return {
        ...state,
        logoutLoading: true
      };
    case actionType.LOGOUT_FAILED: {
      return {
        ...state,
        logoutLoading: false
      };
    }
    case actionType.LOGOUT_SUCCESS: {
      return {
        ...state,
        logoutLoading: false
      };
    }
    case actionType.USER_UPDATE_STARTED: {
      let userUpdate = { loading: true };
      return { ...state, userUpdate };
    }
    case actionType.USER_UPDATE_SUCCESS: {
      let session = { ...state.session };
      session.user = { ...action.payload.user };
      let userUpdate = { loading: false, message: 'Updated successfully...', success: true };
      return { ...state, session, userUpdate };
    }
    case actionType.USER_UPDATE_FAIL: {
      let userUpdate = { loading: false, error: action.payload, success: false };
      return { ...state, userUpdate };
    }
    case actionType.CLEAR_USER_UPDATE: {
      return { ...state, userUpdate: {} };
    }
    case actionType.CLOSE_SUBSCRIPTION_NOTIFICATION:
      return { ...state, closeNotification: true };
    default:
      return state;
  }
};

// firebase custom token signin
const initialFirebaseState = {
  isFirebaseLoggedIn: false,
  loading: false,
  error: null
};

export const firebaseSignin = (state = initialFirebaseState, action) => {
  switch (action.type) {
    case actionType.FIRBASE_SIGNIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionType.FIRBASE_SIGNIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isFirebaseLoggedIn: true
      };
    case actionType.FIRBASE_SIGNIN_FAILED:
      //debugger;
      return {
        ...state,
        loading: false,
        error: action.payload,
        isFirebaseLoggedIn: false
      };
    default:
      return state;
  }
};

// firebase custom token signin
const initialUserState = {
  users: [],
  loading: false,
  error: null
};

export const users = (state = initialUserState, action) => {
  switch (action.type) {
    case actionType.GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionType.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.users
      };
    case actionType.GET_USERS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        users: []
      };
    default:
      return state;
  }
};

const initialOutIntegrationsState = {
  integrations: [],
  loading: false,
  error: null
};

export const outIntegrations = (state = initialOutIntegrationsState, action) => {
  switch (action.type) {
    case actionType.GET_OUT_INTEGRATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionType.GET_OUT_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        integrations: action.payload.integrations
      };
    case actionType.GET_OUT_INTEGRATIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        integrations: []
      };
    default:
      return state;
  }
};
const initialInboxesState = {
  inboxes: [],
  loading: false,
  error: null
};
export const inboxes = (state = initialInboxesState, action) => {
  switch (action.type) {
    case actionType.GET_INBOXES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actionType.GET_INBOXES_REQUEST_WITHOUT_SLUG:
      return {
        ...state,
        loading: true
      };
    case actionType.GET_INBOXES_SUCCESS:
      return {
        ...state,
        loading: false,
        inboxes: action.payload
      };
    case actionType.GET_INBOXES_SUCCESS_WITHOUT_SLUG:
      return {
        ...state,
        loading: false,
        inboxes: action.payload
      };
    case actionType.GET_INBOXES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case actionType.GET_INBOXES_FAILED_WITHOUT_SLUG:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const initialCustomerState = {
  curUser: null,
  loading: false,
  error: null
};
export const currentCustomer = (state = initialCustomerState, action) => {
  switch (action.type) {
    case actionType.GET_CURRENT_CUSTOMER_PENDING:
      return {
        ...state,
        loading: true,
        curUser: null
      };
    case actionType.GET_CURRENT_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        curUser: action.payload
      };
    case actionType.GET_CURRENT_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
        curUser: null,
        error: action.payload
      };
    case actionType.GET_CURRENT_CUSTOMER_INITIAL:
      return {
        ...state,
        loading: false,
        curUser: null,
        error: null
      };
    default:
      return {
        ...state
      };
  }
};
