import React, { Component } from 'react';

import Message from './Message';
import { connect } from 'react-redux';

let todayBatch = true;
let yesterdayBatch = true;
let datesBatch = [];
let allAssignees = [];

var visibleY = function(el) {
  var rect = el.getBoundingClientRect(),
    top = rect.top,
    height = rect.height,
    el = el.parentNode;
  if (rect.bottom < 0) return false;

  if (top > document.documentElement.clientHeight) return false;
  do {
    rect = el.getBoundingClientRect();
    if (top <= rect.bottom === false) return false;
    if (top + height <= rect.top) return false;
    el = el.parentNode;
  } while (el != document.body);
  return true;
};

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollToBottom: false,
      keepScrollPosition: null
    };
  }
  scrollToBottom = behavior => {
    if (this.messagesEnd !== null && this.messagesEnd !== undefined) {
      this.messagesEnd.scrollIntoView({ behavior: behavior || 'instant' }); //smooth or instant or auto
      document.querySelector('.scroll-down').style.display = 'none';
    }
  };
  getSnapshotBeforeUpdate(prevProps, prevState) {
    let nextProps = this.props;
    let prevMsgs = prevProps.messages;
    let currentMsgs = nextProps.messages;
    let ele = document.querySelector('.chat-detail-body');
    if (
      prevMsgs &&
      currentMsgs &&
      prevMsgs.length &&
      currentMsgs.length &&
      prevMsgs.length !== currentMsgs.length
    ) {
      this.setState({ scrollToBottom: true });
    }
    if ((!prevMsgs || prevMsgs.length === 0) && currentMsgs && currentMsgs.length > 0) {
      this.setState({ scrollToBottom: true });
      setTimeout(() => {
        ele.scrollTop = ele.scrollHeight;
      }, 100);
    } else if (
      prevMsgs &&
      currentMsgs &&
      prevMsgs.length &&
      currentMsgs.length &&
      currentMsgs[0].messageId !== prevMsgs[0].messageId
    ) {
      return ele.scrollHeight - ele.scrollTop;
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState, prevScroll) {
    let positionEle = document.querySelector('.chat-detail-body');
    if (prevScroll !== null) {
      setTimeout(() => {
        positionEle.scrollTo({ top: positionEle.scrollHeight - prevScroll });
      }, -1);
    }
    if (this.state.scrollToBottom) {
      this.scrollToBottom();
      setTimeout(() => {
        this.setState({ scrollToBottom: false, keepScrollPosition: null });
      }, 0);
    }
  }
  scrollButton() {
    setTimeout(() => this.scrollToBottom('smooth'), 2);
  }

  getSender(message) {
    const sender = {};
    if (
      message !== undefined &&
      message.sender.integration &&
      message.message.message.type !== 'note'
    ) {
      if (this.props.conversation.data) {
        if (
          message.sender.integration.id === this.props.conversation.data.inIntegration.id &&
          message.sender.integration.type === this.props.conversation.data.inIntegration.type
        ) {
          sender.type = 'user';
          sender.detail = 'user';
          sender.profilePic = this.getUserImg(this.props.conversation);
        } else {
          sender.type = 'client';
          sender.detail = 'bot';
          sender.profilePic = this.getBotImg(message);
        }
      }
    } else {
      sender.type = 'client';
      sender.detail = 'inbox_user';
      sender.profilePic = this.getInboxUserImg(message);
      sender.name = this.getInboxUserName(message);
    }
    return sender;
  }
  getBackgroundClass(messageObject) {
    if (messageObject.type === 'message' && messageObject.sender.type === 'user') {
      return 'message_type_user';
    } else if (messageObject.type === 'message' && messageObject.sender.type === 'client') {
      return 'message_type_reply';
    } else if (messageObject.type === 'note') {
      return 'message_type_note';
    } else if (messageObject.type === 'event') {
      return 'message_type_event';
    }
  }
  element_type_one_neighbour(user_type, user_neighbour_type, type) {
    let remaining;
    if (type === 'upper') {
      remaining = 'bottom_element';
    } else {
      remaining = 'top_element';
    }
    if (user_type === user_neighbour_type) {
      return remaining;
    } else {
      return 'single_element';
    }
  }

  element_type_two_neighbours(user_type, user_type_lower, user_type_upper) {
    if (user_type_upper === user_type && user_type_lower === user_type) {
      return 'middle_element';
    } else if (user_type_upper === user_type) {
      return 'bottom_element';
    } else if (user_type_lower === user_type) {
      return 'top_element';
    }
    return 'single_element';
  }

  getMessageType(message, index, messageObject) {
    const newMessageObject = JSON.parse(JSON.stringify(messageObject));
    if (index === 0) {
      if (this.props.messages.length === 1) {
        return 'single_element';
      }
      if (this.checkMessageType(this.props.messages[index + 1]) === messageObject.type) {
        return this.element_type_one_neighbour(
          newMessageObject.sender.detail,
          this.getSender(this.props.messages[index + 1]).detail,
          'lower'
        );
      }
      return 'single_element';
    } else if (index === this.props.messages.length - 1) {
      //debugger;
      if (this.checkMessageType(this.props.messages[index - 1]) === messageObject.type) {
        return this.element_type_one_neighbour(
          newMessageObject.sender.detail,
          this.getSender(this.props.messages[index - 1]).detail,
          'upper'
        );
      }
      return 'single_element';
    } else {
      newMessageObject.lowerMessageType = this.checkMessageType(this.props.messages[index + 1]);
      newMessageObject.upperMessageType = this.checkMessageType(this.props.messages[index - 1]);
      if (
        newMessageObject.lowerMessageType === newMessageObject.type &&
        newMessageObject.upperMessageType === newMessageObject.type
      ) {
        return this.element_type_two_neighbours(
          messageObject.sender.detail,
          this.getSender(this.props.messages[index + 1]).detail,
          this.getSender(this.props.messages[index - 1]).detail
        );
      } else if (newMessageObject.lowerMessageType === newMessageObject.type) {
        return this.element_type_one_neighbour(
          newMessageObject.sender.detail,
          this.getSender(this.props.messages[index + 1]).detail,
          'lower'
        );
      } else if (newMessageObject.upperMessageType === newMessageObject.type) {
        return this.element_type_one_neighbour(
          newMessageObject.sender.detail,
          this.getSender(this.props.messages[index - 1]).detail,
          'upper'
        );
      } else {
        return 'single_element';
      }
    }
  }
  display_image(message, index, messageObject) {
    if (
      messageObject.messageDisplayType === 'bottom_element' ||
      messageObject.messageDisplayType === 'single_element'
    ) {
      return true;
    } else {
      if (
        messageObject.sender.detail === 'inbox_user' &&
        this.getSender(this.props.messages[index + 1]).detail === 'inbox_user' &&
        message.sender.data.id !== this.props.messages[index + 1].sender.data.id
      ) {
        return true;
      }
    }
    return false;
  }
  getBotImg(message) {
    if (message.sender.integration.type === 'DialogFlowIntegration') {
      return 'DialogFlowIntegration';
    }
  }
  getInboxUserImg(message) {
    if (message !== undefined && message.sender && message.sender.data) {
      for (let i = 0; i < this.props.users.length; i++) {
        if (this.props.users[i].id === message.sender.data.id) {
          return this.props.users[i].name;
        }
      }
      return 'user';
    }
  }
  getInboxUserName = message => {
    let val = '';
    if (message !== undefined && message.sender && message.sender.data) {
      for (let i = 0; i < this.props.users.length; i++) {
        if (this.props.users[i].id === message.sender.data.id) {
          val = this.props.users[i].name;
        }
      }
    }
    return val;
  };
  getUserImg(conversation) {
    if (conversation && conversation.data && conversation.data.sender.details) {
      if (conversation.data.sender.details.picUrl === undefined) {
        return '/user.svg';
      } else {
        return conversation.data.sender.details.picUrl;
      }
    } else {
      return '/user.svg';
    }
  }

  checkMessageType(message) {
    if (message.message.message.type) {
      switch (message.message.message.type) {
        case 'note':
          return 'note';
        case 'message':
          return 'message';
        case 'event':
          return 'event';
        default:
          return null;
      }
    }
  }
  getParentClass(messageObject) {
    if (messageObject.sender.type === 'user' || messageObject.type === 'event') {
      return 'chat_left_parent';
    }
    return 'chat_right_parent';
  }
  getMessageBodyClass(messageObject) {
    if (messageObject.sender.type === 'user') {
      return 'user_typed ' + messageObject.sender.type + '_' + messageObject.messageDisplayType;
    }
    return 'client_typed ' + messageObject.sender.type + '_' + messageObject.messageDisplayType;
  }
  getEventDisplayType(events, index) {
    if (events.length === 1) {
      return 'single_element';
    } else if (index === 0) {
      return 'top_element';
    } else if (index === events.length - 1) {
      return 'bottom_element';
    } else {
      return 'middle_element';
    }
  }
  shouldEventDisplayImage(messageObject) {
    if (
      messageObject.eventDisplayType === 'single_element' ||
      messageObject.eventDisplayType === 'bottom_element'
    ) {
      return true;
    }
    return false;
  }
  getEventBodyClass(messageObject) {
    if (messageObject.sender.type === 'user') {
      return 'user_typed ' + messageObject.sender.type + '_' + messageObject.eventDisplayType;
    }
    return 'client_typed ' + messageObject.sender.type + '_' + messageObject.eventDisplayType;
  }

  getTimeString = message => {
    const time = new Date(message.createdAt.seconds * 1000);
    const day = time.getDate();
    const month = time.getMonth();
    const year = time.getFullYear();
    function getYesterdaysDate() {
      var date = new Date();
      date.setDate(date.getDate() - 1);
      return date.getDate();
    }
    if (todayBatch) {
      if (
        day === new Date().getDate() &&
        month === new Date().getMonth() &&
        year === new Date().getFullYear()
      ) {
        todayBatch = false;
        return (
          <h5 className="time-show">
            <span>Today</span>
          </h5>
        );
      }
    }
    if (yesterdayBatch) {
      if (
        day === getYesterdaysDate() &&
        month === new Date().getMonth() &&
        year === new Date().getFullYear()
      ) {
        yesterdayBatch = false;
        return (
          <h5 className="time-show">
            <span>Yesterday</span>
          </h5>
        );
      }
    }
    if (day !== new Date().getDate() && day !== getYesterdaysDate()) {
      if (datesBatch.includes(new Date(message.createdAt.seconds * 1000).toDateString())) {
        return '';
      } else {
        datesBatch.push(new Date(message.createdAt.seconds * 1000).toDateString());
        return (
          <h5 className="time-show">
            <span>{new Date(message.createdAt.seconds * 1000).toDateString()}</span>
          </h5>
        );
      }
    }
    return '';
  };

  agent = {
    name: '',
    image: ''
  };
  getAssignee = event => {
    if (event.name === 'assigned') {
      if (event.data.type === 'InboxIntegration' && event.data.agent) {
        this.agent = {
          name: event.data.agent.name,
          image: event.data.agent.image
        };
      } else if (event.data.type !== 'InboxIntegration') {
        this.agent.name = event.data.name;
      }
    }
    // if (
    //   allAssignees.length > 0 &&
    //   allAssignees[allAssignees.length - 1] === this.agent.name
    // ) {
    //   return ''
    // }
    allAssignees.push(this.agent.name);
    return (
      <div className="show-assignee">
        <span>{`This conversation is now assigned to ${this.agent.name}`}</span>
      </div>
    );
  };

  generateComponent(message, index) {
    if (index === 0) {
      todayBatch = true;
      yesterdayBatch = true;
      datesBatch = [];
      allAssignees = [];
    }
    let conversation = this.props.conversation;
    let { messages_seen_at, messages_received_at } = (conversation && conversation.data) || {};

    const messageObject = {};
    messageObject.type = this.checkMessageType(message);
    /*if(messageObject.type !== 'event'){*/
    messageObject.sender = this.getSender(message, messageObject);
    messageObject.messageDisplayType = this.getMessageType(message, index, messageObject);
    messageObject.displayImage = this.display_image(message, index, messageObject);
    if (messageObject.type !== 'event') {
      return (
        <React.Fragment key={message.messageId}>
          {this.getTimeString(message)}
          <Message
            // key={index + message.messageId}
            id={message.createdAt.seconds}
            message={message}
            messageObject={messageObject}
            display_image={
              this.display_image(message, index, messageObject)
                ? messageObject.sender.profilePic
                : false
            }
            chat_parent={this.getParentClass(messageObject)}
            bodyClass={this.getMessageBodyClass(messageObject)}
            backgroundClass={this.getBackgroundClass(messageObject)}
            messageId={message.messageId}
            messages_seen_at={messages_seen_at}
            messages_received_at={messages_received_at}
          />
        </React.Fragment>
      );
    } else {
      if (!message.message.message.events) {
        return;
      }
      //console.log(message.createdAt.getTime())
      if (message.message.message.events)
        return message.message.message.events.map((event, eventIndex) => {
          messageObject.eventDisplayType = this.getEventDisplayType(
            message.message.message.events,
            eventIndex
          );
          return (
            <React.Fragment key={message.messageId + ' ' + eventIndex}>
              {this.getTimeString(message)}
              {event.name === 'assigned' ? (
                this.getAssignee(event)
              ) : (
                <Message
                  message={message}
                  id={message.createdAt.seconds + ' ' + eventIndex}
                  key={index + ' ' + eventIndex}
                  display_image={
                    this.shouldEventDisplayImage(messageObject)
                      ? messageObject.sender.profilePic
                      : false
                  }
                  chat_parent={this.getParentClass(messageObject)}
                  bodyClass={this.getEventBodyClass(messageObject)}
                  eventIndex={eventIndex}
                  backgroundClass={this.getBackgroundClass(messageObject)}
                  messageObject={messageObject}
                  text={event.text}
                  messages_seen_at={messages_seen_at}
                  messages_received_at={messages_received_at}
                />
              )}
            </React.Fragment>
          );
        });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.loadingMessages && (
          <div className="convs-loader">
            <img width="100px" src={require('../public/icons/loader.gif')} alt="loader" />
          </div>
        )}
        <div className="chat-list">
          {this.props.messages.map((message, index) => this.generateComponent(message, index))}
        </div>
        <div
          className="visible-el"
          style={{
            float: 'left',
            clear: 'both'
          }}
          ref={el => {
            this.messagesEnd = el;
          }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    messages: [...state.message.messages].reverse(),
    loadingMessages: state.message.loadingMessages,
    users: state.users.users
  };
};

export default connect(mapStateToProps, null)(Messages);
