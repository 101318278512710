import firebase from 'firebase';
import 'firebase/firestore';

let config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

let database;
let firebaseDatabase;
let messaging;

export const storage = firebase.storage();

export const init = () => {
  firebaseDatabase = firebase.database();
  database = firebase.firestore();
  messaging = (firebase.messaging.isSupported() && firebase.messaging()) || null;
  //const settings = {"timestampsInSnapshots": true};
  //database.settings(settings);
  // database.enablePersistence();
};

export const db = () => {
  return database;
};

export const firebaseDB = () => {
  return firebaseDatabase;
};

export const firebaseMessaging = () => {
  return messaging;
};

export function AddFileToStorage(reference, data, on_Uploading, on_Error, on_Complete) {
  storage
    .ref()
    .child(reference)
    .put(data)
    .on('state_changed', on_Uploading, on_Error, on_Complete);
}
export function GetFileUrl(reference, callback) {
  storage
    .ref()
    .child(reference)
    .getDownloadURL()
    .then(callback);
}
