import React, { Component } from 'react';
import Profile from './Profile';

import './index.css';

export default class AccountContainer extends Component {
  render() {
    return <Profile />;
  }
}
