import React, { Component } from 'react';
import SettingsTeam from './SettingsTeam';

export default class SettingsTeamContainer extends Component {
  render() {
    return (
      <div>
        {this.props.teams.map((team, index) => (
          <SettingsTeam key={index} team={team} />
        ))}
      </div>
    );
  }
}
