import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { ProfileImage } from '../helpers/ImageMapper';
import { connect } from 'react-redux';
// import {getUsers} from '../actions/Session'
import { handoverConversation } from '../actions/Conversation';
import { getAssignee } from '../helpers/Conversation';

class Assign extends React.Component {
  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleSelection = value => {
    this.props.handoverConversation(this.props.conversation, value);
    this.handleRequestClose();
  };

  renderDropdownItems() {
    let menuItems = [];
    let index = 0;
    let inboxIntegration =
      this.props.integrations && this.props.integrations.find(i => i.type === 'InboxIntegration');
    let botIntegrations =
      this.props.integrations && this.props.integrations.filter(i => i.type !== 'InboxIntegration');
    if (botIntegrations && botIntegrations.length > 0) {
      menuItems.push(
        <Dropdown.Item header={'true'} key="bot_header">
          <b>Bots</b>
        </Dropdown.Item>
      );
      this.props.integrations.map(integration => {
        if (integration.type === 'InboxIntegration') {
          inboxIntegration = integration;
        } else {
          index += 1;
          menuItems.push(
            <Dropdown.Item
              key={index}
              onSelect={() => this.handleSelection(integration)}
              title={integration.name}
              children={
                <div className="bots">
                  <ProfileImage name={integration.type} className="assignedBotImage" />
                  {integration.name}
                </div>
              }
            />
          );
        }
        return null;
      });
      menuItems.push(<Dropdown.Item key="divider" divider={'true'} />);
    }
    if (this.props.users && inboxIntegration) {
      index += 1;
      menuItems.push(
        <Dropdown.Item
          header={'true'}
          key="user_header"
          onSelect={() => this.handleSelection({ inboxIntegration })}
        >
          <b>Agents</b>
        </Dropdown.Item>
      );
      this.props.users.map(user => {
        index += 1;
        menuItems.push(
          <Dropdown.Item
            key={index}
            onSelect={() => this.handleSelection({ user, inboxIntegration })}
            title={user.name}
            children={
              <div className="users">
                <ProfileImage name={user.name} image={user.image} className="assigneeUserImage" />
                <span style={{ marginLeft: '10px' }}>
                  {user.id === this.props.currentUser.id ? 'Me' : user.name}
                </span>
              </div>
            }
          />
        );
        return null;
      });
    }
    return menuItems;
  }

  render() {
    const assigneeName = getAssignee(
      this.props.conversation,
      this.props.currentUser,
      this.props.integrations
    );
    return (
      <DropdownButton id="dropDown-btn" title={assigneeName} style={{ zIndex: 999 }}>
        {this.renderDropdownItems()}
      </DropdownButton>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users.users,
    error: state.users.error,
    integrations: state.outIntegrations.integrations,
    currentUser: state.session.session.user || {}
  };
};

export default connect(mapStateToProps, { handoverConversation })(Assign);
