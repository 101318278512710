import React, { Component } from 'react';

export default class NotesButton extends Component {
  render() {
    return (
      <div className="addNotes">
        <button
          onClick={() => this.props.notesClick('message')}
          className={`message-btn ${this.props.current === 'message' ? 'notes-btn-active' : ''}`}
        >
          Inbox
        </button>
        <button
          onClick={() => this.props.notesClick('note')}
          className={`notes-btn ${this.props.current !== 'message' ? 'notes-btn-active' : ''}`}
        >
          Note
        </button>
      </div>
    );
  }
}
