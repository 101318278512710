import React, { Component } from 'react';
import './index.css';
import Status from './Status';
import NotesButton from './NotesButton';
import Submit from './Submit';
import { connect } from 'react-redux';
class ComposeMessageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 'message'
    };
    // this.handleChatScroll = this.handleChatScroll.bind(this);
    this.handleSmallSizeClick = this.handleSmallSizeClick.bind(this);
    this.handleMediumSizeClick = this.handleMediumSizeClick.bind(this);
    this.handleLargeSizeClick = this.handleLargeSizeClick.bind(this);
  }

  handleSmallSizeClick = e => {
    const el = document.getElementById('writing-area');
    el.style.fontSize = '10px';
  };
  handleMediumSizeClick = e => {
    const el = document.getElementById('writing-area');
    el.style.fontSize = '14px';
  };
  handleLargeSizeClick = e => {
    const el = document.getElementById('writing-area');
    el.style.fontSize = '16px';
  };

  notesClicked = current => {
    this.setState(prevState => {
      if (prevState.current !== current) {
        return { current };
      }
    });

    // else {
    //   this.setState({
    //     current: 'message'
    //   });
    // }
  };

  render() {
    if (!this.props.conversation && !this.props.conversation.data) {
      return null;
    }
    let { productsForCarousel } = this.props;
    return (
      <div>
        {this.props.showsize ? (
          <div className={productsForCarousel.length ? 'sizes-carousel' : 'sizes'}>
            <span className="sizes-option" onClick={this.handleSmallSizeClick}>
              Small
            </span>
            <span className="sizes-option" onClick={this.handleMediumSizeClick}>
              Medium
            </span>
            <span className="sizes-option" onClick={this.handleLargeSizeClick}>
              Large
            </span>
          </div>
        ) : (
          ''
        )}
        <NotesButton notesClick={this.notesClicked} current={this.state.current} />
        <Submit
          current={this.state.current}
          notesClick={this.notesClicked}
          showEmoji={this.props.showEmoji}
        />
        <Status />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    productsForCarousel: state.message.addedProductsToCarousel
  };
};
export default connect(mapStateToProps, null)(ComposeMessageContainer);
