import { Picker } from 'emoji-mart';
import React, { useRef, useEffect } from 'react';
import 'emoji-mart/css/emoji-mart.css';

export default function FloatingModalWrapper({ onClose, onEmojiClick }) {
  const wrapperRef = useRef(null);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);
  const handleClickOutside = e => {
    if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(e.target)) onClose(e);
  };
  return (
    <div ref={wrapperRef} className="emoji-pick">
      <Picker
        emoji="point_up"
        showSkinTones={false}
        showPreview={false}
        style={{ zIndex: 444 }}
        onSelect={onEmojiClick}
      />
    </div>
  );
}
