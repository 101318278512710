import React, { Component } from 'react';
import { Image, FormLabel, FormControl, Button } from 'react-bootstrap';

export default class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      userName: ''
    };

    this.handleNameChange = this.handleNameChange.bind(this);

    this.handleUserNameChange = this.handleUserNameChange.bind(this);
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handleUserNameChange(e) {
    this.setState({ userName: e.target.value });
  }

  render() {
    return (
      <div className="Overview">
        <Image
          height={120}
          width={120}
          src="http://www.personalbrandingblog.com/wp-content/uploads/2017/08/blank-profile-picture-973460_640-300x300.png"
          circle
        />

        <div className="overview-label">
          <FormLabel>Full Name</FormLabel>
          <FormControl
            type="text"
            value={this.state.name}
            placeholder="Your Full Name"
            onChange={this.handleNameChange}
          />

          <br />

          <FormLabel>User Name</FormLabel>
          <FormControl
            type="text"
            value={this.state.userName}
            placeholder="Your User Name"
            onChange={this.handleUserNameChange}
          />

          <br />

          <Button variant="primary">Save</Button>
        </div>
      </div>
    );
  }
}
