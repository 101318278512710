import React, { useRef, useEffect } from 'react';
import ManageCannedResponse from './ManageCannedResponse';

export default function FloatingModalWrapper({ onClose, selectCannedMessage }) {
  const wrapperRef = useRef(null);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);
  const handleClickOutside = e => {
    // if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(e.target)) onClose(e);
  };
  return (
    <div ref={wrapperRef} className="canned-floating-menu">
      <ManageCannedResponse onClose={onClose} selectCannedMessage={selectCannedMessage} />
    </div>
  );
}
