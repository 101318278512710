import React from 'react';
import { ProfileImage } from '../helpers/ImageMapper';

export default class AutoCompleteTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false
    };
    this.getMatchedUsers = this.getMatchedUsers.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', e => {
      this.props.changeDisplay(false);
    });
  }

  getMatchedUsers(userTyped) {
    //debugger;
    let matchedUsers = this.props.state.matchedUsers;
    let classList;

    return matchedUsers.map((user, index) => {
      if (this.props.state.active === index) {
        classList = 'tab_complete_option tab_complete_active';
      } else {
        classList = 'tab_complete_option';
      }
      //const el = document.createElement("span", {}, "@"+user.name);
      return (
        <div
          className={classList}
          onMouseEnter={(e, name, i) => this.props.handleMouseEnter(index)}
          onClick={i => this.props.handleClick(index)}
          key={index}
        >
          <ProfileImage name={user.name} className="taggedUserImage" />
          <div className="tab-user-name">{user.name}</div>
        </div>
      );
    });
  }
  render() {
    if (
      this.props.current === 'note' &&
      this.props.state.display &&
      this.props.state.matchedUsers.length !== 0
    ) {
      return (
        <div className="tab_complete_UI">
          <div className="tab_complete_header">
            People matching @{this.props.state.userTypedMessage}
          </div>
          {this.getMatchedUsers(this.props.state.userTyped)}
        </div>
      );
    } else {
      return null;
    }
  }
}
