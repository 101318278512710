import React, { useEffect, useState, useCallback } from 'react';
import Conversation from './Conversation';
import { connect } from 'react-redux';
import Loader from './Loader';
import {
  getMessages,
  activeConversation,
  getConversations,
  activeHeaderConversation,
  updateActiveUsers,
  resetPendingMessages,
  loadConversationsOnRequest
} from '../actions/Conversation';
import { getConversationUser } from '../actions/Session';

function sortByFields(ids, list, field1, field2, field3) {
  ids.sort((ai, bi) => {
    let a = list[ai].data;
    let b = list[bi].data;
    let x1 = a.inbox.sortOrder; // TODO : field1 is hardcoded
    let x2 = b.inbox.sortOrder;
    if (x1 && x2) return x1 > x2 ? -1 : 1;
    if (x1) return -1;
    if (x2) return 1;
    if (!field2) return 0;
    x1 = a[field2];
    x2 = b[field2];
    if (x1 && x2) return x1 > x2 ? -1 : 1;
    if (x1) return -1;
    if (x2) return 1;
    if (!field3) return 0;
    x1 = a[field3];
    x2 = b[field3];
    if (x1 && x2) return x1 > x2 ? -1 : 1;
    if (x1) return -1;
    if (x2) return 1;
  });
}

const ConversationsWithHook = props => {
  const [activeConversationId, setActiveConversation] = useState(props.selectedConversationId);

  // memoized function gets returned. Will be called only when 'activeConversationId' changes
  const selectConversation = useCallback(
    conversation => {
      setActiveConversation(conversation.unique_id);
      if (props.selectedConversationId && conversation.unique_id !== props.selectedConversationId) {
        let scrollBtn = document.querySelector('.scroll-down');
        scrollBtn && (scrollBtn.style.display = 'none');
        // before switching out, mark it viewed
        let oldConversation = props.conversations[props.selectedConversationId];
        if (
          oldConversation &&
          oldConversation.data.lastMessageAt &&
          oldConversation.data.inbox.activeUsers &&
          oldConversation.data.inbox.activeUsers[props.currentUser.id] &&
          oldConversation.data.inbox.activeUsers[props.currentUser.id].at <
            oldConversation.data.lastMessageAt.toMillis()
        ) {
          props.updateActiveUsers(oldConversation, props.currentUser, 'viewing'); // TODO backdate viewing just enough to make it read
        }
      }
      props.activeConversation(conversation);
      props.getMessages(conversation.unique_id);
      props.updateActiveUsers(conversation, props.currentUser, 'viewing');
      props.getConversationUser(conversation.data.userId);
      window.gtag('event', 'conversation-select');
    },
    [activeConversationId]
  );
  useEffect(() => {
    return () => {
      props.resetPendingMessages();
    };
  }, [activeConversationId]);

  if (props.currentDisplay === 'open' || props.currentDisplay === 'closed') {
    sortByFields(
      props.conversation_ids,
      props.conversations,
      'inbox.sortOrder',
      'lastMessageAt',
      'lastSeenAt'
    );
  } else if (props.currentDisplay === 'live') {
    sortByFields(props.conversation_ids, props.conversations, 'inbox.sortOrder', 'lastSeenAt');
  } else {
    sortByFields(props.conversation_ids, props.conversations, 'inbox.sortOrder', 'lastSeenAt');
  }

  function handleScroll(e) {
    let { currentDisplay, currentAssigned, inbox, currentUser } = props;

    const diff = Math.abs(e.target.clientHeight - (e.target.scrollHeight - e.target.scrollTop));
    // e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    let filters = {
      assignedTo: { name: currentAssigned, userId: currentUser.id },
      status: currentDisplay
    };
    if (diff <= 2 && currentDisplay !== 'live') {
      props.loadConversationsOnRequest(inbox, props.filters);
    }
  }

  return (
    <div className="main-container" style={{ height: '100%' }}>
      <div className="conversation-container" onScroll={handleScroll}>
        {props.conversation_ids &&
          props.conversation_ids.map(conversation_id => {
            let conversation = props.conversations[conversation_id];
            return (
              <Conversation
                currentUser={props.currentUser}
                key={conversation_id}
                conversation={conversation}
                selectConversation={selectConversation}
                active={props.selectedConversationId === conversation_id}
              />
            );
          })}

        {!props.conversation_ids && <Loader />}
        {!props.conversationsLoading &&
          props.conversation_ids &&
          props.conversation_ids.length === 0 && (
            <p style={{ margin: '20%' }}>No Conversations found matching this filter</p>
          )}
      </div>
      {props.conversationsLoading && (
        <div className="convs-loader">
          <img width="100px" src={require('../public/icons/loader.gif')} alt="loader" />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    conversations: state.conversation.conversations,
    conversation_ids: state.conversation.conversation_ids,
    selectedConversationId: state.activeConversation.uniqueId,
    currentUser: state.session.session.user || {},
    currentDisplay: state.conversation.currentDisplay,
    currentAssigned: state.conversation.currentAssigned,
    inbox: state.session.session.inbox || {},
    conversationsLoading: state.conversation.loading
  };
};

export default connect(mapStateToProps, {
  getConversations,
  getMessages,
  getConversationUser,
  activeConversation,
  activeHeaderConversation,
  updateActiveUsers,
  resetPendingMessages,
  loadConversationsOnRequest
})(ConversationsWithHook);
