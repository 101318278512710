import React, { Component } from 'react';
import TeamAccess from './TeamAccess';
import { connect } from 'react-redux';
import { getTeamsByTeammateAsync } from '../actions/SettingAction';

import './index.css';

class TeamsAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // teams: [],
      selectedTeams: []
    };

    this.addTeam = this.addTeam.bind(this);
    this.removeTeam = this.removeTeam.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getTeamsByTeammateAsync());
  }

  addTeam(team) {
    this.state.selectedTeams.push(team);
  }

  removeTeam(team) {
    this.setState({
      selectedTeams: this.state.selectedTeams.filter((x, i) => x.id !== team.id)
    });
  }

  render() {
    return (
      <div>
        <div>
          <span className="primary bold">Teammate&nbsp;</span>

          <span className="fa-Bodytext -small -secondary">will have access to these teams</span>

          {this.props.teams.map((team, index) => (
            <TeamAccess
              key={index}
              team={team}
              addTeam={this.addTeam}
              removeTeam={this.removeTeam}
            />
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  teams: state.teams.teams,
  loading: state.teams.loading,
  error: state.teams.error
});

export default connect(mapStateToProps)(TeamsAccess);
