import React, { Component } from 'react';
import Team from './Team';
import { Button, Modal, FormControl, FormLabel } from 'react-bootstrap';

import { connect } from 'react-redux';
import { createTeamAsync, getTeamsAsync, editTeamAsync } from '../actions/SettingAction';

import './index.css';

const GET_TEAMS = 'GET_TEAMS';
const CREATE_TEAM = 'CREATE_TEAM';
const EDIT_TEAM = 'EDIT_TEAM';

export class Teams extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleCreateTeamShow = this.handleCreateTeamShow.bind(this);
    this.handleCreateTeamSave = this.handleCreateTeamSave.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCreateTeamClose = this.handleCreateTeamClose.bind(this);
    this.handleEditTeamSave = this.handleEditTeamSave.bind(this);
    this.handleEditTeamClose = this.handleEditTeamClose.bind(this);
    this.handleDeleteTeam = this.handleDeleteTeam.bind(this);
    this.handleEditNameChange = this.handleEditNameChange.bind(this);

    this.state = {
      type: GET_TEAMS,
      teams: [],
      name: '',
      showCreateTeam: false,
      editId: '',
      editName: '',
      showEditTeam: false,
      showDeleteTeam: false
    };
  }

  componentDidMount() {
    this.props.dispatch(getTeamsAsync());
  }

  handleCreateTeamSave() {
    this.setState({ type: CREATE_TEAM });

    this.props.dispatch(createTeamAsync());

    this.setState({ showCreateTeam: false });
  }

  handleCreateTeamShow() {
    this.setState({ showCreateTeam: true });
  }

  handleCreateTeamClose() {
    this.setState({ showCreateTeam: false });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handleEdit(team) {
    this.setState({ editId: team.id, editName: team.name });
    this.setState({ showEditTeam: true });
  }

  handleEditNameChange(e) {
    this.setState({ editName: e.target.value });
  }

  handleEditTeamSave() {
    this.setState({ type: EDIT_TEAM });

    this.props.dispatch(editTeamAsync());

    this.setState({ showEditTeam: false });
  }

  handleEditTeamClose() {
    this.setState({ showEditTeam: false });
  }

  handleDeleteTeam() {
    this.setState({ showDeleteTeam: true });
  }

  componentDidUpdate(prevProps) {
    var teamList = [];

    if (this.state.type === GET_TEAMS) {
      const { teams } = this.props;
      teamList = teams;
    } else if (this.state.type === CREATE_TEAM) {
      const { createdTeams } = this.props;
      teamList = createdTeams;
    } else {
    }

    if (teamList != null && teamList.length > 0) {
      this.setState({ teams: teamList });
    }
  }

  render() {
    return (
      <div>
        {this.state.teams.map((team, index) => (
          <Team key={index} team={team} handleEdit={this.handleEdit} />
        ))}
        <br />

        <Button variant="primary" onClick={this.handleCreateTeamShow}>
          Create a Team
        </Button>

        <Modal show={this.state.showCreateTeam} onHide={this.handleCreateTeamClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create a Team</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormLabel>Team Name</FormLabel>
            <FormControl
              type="text"
              value={this.state.name}
              placeholder="Your Team Name"
              onChange={this.handleNameChange}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleCreateTeamSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showEditTeam} onHide={this.handleEditTeamClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Team</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormLabel>Team Name</FormLabel>
            <FormControl
              type="text"
              value={this.state.editName}
              placeholder="Your Team Name"
              onChange={this.handleEditNameChange}
            />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={this.handleEditTeamSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  teams: state.teams.teams,
  loading: state.teams.loading,
  error: state.teams.error,
  createdTeams: state.createdTeams.teams,
  createLoading: state.createdTeams.loading,
  createError: state.createdTeams.error,
  editSuccess: state.editTeam.success
});

export default connect(mapStateToProps)(Teams);
