import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import MicrolinkCard from '@microlink/react';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

class ProductUrlsCarouselView extends React.Component {
  render() {
    return (
      <Carousel
        swipeable={true}
        draggable={false}
        showDots={true}
        responsive={responsive}
        // ssr={true} // means to render carousel on server-side.
        infinite={false}
        autoPlay={false}
        focusOnSelect={true}
        // centerMode={true}
        // autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all 1.5s"
        transitionDuration={100}
        containerClass="carousel-container pt-5"
        removeArrowOnDeviceType={['tablet', 'mobile']}
        deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style d-none"
        itemClass="carousel-item-padding-40-px"
      >
        {this.props.shopifyProductUrls.length > 0 &&
          this.props.shopifyProductUrls.map((url, i) => {
            return (
              <div key={i + new Date().getMilliseconds()}>
                <MicrolinkCard url={url} />
              </div>
            );
          })}
      </Carousel>
    );
  }
}

export default ProductUrlsCarouselView;
