import React, { Component } from 'react';
import TimeAgo from 'timeago-react';
import './index.css';

import { ProfileImage } from '../helpers/ImageMapper';
import { connect } from 'react-redux';
import * as timeago from 'timeago.js';
import shoppingCart from '../public/icons/shoppingCart.png';
import { getImage } from '../helpers/Common';
import { getAssignee } from '../helpers/Conversation';
import sound from '../sounds/message_sound.mp3';
var audio = new Audio(sound);
timeago.register('compact', (number, index) => {
  return [
    ['now', 'now'],
    ['now', 'now'],
    ['1 min ago', '1 min ago'],
    ['%s mins ago', '%s mins ago'],
    ['1 hour ago', '1 hour ago'],
    ['%s hours ago', '%s hours ago'],
    ['1 day ago', '1 day ago'],
    ['%s days ago', '%s days ago'],
    ['1 week ago', '1 week ago'],
    ['%s weeks ago', '%s weeks'],
    ['1 month ago', '1 month ago'],
    ['%s months ago', '%s months ago'],
    ['1 year ago', '1 year ago'],
    ['%s years ago', '%s years ago']
  ][index];
});
timeago.register('live', (number, index) => {
  if (index <= 1) {
    return ['Live', 'Live'];
  } else {
    return ['', ''];
  }
});

class Conversation extends Component {
  componentDidMount() {
    if (this.props.active) {
      this.props.selectConversation(this.props.conversation);
    }
  }

  conversationStatus = (conversation, currentUser) => {
    let status = { message: false, unread: false, pending: false };

    const lastMessageAt =
      conversation.data.lastMessageAt && Number(conversation.data.lastMessageAt.seconds);

    if (!lastMessageAt) return status;
    status.message = true;
    status.unread = true;

    if (conversation.data.responsePending) {
      status.pending = true;
    }

    const activeUsers = this.props.conversation.data.inbox.activeUsers;

    if (!activeUsers) {
      return status;
    }

    if (currentUser.id in activeUsers) {
      const lastActiveAt = Math.round(activeUsers[currentUser.id].at / 1000);
      if (lastActiveAt < lastMessageAt) {
        return status;
      }
    } else {
      return status;
    }
    status.unread = false;
    return status;
  };
  getLiveStatus() {
    const { conversation } = this.props;
    const lastSeenAt = conversation && conversation.data && conversation.data.lastSeenAt;
    let now = new Date();
    let last = lastSeenAt.toDate();
    let seconds = now - last;
    if (seconds / 1000 / 60 <= 1) {
      return true;
    }
    return false;
  }

  imageDisplay = conversation => {
    let live = this.getLiveStatus();
    if (conversation.data.sender.details.picUrl)
      return (
        <img
          alt=""
          src={conversation.data.sender.details.picUrl}
          style={{ borderRadius: '50%' }}
          className={live ? 'conversationUserDefaultImage-live' : 'conversationUserDefaultImage'}
        />
      );
    else if (conversation.data.sender.uniqueIdentifier) {
      let profile = getImage(conversation.data.sender.uniqueIdentifier, 'max');

      return (
        <div
          style={
            live
              ? {
                  width: '50px',
                  height: '50px',
                  background: `url(${require('../public/icons/' + profile.src)}) ${
                    profile.position
                  }`,
                  borderRadius: '50%',
                  backgroundSize: profile.size,
                  border: '2px solid green',
                  marginRight: '1px'
                }
              : {
                  width: '50px',
                  height: '50px',
                  background: `url(${require('../public/icons/' + profile.src)}) ${
                    profile.position
                  }`,
                  borderRadius: '50%',
                  backgroundSize: profile.size
                }
          }
        ></div>
      );
    } else return null;
  };

  handleOnclick = () => {
    if (!this.props.active) {
      this.props.selectConversation(this.props.conversation);
    }
  };

  showNameEmail = () => {
    const { conversation } = this.props;
    if (
      conversation.data.ecommerce &&
      conversation.data.ecommerce.customer &&
      conversation.data.ecommerce.customer.name
    ) {
      return `${conversation.data.ecommerce.customer.name} ${
        conversation.data.ecommerce.customer.email
          ? `(${conversation.data.ecommerce.customer.email})`
          : ''
      }`;
    } else if (conversation.data.sender.details.name) {
      return ` ${
        conversation.data.sender.details.email ? `(${conversation.data.sender.details.email})` : ''
      }`;
    } else if (
      conversation.data.ecommerce &&
      conversation.data.ecommerce.customer &&
      conversation.data.ecommerce.customer.location
    ) {
      return (
        <span>
          <i className="fa fa-map-marker"></i>
          {conversation.data.ecommerce.customer.location === 'unidentified city'
            ? 'unknown'
            : conversation.data.ecommerce.customer.location}
        </span>
      );
    } else {
      return '  ';
    }
  };
  lengthAdjust(str, length) {
    if (str.length > length) {
      return str.slice(0, length) + '...';
    }
    return str;
  }
  playSound = () => {
    audio.play();
  };
  componentDidUpdate(prevprops) {
    if (
      this.props.unansweredConversationsCount > 0 &&
      this.props.unansweredConversationsCount > prevprops.unansweredConversationsCount
    ) {
      this.playSound();
    }
  }
  render() {
    const { conversation, active, currentDisplay } = this.props;

    const lastSeenAt = conversation && conversation.data && conversation.data.lastSeenAt;
    if (conversation != null && conversation !== undefined && conversation.data) {
      let conversationStatus = this.conversationStatus(conversation, this.props.currentUser);
      let live = this.getLiveStatus();
      const renderElement = (
        <div
          className={
            'single-conversation ' +
            (conversationStatus.pending && !active ? 'message-wrapper-border' : 'message-wrapper') +
            (active ? ' message-wrapper-selected' : '')
          }
          onClick={this.handleOnclick}
        >
          <div className="message-row">
            <div className="user-img">
              {this.imageDisplay(conversation)}
              <article className={live ? 'live-label-active' : 'live-label'}>
                <TimeAgo datetime={lastSeenAt && lastSeenAt.toDate()} locale="live" />
              </article>
              {/* <ProfileImage name={conversation.data.inIntegration.type} className="inIntegration" /> */}
            </div>
            <div className="message-sender">
              <div className="message-preview">{this.lengthAdjust(this.showNameEmail(), 15)}</div>
              <div className="message-sender-name">{conversation.data.lastMessage}</div>
            </div>

            {conversation.data.cart &&
            conversation.data.cart.total_price &&
            conversation.data.cart.total_price > 0 ? (
              <div
                className={this.props.active ? 'cart-wrapper cart-wrapper-active' : 'cart-wrapper'}
              >
                {/*this.props.curUser && this.props.active ? (
                <div className="cart-quantity">
                  {this.props.curUser.cart.items[0]
                    ? this.props.curUser.cart.items[0].quantity
                    : '0'}
                </div>
              ) : (
                ' '
              )*/}
                <div>
                  <img className="cart-image" src={shoppingCart} alt="cart" />
                </div>
                <div className="cart-total">
                  {conversation.data.cart.currency === 'USD'
                    ? '$'
                    : conversation.data.cart.currency}
                  {this.lengthAdjust(conversation.data.cart.total_price.toString(), 5)}
                </div>
              </div>
            ) : null}

            {conversationStatus.unread && !active ? <div className="unread-messages"></div> : ''}

            <div className="message-detail">
              <div className="integrations">
                <ProfileImage
                  conversation={conversation}
                  name={getAssignee(conversation)}
                  className="outIntegration"
                />
              </div>
            </div>
          </div>

          {lastSeenAt && lastSeenAt.seconds && (
            <TimeAgo datetime={lastSeenAt.toDate()} className="message-time" locale="compact" />
          )}
        </div>
      );

      return renderElement;
    }
    return <div />;
  }
}

function mapStateToProps(state) {
  return {
    currentDisplay: state.conversation.currentDisplay,
    curUser: state.currentCustomer.curUser,
    inbox: state.session.session.inbox || {},
    unansweredConversationsCount: state.conversation.unansweredCount
  };
}

export default connect(mapStateToProps)(Conversation);
