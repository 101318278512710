import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
//import ContentEditable from 'react-contenteditable';
import ContentEditable from './ContentEditable';
import {
  addMessage,
  updateActiveUsers,
  setFileUploadDetails,
  setProductsToCarousel
} from '../actions/Conversation';
import { actionType, updateTime } from '../constants/index';
import trim from 'trim';
import { messageData, message } from '../javascripts/models/Message';
import AutoCompleteTab from './AutoCompleteTab';
import { getStoreKey } from '../helpers/Common';
import { AddFileToStorage, GetFileUrl } from '../javascripts/Firestore';
import firebase from 'firebase';
import { db, firebaseDB, storage } from '../javascripts/Firestore';
import CannedMessages from './CannedResponse/CannedMessages';
import { getFilterCannedMessages } from '../helpers';
import FloatingModalWrapper from './CannedResponse/FloatingModalWrapper';
import store from '../configuration/Store';
import EmojiPicker from './EmojiPicker';

class Submit extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = null;
    this.state = {
      message: '',
      active: 0,
      matchedUsers: [],
      matchedCannedMessages: [],
      activeCannedMessages: 0,
      cannedMessagesDisplay: false,
      taggedUsers: [],
      display: false,
      userTypedMessage: '',
      html: '',
      editable: true,
      clearInput: false,
      bytesTransferred: 0,
      totalBytes: 0,
      showEmoji: false,
      emoji: '',
      showCannedMenu: false
    };
    this.handleSendMessage = this.handleSendMessage.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.keyFunctions = this.keyFunctions.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.getMatchedUsers = this.getMatchedUsers.bind(this);
    this.changeDisplay = this.changeDisplay.bind(this);
    this.enableEmojiPicker = this.enableEmojiPicker.bind(this);
    this.onEmojiClick = this.onEmojiClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    // props.current is a string
    if (
      prevProps.current !== this.props.current ||
      prevProps.conversation.unique_id !== this.props.conversation.unique_id
    ) {
      this.setState({
        clearInput: true
      });
    }
    if (!prevProps.addCannedMessageToInput && this.props.addCannedMessageToInput) {
      let new_message = this.state.message + ' ' + this.props.addCannedMessageToInput.message;
      this.setState({
        message: new_message,
        html: new_message
      });
      store.dispatch({ type: actionType.ADD_CANNED_MESSAGE_TO_INPUT });
      this.scrollToEnd(100);
    }
  }
  setEndOfContenteditable() {
    let el = document.querySelector('div.editableDiv');
    //el.focus();
    if (typeof window.getSelection != 'undefined' && typeof document.createRange != 'undefined') {
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange != 'undefined') {
      var textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  }
  handleCannedMessageEnter = i => {
    this.setState({ activeCannedMessages: i, clearInput: false });
  };
  scrollToEnd = (time = 0) => {
    setTimeout(() => {
      this.inputRef && this.inputRef.setEndOfContenteditable();
    }, time);
  };
  handleSelectCannedMessage = i => {
    let cannedMessage = (this.state.matchedCannedMessages || []).find(
      (message, index) => i >= 0 && index === i
    );
    if (cannedMessage) {
      this.setState({
        message: cannedMessage.message,
        html: cannedMessage.message,
        activeCannedMessages: 0,
        cannedMessagesDisplay: false,
        matchedCannedMessages: [],
        clearInput: true
      });
      this.scrollToEnd();
    }
  };
  handleMouseEnter(i) {
    //const div = document.querySelector("div.submit > div[contentEditable = true]");
    const user = this.state.matchedUsers[i];
    const taggedUsers = JSON.parse(JSON.stringify(this.state.taggedUsers));
    // var isFirefox = typeof InstallTrigger !== 'undefined';
    let text = '' + this.state.message;
    let html = '' + this.state.html;
    // const htmlArray = html.split(' ');
    const textArray = text.split(' ');
    if (user && '@' + this.state.userTypedMessage === textArray[textArray.length - 1]) {
      taggedUsers.push(user);
      const lastIndex = html.lastIndexOf(textArray[textArray.length - 1]);
      const lastIndexInText = text.lastIndexOf(textArray[textArray.length - 1]);
      html =
        html.slice(0, lastIndex) +
        html
          .slice(lastIndex)
          .replace(
            textArray[textArray.length - 1],
            `<span><span contenteditable = false class = "tagged_user">@` +
              user.name +
              `</span>&nbsp;</span>`
          );
      text =
        text.slice(0, lastIndexInText) +
        text.slice(lastIndexInText).replace(textArray[textArray.length - 1], '@' + user.name + ' ');
    } else {
      if (taggedUsers && taggedUsers.length > 0) {
        const lastIndex = html.lastIndexOf(taggedUsers[taggedUsers.length - 1].name);
        const lastIndexInText = text.lastIndexOf(taggedUsers[taggedUsers.length - 1].name);
        html =
          html.slice(0, lastIndex) +
          html.slice(lastIndex).replace(taggedUsers[taggedUsers.length - 1].name, user.name);
        text =
          text.slice(0, lastIndexInText) +
          text.slice(lastIndexInText).replace(taggedUsers[taggedUsers.length - 1].name, user.name);
        taggedUsers[taggedUsers.length - 1] = user;
      }
    }
    setTimeout(() => {
      this.setEndOfContenteditable();
    }, 100);

    this.setState({
      html: html,
      taggedUsers: taggedUsers,
      active: i,
      message: text
    });
  }

  changeDisplay(status) {
    this.setState({
      display: status
    });
  }
  changeCannedMessagesDisplay = status => {
    this.setState({
      cannedMessagesDisplay: status
    });
  };
  handleChange(html, text) {
    this.setState({
      html: html,
      clearInput: false
    });
    //const messageBox = document.querySelector('div.submit div[contentEditable = true]');
    if (this.props.activeUsers.length > 0 && this.props.currentUser.id) {
      if (this.props.activeUsers[this.props.currentUser.id].type !== 'typing') {
        this.props.updateActiveUsers(this.props.conversation, this.props.currentUser, 'typing');
      } else {
        const difference =
          (new Date().getTime() -
            new Date(this.props.activeUsers[this.props.currentUser.id].at).getTime()) /
          1000;
        if (difference > updateTime.typing) {
          this.props.updateActiveUsers(this.props.conversation, this.props.currentUser, 'typing');
        }
      }
    }
    if (trim(text) !== '') {
      if (this.props.current === 'note') {
        let messageText = text;

        messageText = messageText.replace(/\u00A0/g, ' ');
        messageText = messageText.replace(/[\r\n]+/g, '');

        this.setState({
          message: messageText
        });
        const words = messageText.split(' ');
        if (words.length === 1) {
          const message = words[0];
          if (message.length > 0 && message[0] === '@') {
            const userTypedMessage =
              message.length > 1 ? message.substr(1, message.length - 1) : '';
            this.getMatchedUsers(userTypedMessage);
            this.setState({
              userTypedMessage: userTypedMessage
            });
            this.changeDisplay(true);
          } else {
            this.changeDisplay(false);
          }
        } else {
          let lastWord = words[words.length - 1];
          if (lastWord.length > 0 && lastWord[0] === '@') {
            if (lastWord.slice(lastWord.length - 1) === '\n') {
              lastWord = lastWord.slice(0, lastWord.length - 1);
            }
            const userTypedMessage =
              lastWord.length > 1 ? lastWord.substr(1, lastWord.length - 1) : '';
            this.getMatchedUsers(userTypedMessage);
            this.changeDisplay(true);
            this.setState({
              userTypedMessage: userTypedMessage
            });
          } else {
            this.changeDisplay(false);
          }
        }
      } else if (this.props.current === 'message') {
        this.changeDisplay(false);
        let messageText = text;
        messageText = messageText.replace(/\u00A0/g, ' ');
        messageText = messageText.replace(/[\r\n]+/g, '');

        this.setState({
          message: messageText
        });
        if (messageText && messageText.trim().startsWith('/')) {
          let userTypedMessage = messageText.trim().slice('1');
          this.getMatchedCannedMessages(userTypedMessage);
          this.setState({
            userTypedMessage: userTypedMessage,
            cannedMessagesDisplay: true
          });
        } else {
          this.changeCannedMessagesDisplay(false);
        }
      }
    } else {
      this.setState({
        message: '',
        cannedMessagesDisplay: false,
        display: false
      });
    }
  }
  getMatchedUsers(userTypedMessage) {
    const users = this.props.users;
    const matchedUsers = [];
    for (let i = 0; i < users.length; i++) {
      if (
        userTypedMessage.toUpperCase() ===
        users[i].name.substr(0, userTypedMessage.length).toUpperCase()
      ) {
        matchedUsers.push(users[i]);
      }
    }
    this.setState({
      matchedUsers: matchedUsers
    });
    if (this.state.active > matchedUsers.length - 1) {
      this.setState({
        active: 0
      });
    }
  }

  getMatchedCannedMessages(userTypedMessage) {
    const cannedMessages = this.props.cannedMessages;
    const matchedCannedMessages = getFilterCannedMessages(userTypedMessage, cannedMessages);
    this.setState({
      matchedCannedMessages: matchedCannedMessages
    });
    if (this.state.activeCannedMessages > matchedCannedMessages.length - 1) {
      this.setState({
        activeCannedMessages: 0
      });
    }
  }

  keyFunctions(e) {
    switch (e.keyCode) {
      //key code 13 belongs to enter
      case 13:
        e.preventDefault();
        if (this.props.current === 'message') {
          if (this.state.cannedMessagesDisplay) {
            this.handleSelectCannedMessage(this.state.activeCannedMessages);
          } else {
            this.handleSendMessage();
          }
        } else {
          if (this.state.display) {
            this.handleClick(this.state.active);
          } else {
            this.handleSendMessage();
          }
        }
        break;
      //key code 8 belongs to backspace
      case 8:
        if (
          this.props.current === 'note' &&
          this.state.taggedUsers.length > 0 &&
          this.state.message.length > 0
        ) {
          const taggedUsers = JSON.parse(JSON.stringify(this.state.taggedUsers));
          const lastWord = taggedUsers[taggedUsers.length - 1].name;
          const lastWordArray = lastWord.split(' ');
          const message = this.state.message;
          const words = this.state.message.split(' ');
          if (message.charAt(message.length - 1) !== ' ') {
            if (lastWordArray.length === 1) {
              if (words[words.length - 1] === '@' + lastWord) {
                taggedUsers.pop();
                this.setState({
                  taggedUsers: taggedUsers
                });
              }
            } else {
              if (words[words.length - 1] === lastWordArray[lastWordArray.length - 1]) {
                taggedUsers.pop();
                this.setState({
                  taggedUsers: taggedUsers
                });
              }
            }
          }
        }
        break;
      //key code 38 belongs to upper arrow
      case 38:
        //debugger;
        if (this.props.current === 'message' && this.state.cannedMessagesDisplay) {
          if (this.state.activeCannedMessages > 0)
            this.handleCannedMessageEnter(this.state.activeCannedMessages - 1);
          else this.handleCannedMessageEnter(0);
        } else if (this.state.display) {
          if (this.state.active > 0) {
            this.handleMouseEnter(this.state.active - 1);
          } else {
            this.handleMouseEnter(0);
          }
        }
        break;
      //key code 40 belongs to lower arrow
      case 40:
        if (this.props.current === 'message' && this.state.cannedMessagesDisplay) {
          if (this.state.activeCannedMessages < this.state.matchedCannedMessages.length - 1)
            this.handleCannedMessageEnter(this.state.activeCannedMessages + 1);
          else this.handleCannedMessageEnter(this.state.matchedCannedMessages.length - 1);
        } else if (this.state.display === true) {
          if (this.state.active < this.state.matchedUsers.length - 1)
            this.handleMouseEnter(this.state.active + 1);
          else this.handleMouseEnter(this.state.matchedUsers.length - 1);
        }
        break;
      default:
        break;
    }
  }

  handleSendMessage() {
    if (trim(this.state.message) !== '') {
      this.setState({ showEmoji: false });
      let text = this.state.message;
      let conversation = this.props.conversation;
      if (conversation != null && conversation.data != null) {
        let messageModel = message(text, null, null, null, false, false, '');

        messageModel.sender = {};
        if (this.props.current === 'note') {
          messageModel.type = 'note';
          messageModel.taggedUsers = { ...this.state.taggedUsers };
          const recipient = {};
          recipient.conversationId = conversation.unique_id;
          messageModel.recipient = recipient;
        } else {
          messageModel.type = 'message';
          const recipient = conversation.data.sender;
          recipient.integration = conversation.data.inIntegration;
          recipient.conversationId = conversation.unique_id;
          messageModel.recipient = recipient;
        }
        //messageModel.type = this.props.current;

        let inbox = {};
        let session = JSON.parse(localStorage.getItem(getStoreKey()));
        if (session != null && session.inbox != null) {
          // Read item:     let
          messageModel.sender.id = session.user.id;
          inbox.id = session.inbox.id;
        }

        let messageDataModel = messageData(inbox, messageModel, true);
        if (this.props.productsForCarousel && this.props.productsForCarousel.length > 0) {
          let carouselAttachments = {
            type: 'CAROUSEL',
            items: []
          };
          let allItems = [];
          this.props.productsForCarousel.forEach(product => {
            let eachItem = {};
            eachItem['title'] = product.name || 'Product Name';
            eachItem['subtitle'] = product.description || '';
            if (product.imageUrl) {
              eachItem.media = {
                type: 'THUMBNAIL',
                url: product.imageUrl
              };
            }
            eachItem.actions = [
              {
                type: 'URL',
                text: 'View Product',
                data: { url: product.url || null }
              }
            ];
            allItems.push(eachItem);
          });
          carouselAttachments.items = allItems;
          messageDataModel.message.attachment = carouselAttachments;

          // let temp ={
          //     text: 'Check out this products...',
          //     type: 'message',
          //     attachment: {
          //       type: 'CAROUSEL',
          //       items: [
          //         {
          //           title: 'Denims Regular Fit',
          //           subtitle: 'Price: INR2400',
          //           media: {
          //             type: 'THUMBNAIL',
          //             url: 'https://4.imimg.com/data4/RB/TK/MY-1136511/silky-lycra-mens-jeans-250x250.jpg'
          //           },
          //           actions: [
          //             {
          //               type: 'URL',
          //               text: 'Show Product',
          //               data: {
          //                 url: 'https://en.wikipedia.org/wiki/Jeans'
          //               }
          //             },
          //             {
          //               type: 'SHARE',
          //               text: 'Share',
          //               data: {
          //                 url: 'https://en.wikipedia.org/wiki/Jeans'
          //               }
          //             }
          //           ]
          //         },

          //         {
          //           title: 'Ripped jeans fit',
          //           subtitle: 'Price: INR2200',
          //           media: {
          //             type: 'THUMBNAIL',
          //             url: 'https://images-na.ssl-images-amazon.com/images/I/715O%2BFtMLmL._AC_UY1000_.jpg'
          //           },
          //           // actions: [
          //           //   {
          //           //     type: 'URL',
          //           //     text: 'Show Product',
          //           //     data: {
          //           //       url: 'https://en.wikipedia.org/wiki/Jeans#Recent_evolution'
          //           //     }
          //           //   },
          //           //   {
          //           //     type: 'SHARE',
          //           //     text: 'Share',
          //           //     data: {
          //           //       url: 'https://en.wikipedia.org/wiki/Jeans#Recent_evolution'
          //           //     }
          //           //   }
          //           // ]
          //         },
          //         {
          //           title: 'Dummy Jeans',
          //           subtitle: 'Price: INR9200',
          //           media: {
          //             type: 'THUMBNAIL',
          //             url: 'https://images-na.ssl-images-amazon.com/images/I/81kv6dIAc7L._UL1500_.jpg'
          //           },
          //           // actions: [
          //           //   {
          //           //     type: 'URL',
          //           //     text: 'Show Product',
          //           //     data: {
          //           //       url: 'https://en.wikipedia.org/wiki/Jeans#Recent_evolution'
          //           //     }
          //           //   },
          //           //   {
          //           //     type: 'SHARE',
          //           //     text: 'Share',
          //           //     data: {
          //           //       url: 'https://en.wikipedia.org/wiki/Jeans#Recent_evolution'
          //           //     }
          //           //   }
          //           // ]
          //         }
          //       ]
          //     },
          //     // actions: [
          //     //   {
          //     //     type: 'quick_reply',
          //     //     text: 'Show More',
          //     //     data: {
          //     //       payload: 'SHOW_MORE_PRODUCTS'
          //     //     }
          //     //   },

          //     //   {
          //     //     type: 'location_request',
          //     //     text: 'Send Location'
          //     //   }
          //     // ]
          //   }
          //   message.message.attachment=temp.attachment;
          // message.message.actions=temp.actions;
        }
        this.props.addMessage(messageDataModel);
        this.props.setProductsToCarousel([]);

        this.setState({
          message: '',
          taggedUsers: [],
          matchedusers: [],
          active: 0,
          display: false,
          html: ''
        });
        document.querySelector('div.editableDiv').innerText = '';
      } else {
      }
    }
  }
  //i stands for index in MatchedUsers
  handleClick(i) {
    this.handleMouseEnter(i);
    this.setState({
      display: false
    });
  }
  handleCannedMessageClick(i) {
    this.setState({
      cannedMessagesDisplay: false
    });
  }

  addAttachments = e => {
    let storageRef = storage.ref();
    let mediaType = { image: 'IMAGE', video: 'VIDEO', audio: 'AUDIO', application: 'DOCUMENT' };
    let file = e.target.files[0];
    let attachementType = (file && mediaType[file.type.split('/')[0]]) || mediaType['application'];
    let supportedTypes = ['IMAGE', 'VIDEO', 'AUDIO', 'DOCUMENT'];

    var FileSize = file.size / 1024 / 1024; // in MB

    if (FileSize >= 5) {
      alert('File size exceeds 5 MB');
      return;
    } else {
      let conversation = this.props.conversation;
      if (conversation != null && conversation.data != null) {
        let text = file.name || 'Untitled Document';
        let messageModel = message(text, null, {}, null, false, false, '');

        messageModel.sender = {};
        messageModel.message = {};
        const recipient = conversation.data.sender;
        recipient.integration = conversation.data.inIntegration;
        recipient.conversationId = conversation.unique_id;
        messageModel.recipient = recipient;

        let inbox = {};
        let session = JSON.parse(localStorage.getItem(getStoreKey()));
        if (session != null && session.inbox != null) {
          // Read item:     let
          messageModel.sender.id = session.user.id;
          inbox.id = session.inbox.id;
        }
        if (supportedTypes.includes(attachementType)) {
          messageModel = {
            ...messageModel,
            attachment: {
              media: {
                Bytes: '',
                totalBytes: '',
                url: ''
              },
              type: attachementType
            },
            type: 'message',
            isImageUploaded: false,
            isMessageSent: false
          };
          let self = this;
          let blob = new Blob([file]);
          let uploadFileName = file.lastModified + '-' + Date.now() + '-' + text;
          let session_id = this.props.session_id;
          let sessionData = session_id.split(':'),
            sessionId = sessionData[0],
            session_path = sessionData[1] || '';
          let sessionPath = session_path ? session_path + '/' : '';
          let parentPath = sessionPath ? 'sdkmessages' : 'clients';
          let reference = `/${parentPath}/${sessionPath}${this.props.client_key}/sessions/${sessionId}/uploads/${uploadFileName}`;
          this.props.setFileUploadDetails({ bytesTransferred: file.size, totalBytes: file.size });
          let messageDataModel = messageData(inbox, messageModel, false);

          AddFileToStorage(
            reference,
            blob,
            function(snapshot) {
              messageModel.attachment.media.Bytes = snapshot.bytesTransferred;
              messageModel.attachment.media.totalBytes = snapshot.totalBytes;
              self.props.setFileUploadDetails({
                bytesTransferred: snapshot.bytesTransferred,
                totalBytes: snapshot.totalBytes
              });
            },
            function(error) {
              console.log(error);
            },
            function() {
              GetFileUrl(reference, function(url) {
                messageModel.attachment.media.url = url;
                self.props.addMessage(messageDataModel);
                self.props.setFileUploadDetails({
                  bytesTransferred: file.size,
                  totalBytes: file.size
                });
              });
            }
          );
        } else {
          alert('Selected file type is not supported.');
        }
      }
    }
  };
  removeProductFromCarousel = productId => {
    let filteredProducts = [];
    this.props.productsForCarousel.forEach(p => {
      if (p.id !== productId) {
        filteredProducts.push(p);
      }
    });
    this.props.setProductsToCarousel(filteredProducts);
  };
  onEmojiClick = emojiObject => {
    const el = document.querySelector('div.editableDiv');
    el.innerText += emojiObject.native;
    this.handleChange(el.innerHTML, el.innerText);
  };

  callPicker() {
    if (this.state.showEmoji) {
      return <EmojiPicker onEmojiClick={this.onEmojiClick} onClose={this.disableEmojiPicker} />;
    }
  }
  enableEmojiPicker = e => {
    if (!this.state.showEmoji)
      this.setState({
        showEmoji: true
      });
  };
  disableEmojiPicker = e => {
    setTimeout(() => {
      this.setState({ showEmoji: false });
    }, 150);
  };
  disableCannedMenu = e => {
    setTimeout(() => {
      this.setState({ showCannedMenu: false });
    }, 150);
  };
  handleSelectMessage = canned_message => {
    let new_message = this.state.message + ' ' + canned_message.message;
    this.setState({
      message: new_message,
      html: new_message,
      showCannedMenu: false
    });
    this.scrollToEnd(100);
  };
  createNewCannedMessage = () => {
    store.dispatch({ type: actionType.EDIT_CANNED_MESSAGE });
  };
  render() {
    let { productsForCarousel } = this.props;
    return (
      <React.Fragment>
        <div className="carousel-products-view">
          {productsForCarousel.length > 0 &&
            productsForCarousel.map(product => {
              return (
                <div className="carousel-product " key={product.id}>
                  {product.name}
                  <span
                    style={{ cursor: 'pointer' }}
                    className="ml-2 text-danger"
                    onClick={() => this.removeProductFromCarousel(product.id)}
                  >
                    x
                  </span>
                </div>
              );
            })}
        </div>
        <div className={this.props.current === 'message' ? 'submit send-box' : 'submit note-box'}>
          <ContentEditable
            ref={ref => (this.inputRef = ref)}
            onChange={this.handleChange}
            className="editableDiv"
            id="editableDiv-id"
            html={this.state.html}
            keyFunctions={this.keyFunctions}
            clearInput={this.state.clearInput}
            current={this.props.current}
            onFocus={this.setEndOfContenteditable}
            // emoji={this.state.emoji}
          />
          <div className="extra">
            <div className="attachments">
              <img
                style={{ width: '25px', marginBottom: '5px' }}
                className="attachments-item"
                src="/icons/canned-response.png"
                alt="attchment-icon"
                onClick={e => this.setState({ showCannedMenu: !this.state.showCannedMenu })}
              />
              {this.state.showCannedMenu && (
                <FloatingModalWrapper
                  onClose={this.disableCannedMenu}
                  selectCannedMessage={this.handleSelectMessage}
                />
              )}
            </div>
            <div className="attachments">
              <label>
                <input
                  type="file"
                  className="attachments-input"
                  onChange={e => this.addAttachments(e)}
                  onClick={event => {
                    event.target.value = null;
                  }}
                />
                <img
                  className="attachments-item"
                  src="/icons/attachment.png"
                  alt="attchment-icon"
                />
              </label>
            </div>
            <div className="attachments">
              <span>
                <img
                  className="emoji-item"
                  src="/icons/emoji.png"
                  alt="emoji-icon"
                  onClick={this.enableEmojiPicker}
                />

                {this.callPicker()}
              </span>
              {this.state.emoji ? console.log('emoji', this.state.emoji) : ''}
            </div>

            <div className="attachments">
              <Button className="send-button" onClick={this.handleSendMessage} type="submit">
                {this.props.current === 'message' ? 'Send' : 'Add Notes'}
              </Button>
            </div>
            {this.props.current === 'message' && this.state.cannedMessagesDisplay && (
              <CannedMessages
                state={this.state}
                changeDisplay={this.changeCannedMessagesDisplay}
                handleMouseEnter={this.handleCannedMessageEnter}
                selectCannedMessage={this.handleSelectCannedMessage}
                current={this.props.current}
                onNew={this.createNewCannedMessage}
              />
            )}
            {this.props.current === 'note' && (
              <AutoCompleteTab
                state={this.state}
                changeDisplay={this.changeDisplay}
                handleMouseEnter={this.handleMouseEnter}
                handleClick={this.handleClick}
                current={this.props.current}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { conversations } = state.conversation;
  let activeConversation = [];
  let activeUsers;
  if (conversations.length > 0) {
    activeConversation = conversations.filter(
      conversation => conversation.unique_id === state.activeConversation.conversation.unique_id
    );
    if (activeConversation[0] && activeConversation[0].data) {
      activeUsers = activeConversation[0].data.inbox.activeUsers;
    }
  }
  return {
    conversation: state.activeConversation.conversation,
    activeUsers: activeUsers || {},
    currentUser: state.session.session.user || {},
    users: state.users.users,
    sessionToken: state.session.session.token,
    client_key: state.activeConversation.conversation.data.sender.data.client_key,
    session_id: state.activeConversation.conversation.data.sender.data.session_id,
    productsForCarousel: state.message.addedProductsToCarousel,
    cannedMessages: state.cannedMessages.cannedMessages,
    addCannedMessageToInput: state.cannedMessages.addCannedMessageToInput
  };
};

export default connect(mapStateToProps, {
  addMessage,
  updateActiveUsers,
  setFileUploadDetails,
  setProductsToCarousel
})(Submit);
