import React from 'react';

export default class CannedMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false
    };
    this.getMatchedCannedMessages = this.getMatchedCannedMessages.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', e => {
      this.props.changeDisplay(false);
    });
  }

  getMatchedCannedMessages() {
    //debugger;
    let matchedCannedMessages = this.props.state.matchedCannedMessages;
    let classList;
    return matchedCannedMessages.map((cannedMessage, index) => {
      if (this.props.state.activeCannedMessages === index) {
        classList = 'canned_message_option tab_complete_active';
      } else {
        classList = 'canned_message_option';
      }
      //const el = document.createElement("span", {}, "@"+cannedMessage.name);
      return (
        <div
          className={classList}
          onMouseEnter={(e, name, i) => this.props.handleMouseEnter(index)}
          onClick={i => this.props.selectCannedMessage(index)}
          key={index}
        >
          <div className="canned-message-header">
            <span className="canned-tag-name">/{cannedMessage.short_code}</span>
          </div>
          <div className="canned-message-message">{cannedMessage.message}</div>
        </div>
      );
    });
  }
  render() {
    if (this.props.current === 'message' && this.props.state.cannedMessagesDisplay) {
      return this.props.state.matchedCannedMessages.length > 0 ? (
        <div className="tab_complete_UI">
          <div className="tab_complete_header">
            Canned Responses matching <strong>/{this.props.state.userTypedMessage}</strong>
          </div>
          {this.getMatchedCannedMessages()}
        </div>
      ) : (
        <div className="tab_complete_UI">
          <div className="tab_complete_header">
            Canned Messages matching <strong>/{this.props.state.userTypedMessage}</strong>
          </div>
          <div className="empty_canned_message">
            <div className={'empty-message'}>No results found. </div>
            <div className={'empty-add-new-message'} onClick={this.props.onNew}>
              + Add Response
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}
