import { actionType } from '../constants';
import firebase from 'firebase';
import { db } from '../javascripts/Firestore';
import { firebaseMessaging } from '../javascripts/Firestore';
import { getStoreKey, getUserUpdatePath } from '../helpers/Common';
export const getSession = (url, params, isNewLogin = false) => {
  return dispatch => {
    dispatch({ type: actionType.GET_SESSION_REQUEST });
    fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    })
      // .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        if (json.status === 'error') {
          dispatch({ type: actionType.GET_SESSION_FAILED, payload: json });
        } else {
          if (isNewLogin) window.location.reload();
          dispatch({ type: actionType.GET_SESSION_SUCCESS, payload: json });
        }
      });
    // .catch(error => {
    //   dispatch({ type: actionType.GET_SESSION_FAILED, payload: error });
    // });
  };
};

export const userLogout = url => {
  return dispatch => {
    dispatch({ type: actionType.LOGOUT_REQUEST });
    fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      // .then(handleErrors)
      .then(res => res.json())
      .then(async json => {
        if (json.status === 'error') {
          dispatch({ type: actionType.LOGOUT_FAILED });
        } else {
          window.location.replace(window.location.origin);
          dispatch({ type: actionType.LOGOUT_SUCCESS });
        }
      });
    // .catch(error => {
    //   dispatch({ type: actionType.GET_SESSION_FAILED, payload: error });
    // });
  };
};
export const getInboxesWithoutSlug = (url, params) => {
  return dispatch => {
    dispatch({ type: actionType.GET_INBOXES_REQUEST_WITHOUT_SLUG });
    fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    })
      // .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        if (json.status === 'error') {
          dispatch({ type: actionType.GET_INBOXES_FAILED_WITHOUT_SLUG, payload: json });
        } else {
          const inboxes = [...json.inboxes];
          dispatch({ type: actionType.GET_INBOXES_SUCCESS_WITHOUT_SLUG, payload: inboxes });
        }
      });
    // .catch(error => {
    //   const errorMsg=error.message?error.message:'You have entered an invalid username or password';
    //   dispatch({ type: actionType.GET_INBOXES_FAILED_WITHOUT_SLUG, payload:{message:errorMsg} });
    // });
  };
};

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const firebaseSignIn = token => {
  return dispatch => {
    dispatch({ type: actionType.FIRBASE_SIGNIN_REQUEST });
    firebase
      .auth()
      .signInWithCustomToken(token)
      .then(function(res) {
        //console.log("success", res)
        dispatch({ type: actionType.FIRBASE_SIGNIN_SUCCESS, payload: res });
      })
      .catch(function(error) {
        // Handle Errors here. var errorCode = error.code; var errorMessage =
        // error.message; console.log("error", errorMessage) ...
        console.log(error);
        window.location.pathname = '/';
        localStorage.removeItem(getStoreKey());
        if (error.message === 'TOKEN_EXPIRED') {
          dispatch({ type: actionType.SESSION_EXPIRY, payload: error });
        }
        dispatch({ type: actionType.FIRBASE_SIGNIN_FAILED, payload: error });
      });
  };
};

export const getUsers = url => {
  return dispatch => {
    dispatch({ type: actionType.GET_USERS_REQUEST });
    fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch({ type: actionType.GET_USERS_SUCCESS, payload: json });
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: actionType.SESSION_EXPIRY, payload: error });
        dispatch({ type: actionType.GET_USERS_FAILED, payload: error });
      });
  };
};

export const getCannedMessages = url => {
  return dispatch => {
    fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch({ type: actionType.GET_CANNED_MESSAGES, payload: json.canned_responses });
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const getOutIntegrations = url => {
  return dispatch => {
    dispatch({ type: actionType.GET_OUT_INTEGRATIONS_REQUEST });
    fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        // console.log("USERS_val", json)
        dispatch({
          type: actionType.GET_OUT_INTEGRATIONS_SUCCESS,
          payload: json
        });
      })
      .catch(error => {
        if (
          error.message === 'INVALID_CUSTOM_TOKEN : TOKEN_EXPIRED' ||
          error.message === 'TOKEN_EXPIRED'
        ) {
          dispatch({ type: actionType.SESSION_EXPIRY, payload: error });
        } else {
          dispatch({
            type: actionType.GET_OUT_INTEGRATIONS_FAILED,
            payload: error
          });
        }
      });
  };
};

export const setSession = session => {
  return dispatch => {
    dispatch({ type: actionType.GET_SESSION_SUCCESS, payload: session });
  };
};

export const getInboxes = url => {
  return dispatch => {
    dispatch({ type: actionType.GET_INBOXES_REQUEST });
    fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        const inboxes = [...json.inboxes];
        //console.log(inboxes.length);
        dispatch({ type: actionType.GET_INBOXES_SUCCESS, payload: inboxes });
      })
      .catch(error => {
        //console.log(error);
        dispatch({ type: actionType.GET_INBOXES_FAILED, payload: error });
      });
  };
};

let unsubscribe = null;
export const getConversationUser = id => {
  if (unsubscribe !== null && unsubscribe !== undefined) {
    unsubscribe();
  }
  if (id !== null && id !== undefined) {
    return dispatch => {
      dispatch({ type: actionType.GET_CURRENT_CUSTOMER_PENDING });
      unsubscribe = db()
        .collection('users')
        .doc(id)
        .onSnapshot(
          doc => {
            if (doc !== null && doc !== undefined) {
              doc.data();
              dispatch({
                type: actionType.GET_CURRENT_CUSTOMER_SUCCESS,
                payload: doc.data()
              });
            }
          },
          error => {
            if (
              error.message === 'INVALID_CUSTOM_TOKEN : TOKEN_EXPIRED' ||
              error.message === 'TOKEN_EXPIRED'
            ) {
              dispatch({ type: actionType.SESSION_EXPIRY, payload: error });
            } else {
              dispatch({
                type: actionType.GET_CURRENT_CUSTOMER_FAILED,
                payload: error
              });
            }
          }
        );
    };
  } else {
    return dispatch => {
      dispatch({ type: actionType.GET_CURRENT_CUSTOMER_INITIAL });
    };
  }
};
export const setPushNotifications = () => {
  return dispatch => {
    const messaging = firebaseMessaging();
    if (!messaging) return dispatch({ type: actionType.PUSH_NOTIFICATION, payload: false });
    Notification.requestPermission()
      .then(async () => {
        return messaging.getToken();
      })
      .then(fcm_token => {
        let url = getUserUpdatePath();
        console.log(fcm_token);
        let params = { fcm_token, notifications: true, client: 'web' };
        fetch(url, {
          method: 'PUT',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(params)
        })
          .then(res => {
            if (!res.ok)
              return res.text().then(error => {
                throw new Error(error);
              });

            return res.json();
          })
          .then(json => {
            //console.log(inboxes.length);
            dispatch({ type: actionType.PUSH_NOTIFICATION, payload: true });
          })
          .catch(error => {
            //console.log(error);
            dispatch({ type: actionType.PUSH_NOTIFICATION, payload: false });
          });
      })
      .catch(err => {
        console.log(err);
        dispatch({ type: actionType.PUSH_NOTIFICATION, payload: false });
      });
  };
};

export const updateUser = (url, form_data) => {
  return dispatch => {
    let url = getUserUpdatePath();
    dispatch({ type: actionType.USER_UPDATE_STARTED });
    fetch(url, {
      method: 'PUT',
      credentials: 'include',
      body: form_data
    })
      .then(res => {
        if (!res.ok) {
          return res.text().then(error => {
            throw new Error(error);
          });
        }
        return res.json();
      })
      .then(json => {
        dispatch({ type: actionType.USER_UPDATE_SUCCESS, payload: json });
      })
      .catch(error => {
        let err = 'Unable to update';
        if (error && error.message) {
          let error_object = JSON.parse(error.message);
          err = (error_object && error_object.message) || err;
        }
        dispatch({ type: actionType.USER_UPDATE_FAIL, payload: err });
      });
  };
};
export const clearUserUpdate = () => {
  return dispatch => {
    dispatch({ type: actionType.CLEAR_USER_UPDATE });
  };
};
