import React, { Component } from 'react';
import { FormCheck } from 'react-bootstrap';

export default class TeamAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team: {},
      isChecked: false
    };

    this.handleChange = this.handleChange.bind(this);

    let team = this.props.team;
    this.setState({ team: team, isChecked: team.isAccess });

    // this conditon is for editing teams for already teammates
    // if (team.isAccess !== null && team.isAccess == true) {
    //     this.handleChange()
    // }
  }

  handleChange() {
    // because state  is going to change(true will become false)
    if (this.state.isChecked === true) {
      this.props.removeTeam(this.props.team);
    } else {
      this.props.addTeam(this.props.team);
    }

    this.setState({
      isChecked: !this.state.isChecked
    });
  }

  componentDidMount() {
    let team = this.props.team;
    this.setState({ team: team });
    if (team.isAccess === true) {
      this.handleChange();
    }
  }

  render() {
    return (
      <div className="settings-select-list-item" onClick={this.handleChange}>
        <FormCheck inline checked={this.state.isChecked} onChange={this.handleChange} />

        <div className="primary">
          <span>Team:&nbsp;</span>
          <span>{this.state.team.name}</span>
        </div>
      </div>
    );
  }
}
