import React, { useState, Fragment } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import store from '../../configuration/Store';
import { actionType } from '../../constants';
import { getFilterCannedMessages } from '../../helpers';
import { cannedMessagesPath } from '../../helpers/Common';

function ManageCannedResponse(props) {
  const { cannedMessages, onClose, selectCannedMessage, deleteLoading } = props;
  const [search, setSearch] = useState('');
  let filteredMessages = getFilterCannedMessages(search, cannedMessages);
  const stopPropagation = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDelete = async (e, id) => {
    stopPropagation(e);
    store.dispatch({ type: actionType.DELETE_CANNED_MESSAGE_START });
    store.dispatch({ type: actionType.EDIT_CANNED_MESSAGE_START });
    let data = null;
    try {
      data = await fetch(cannedMessagesPath() + '/' + id, {
        credentials: 'include',
        method: 'DELETE'
      });
    } catch (error) {
      return store.dispatch({ type: actionType.CLEAR_CANNED_LOADER });
    }
    store.dispatch({
      type: actionType.EDIT_CANNED_MESSAGE_DONE,
      payload: data
    });
    store.dispatch({ type: actionType.DELETE_CANNED_MESSAGE_DONE, payload: id });
  };
  const handleEdit = (e, id) => {
    stopPropagation(e);
    store.dispatch({ type: actionType.EDIT_CANNED_MESSAGE, payload: id });
  };
  const handleSelect = message => {
    selectCannedMessage(message);
  };
  const createNew = () => {
    store.dispatch({ type: actionType.EDIT_CANNED_MESSAGE });
  };
  return (
    <div className="canned-messages-wrapper">
      {deleteLoading && (
        <div className="loading-spinner">
          <Spinner animation="border" />
        </div>
      )}
      <div className="canned-messages-header">
        <div className="canned-message-heading">Canned Responses</div>
        <div className="close-wrapper">
          <i className="fa fa-times" aria-hidden="true" onClick={onClose}></i>
        </div>
      </div>
      {cannedMessages && cannedMessages.length > 0 ? (
        <Fragment>
          <div className="canned-message-filter ">
            <div className="canned-message-search">
              <Form.Control
                size="sm"
                type="text"
                className="canned-message-search-input"
                placeholder="Search"
                value={search}
                onChange={e => setSearch(e.target.value)}
                autoFocus={true}
              />
            </div>
            <div className="canned-message-new" onClick={createNew}>
              + Add New Response
            </div>
          </div>
          <div className="canned-messages-list">
            {cannedMessages.length && !filteredMessages.length && (
              <Fragment>
                <div className="no-search-results">No results Found.</div>
                {/* <div className="no-canned-message-button">
                  <span onClick={createNew}>+ Add</span>
                </div> */}
              </Fragment>
            )}
            {filteredMessages.map((message, index) => (
              <div
                className="canned-message-item"
                key={index}
                onClick={e => {
                  handleSelect(message);
                }}
              >
                <div className="single-canned-message-header">
                  <div className="single-canned-message-header-tag">/{message.short_code}</div>
                  <div className="single-canned-message-header-actions">
                    <i
                      className="fa fa-pencil-square edit"
                      aria-hidden="true"
                      onClick={e => handleEdit(e, message.id)}
                    ></i>
                    <i
                      className="fa fa-trash delete"
                      aria-hidden="true"
                      onClick={e => handleDelete(e, message.id)}
                    ></i>
                  </div>
                </div>
                <div className="single-canned-message-content">{message.message}</div>
              </div>
            ))}
          </div>
        </Fragment>
      ) : (
        <div className="no-canned-messages">
          <ul className="no-canned-messages-message">
            <li>
              Canned responses are saved message templates, which can be used to quickly send a
              reply to a conversation.
            </li>
            <li>
              These are accessible by all the Agents under the store ad can be accessed typing "/"
              followed by short code while on a chat.
            </li>
          </ul>
          <div className="no-canned-message-create">Create your first canned response</div>
          <div className="no-canned-message-button">
            <span onClick={createNew}>+ Add</span>
          </div>
        </div>
      )}
    </div>
  );
}
const mapStateToProps = state => {
  return {
    cannedMessages: state.cannedMessages.cannedMessages,
    deleteLoading: state.cannedMessages.deleteLoading
  };
};

export default connect(mapStateToProps, null)(ManageCannedResponse);
