import { data } from '../dummy/data';

import {
  GET_TEAMS_BEGIN,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_FAILURE,
  CREATE_TEAM_BEGIN,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_FAILURE,
  EDIT_TEAM_BEGIN,
  EDIT_TEAM_SUCCESS,
  EDIT_TEAM_FAILURE,
  GET_TEAMMATES_BEGIN,
  GET_TEAMMATES_SUCCESS,
  GET_TEAMMATES_FAILURE
} from './';

// get teams
export const getTeamsBegin = () => ({
  type: GET_TEAMS_BEGIN
});

export const getTeamsSuccess = teams => ({
  type: GET_TEAMS_SUCCESS,
  payload: { teams }
});

export const getTeamsError = error => ({
  type: GET_TEAMS_FAILURE,
  payload: { error }
});

// now api calling here

export function getTeams() {
  return dispatch => {
    dispatch(getTeamsBegin());
    return fetch('/teams')
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(getTeamsSuccess(data.teams));
        return json.markets;
      })
      .catch(error => dispatch(getTeamsError(error)));
  };
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

// dummy data call
export const getTeamsAsync = () => {
  return dispatch => {
    dispatch(getTeamsBegin());
    return setTimeout(() => {
      dispatch(getTeamsSuccess(data.teams));
    }, 2000);
  };
};

// dummy data call
export const getTeamsByTeammateAsync = () => {
  return dispatch => {
    dispatch(getTeamsBegin());
    return setTimeout(() => {
      dispatch(getTeamsSuccess(data.teams3));
    }, 2000);
  };
};

//
//
//
// create team functions
export const createTeamBegin = () => ({
  type: CREATE_TEAM_BEGIN
});

export const createTeamSuccess = teams => ({
  type: CREATE_TEAM_SUCCESS,
  payload: { teams }
});

export const createTeamError = error => ({
  type: CREATE_TEAM_FAILURE,
  payload: { error }
});

// now api calling here

export function createTeam() {
  return dispatch => {
    dispatch(createTeamBegin());
    return fetch('/teams')
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(createTeamSuccess(data.teams));
        return json.markets;
      })
      .catch(error => dispatch(createTeamError(error)));
  };
}

// dummy data call
export const createTeamAsync = () => {
  return dispatch => {
    dispatch(createTeamBegin());
    return setTimeout(() => {
      dispatch(createTeamSuccess(data.teams2));
    }, 2000);
  };
};

//
//
//
// edit team functions
export const editTeamBegin = () => ({
  type: EDIT_TEAM_BEGIN
});

export const editTeamSuccess = teams => ({
  type: EDIT_TEAM_SUCCESS,
  payload: { teams }
});

export const editTeamError = error => ({
  type: EDIT_TEAM_FAILURE,
  payload: { error }
});

// now api calling here

export function editTeam() {
  return dispatch => {
    dispatch(editTeamBegin());
    return fetch('/teams')
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(editTeamSuccess(data.teams));
        return json.markets;
      })
      .catch(error => dispatch(editTeamError(error)));
  };
}

// dummy data call
export const editTeamAsync = () => {
  return dispatch => {
    dispatch(editTeamBegin());
    return setTimeout(() => {
      dispatch(editTeamSuccess(data.teams2));
    }, 2000);
  };
};

// gettings teammates
export const getTeammatesBegin = () => ({
  type: GET_TEAMMATES_BEGIN
});

export const getTeammatesSuccess = teammates => ({
  type: GET_TEAMMATES_SUCCESS,
  payload: { teammates }
});

export const getTeammatesError = error => ({
  type: GET_TEAMMATES_FAILURE,
  payload: { error }
});

// now api calling here

export function getTeammates() {
  return dispatch => {
    dispatch(getTeammatesBegin());
    return fetch('/teams')
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(getTeammatesSuccess(data.teammates));
        return json.markets;
      })
      .catch(error => dispatch(getTeammatesError(error)));
  };
}

// dummy data call
export const getTeammatesAsync = () => {
  return dispatch => {
    dispatch(getTeammatesBegin());
    return setTimeout(() => {
      dispatch(getTeammatesSuccess(data.teammates));
    }, 2000);
  };
};
