import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SettingsContainer from '../components/SettingsContainer';
import SettingsDetailContainer from '../components/SettingsDetailContainer';

export const PROFILE = 'profile',
  TAGS = 'tags',
  RESPONSE = 'response',
  TEAMS = 'teams',
  TEAMMATES = 'teammates';

export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeComponent: PROFILE
    };

    this.setComponent = this.setComponent.bind(this);
  }

  setComponent(TYPE) {
    this.setState({ activeComponent: TYPE });
  }

  render() {
    return (
      <div className="dashboard">
        <Container>
          <Row className="parent-height">
            <Col className="message-column" ms={3} sm={3}>
              <SettingsContainer setComponent={this.setComponent} />
            </Col>

            <Col
              ms={8}
              sm={8}
              style={{
                backgroundColor: '#fff',
                minHeight: 700
              }}
            >
              <SettingsDetailContainer component={this.state.activeComponent} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
