import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import store from '../../configuration/Store';
import { actionType } from '../../constants';
import { cannedMessages } from '../../reducers/Conversation';
import { cannedMessagesPath } from '../../helpers/Common';

function EditCannedResponse({ id, type, editLoading, ...props }) {
  const [cannedResponse, setCannedResponse] = useState({ short_code: '', message: '' });
  const getCannedMessage = id => {
    return (props.cannedMessages || []).find(cm => cm.id === id);
  };
  useEffect(() => {
    if (type === 'new') setCannedResponse({ id, short_code: '', message: '' });
    else setCannedResponse(getCannedMessage(id));
  }, []);
  const handleClose = e => {
    store.dispatch({ type: actionType.CLOSE_EDIT_CANNED_MESSAGE });
  };
  const handleCreate = async e => {
    store.dispatch({ type: actionType.EDIT_CANNED_MESSAGE_START });
    let data = null;
    try {
      data = await fetch(cannedMessagesPath(), {
        credentials: 'include',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(cannedResponse)
      }).then(res => res.json());
    } catch (error) {
      return store.dispatch({ type: actionType.CLEAR_CANNED_LOADER });
    }
    store.dispatch({
      type: actionType.ADD_CANNED_MESSAGE_DONE,
      payload: data
    });
  };
  const handleEdit = async e => {
    store.dispatch({ type: actionType.EDIT_CANNED_MESSAGE_START });
    let data = null;
    try {
      data = await fetch(cannedMessagesPath() + '/' + cannedResponse.id, {
        credentials: 'include',
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(cannedResponse)
      }).then(res => res.json());
    } catch (error) {
      return store.dispatch({ type: actionType.CLEAR_CANNED_LOADER });
    }
    store.dispatch({
      type: actionType.EDIT_CANNED_MESSAGE_DONE,
      payload: data
    });
  };
  const handleSubmit = e => {
    if (type === 'new') handleCreate(e);
    else handleEdit(e);
  };
  const isValid = () => {
    return (
      cannedResponse.short_code &&
      cannedResponse.short_code.trim() &&
      cannedResponse.message &&
      cannedResponse.message.trim()
    );
  };
  return (
    <Modal
      show={true}
      className="canned-messages-edit-modal"
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="edit-canned-response">
        {editLoading && (
          <div className="loading-spinner">
            <Spinner animation="border" />
          </div>
        )}
        <div className="canned-messages-header">
          <div className="canned-message-heading">
            {type === 'new' ? 'Add Canned Response' : 'Edit Canned Response'}
          </div>
          <div className="close-wrapper">
            <i className="fa fa-times" aria-hidden="true" onClick={handleClose}></i>
          </div>
        </div>
        <div className="new-canned-message-tag">
          <span className="input-header-tag">Shortcode*</span>
          <Form.Control
            size="sm"
            type="text"
            className="canned-message-tag-input"
            placeholder="Shortcode"
            value={cannedResponse.short_code}
            onChange={e => setCannedResponse({ ...cannedResponse, short_code: e.target.value })}
            autoFocus={true}
          />
        </div>
        <div className="new-canned-message-message">
          <span className="input-header-tag">Message*</span>
          <Form.Control
            size="sm"
            type="text"
            as="textarea"
            className="canned-message-message-input"
            placeholder="Message"
            value={cannedResponse.message}
            onChange={e => setCannedResponse({ ...cannedResponse, message: e.target.value })}
          />
        </div>
        <div className="new-canned-message-action">
          <div className="cm-action-button">
            <Button variant="outline-secondary" onClick={handleClose}>
              Cancel
            </Button>
          </div>
          <div className="cm-action-button">
            <Button variant="success" onClick={handleSubmit} disabled={!isValid()}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
const mapStateToProps = state => {
  return {
    cannedMessages: state.cannedMessages.cannedMessages,
    cannedMessageId: state.cannedMessages.cannedMessageId,
    editLoading: state.cannedMessages.editLoading
  };
};

export default connect(mapStateToProps, null)(EditCannedResponse);
