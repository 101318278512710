import React, { Component } from 'react';
import Teammate from './Teammate';
import InviteTeammate from './InviteTeammate';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getTeammatesAsync } from '../actions/SettingAction';
import TeammateDetail from './TeammateDetail';

class Teammates extends Component {
  constructor(props) {
    super(props);

    this.props.detailHeader('teammates');

    this.handleInvitation = this.handleInvitation.bind(this);
    this.handleTeammate = this.handleTeammate.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getTeammatesAsync());
  }

  handleInvitation() {
    this.props.detailComponent(
      <InviteTeammate
        detailHeader={this.props.detailHeader}
        detailComponent={this.props.detailComponent}
      />
    );
  }

  handleTeammate(teammate) {
    this.props.detailComponent(<TeammateDetail teammate={teammate} />);
  }

  render() {
    return (
      <div>
        <Button variant="primary" onClick={this.handleInvitation}>
          Invite a teammate
        </Button>

        {this.props.teammates.map((teammate, index) => (
          <Teammate key={index} teammate={teammate} handleTeammate={this.handleTeammate} />
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  teammates: state.teammates.teammates,
  loading: state.teammates.loading,
  error: state.teammates.error
});

export default connect(mapStateToProps)(Teammates);
