import React, { Component } from 'react';
import { connect } from 'react-redux';
import { inboxesPath ,loginPathWithoutSlug} from '../helpers/Common';
import SlugContainer from '../components/SlugContainer';

import Login from '../components/Login';
import Loader from '../components/Loader.js';

import {
  getInboxes,
  getInboxesWithoutSlug,
} from '../actions/Session';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin:false,
      loginClicked:false
    };
  }
  
  handleLogin=(params)=> {
    this.setState({showLogin:false,loginClicked:true});
    let url = loginPathWithoutSlug();
    this.props.getInboxesWithoutSlug(url, params);
  }

  componentDidMount() {
    let url = inboxesPath();
    this.props.getInboxes(url);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { inboxes} = nextProps;
    
    if(inboxes && inboxes.length>0){
      this.setState({showLogin:false});
      if(inboxes && inboxes.length==1) this.redirectToSlug(inboxes[0].slug);
    }else{
      this.setState({showLogin:true});
    }
  }

  redirectToSlug=(slug)=>{
    this.props.history.push(slug);
  }

  handleClose=()=>{
    this.setState({ showLogin: false });
  }
  render() {
    const {inboxes,inboxesLoading}=this.props;
    if(inboxesLoading) return <Loader/>;
    if(inboxes && inboxes.length>1){
      return <SlugContainer inboxes={this.props.inboxes} handleClick={this.redirectToSlug} />;
    }
    if(this.state.showLogin && !inboxesLoading){
      return <Login
              show={this.state.showLogin}
              onHide={this.handleClose}
              handleLogin={this.handleLogin}
              loginError={this.props.inboxesError}
              loginClicked={this.state.loginClicked}
            />
    }
    return <Loader/>;
  }
}
const mapStateToProps = state => {
  return { inboxes: state.inboxes.inboxes, 
           inboxesError: state.inboxes.error,
           inboxesLoading:state.inboxes.loading,
         };
};
export default connect(mapStateToProps, { getInboxes,getInboxesWithoutSlug })(Home);
