export const data = {
  teams: [
    {
      id: 1,
      name: 'Bing bang theory'
    },
    {
      id: 2,
      name: 'Spartacus'
    },
    {
      id: 3,
      name: 'Game of thrones'
    }
  ],
  teams2: [
    {
      id: 1,
      name: 'Bing bang theory'
    },
    {
      id: 2,
      name: 'Spartacus'
    },
    {
      id: 3,
      name: 'Game of thrones'
    },
    {
      id: 4,
      name: 'Avengers'
    }
  ],

  teams3: [
    {
      id: 1,
      name: 'Bing bang theory',
      isAccess: true
    },
    {
      id: 2,
      name: 'Spartacus',
      isAccess: true
    },
    {
      id: 3,
      name: 'Game of thrones',
      isAccess: false
    },
    {
      id: 4,
      name: 'Avengers',
      isAccess: true
    }
  ],

  teammates: [
    {
      id: 1,
      name: 'Pushpendra',
      email: 'pushpendra@devathoin.com',
      admin: true
    },
    {
      id: 2,
      name: 'Pushpendra 1',
      email: 'pushpendra1@devathoin.com',
      admin: false
    },
    {
      id: 2,
      name: 'Pushpendra2',
      email: 'pushpendra2@devathoin.com',
      admin: false
    },
    {
      id: 4,
      name: 'Pushpendra3',
      email: 'pushpendra3@devathoin.com',
      admin: false
    }
  ],

  message: {
    text: 'Check out this products...',
    attachment: {
      type: 'CAROUSEL',
      items: [
        {
          title: 'Denims Regular Fit',
          subtitle: 'Price: INR2400',
          media: {
            type: 'THUMBNAIL',
            url: 'https://goo.gl/5PZzPs'
          },
          actions: [
            {
              type: 'URL',
              text: 'Show Product',
              data: {
                url: 'https://en.wikipedia.org/wiki/Jeans'
              }
            },
            {
              type: 'SHARE',
              text: 'Share',
              data: {
                url: 'https://en.wikipedia.org/wiki/Jeans'
              }
            }
          ]
        },

        {
          title: 'Denims Skin Fit',
          subtitle: 'Price: INR2200',
          media: {
            type: 'THUMBNAIL',
            url: 'https://goo.gl/nNerBf'
          },
          actions: [
            {
              type: 'URL',
              text: 'Show Product',
              data: {
                url: 'https://en.wikipedia.org/wiki/Jeans#Recent_evolution'
              }
            },
            {
              type: 'SHARE',
              text: 'Share',
              data: {
                url: 'https://en.wikipedia.org/wiki/Jeans#Recent_evolution'
              }
            }
          ]
        }
      ]
    },
    actions: [
      {
        type: 'quick_reply',
        text: 'Show More',
        data: {
          payload: 'SHOW_MORE_PRODUCTS'
        }
      },

      {
        type: 'location_request',
        text: 'Send Location'
      }
    ]
  },

  chatList: [
    {
      id: 1,
      text: 'Hello can you provide me some discount on purple skybag',
      sender: 'abc',
      profile: 'http://defumblr.com/img/chat_heads_icon.png'
    },
    {
      id: 2,
      text: 'Sure sir please provide me the product Id',
      sender: 'me',
      profile: 'http://defumblr.com/img/chat_heads_icon.png'
    },
    {
      id: 3,
      text: 'Here it is 455478',
      sender: 'abc',
      profile: 'http://defumblr.com/img/chat_heads_icon.png'
    },
    {
      id: 4,
      text:
        'According to your past shopping fhkjfdhk jkfhfdkfhd hjfhkdfj jhfdkfd kjfdhkjfd j' +
        'fdhfdkjhjk kdfjhkgjd fkjhfd huhjfk kjfhkjf results we have found that you are on' +
        'e of our valuble customer so we are providing you 30% discount :)',
      sender: 'me',
      profile: 'http://defumblr.com/img/chat_heads_icon.png'
    },
    {
      id: 4,
      text:
        'According to your past shopping fhkjfdhk jkfhfdkfhd hjfhkdfj jhfdkfd kjfdhkjfd j' +
        'fdhfdkjhjk kdfjhkgjd fkjhfd huhjfk kjfhkjf results we have found that you are on' +
        'e of our valuble customer so we are providing you 30% discount :)',
      sender: 'abc',
      profile: 'http://defumblr.com/img/chat_heads_icon.png'
    }
  ],
  bots: [
    {
      name: 'direct'
    },
    {
      name: 'support bot'
    },
    {
      name: 'sales bot'
    }
  ]
};
