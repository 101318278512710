import React, { Component } from 'react';
import {
  Modal,
  Button,
  Form,
  FormGroup,
  Col,
  FormLabel,
  FormControl,
  Alert
} from 'react-bootstrap';
import { validateEmail, validatePassword } from '../helpers/validation';
import { getForgetPasswordPath } from '../helpers/Common';
import Spinner from 'react-bootstrap/Spinner';

const params = {
  email: '',
  password: ''
};

const inValidEmail = 'Invalid Email';
const inValidPassword = 'Password should be minimum 6 letters long';

// This component is dumb no api calls from here sending data to parent
// component(dashboard)..getting session from there
export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      emailValidationState: null,
      passwordValidationState: null,
      inValidEmail: '',
      inValidPassword: '',
      showLoader: false
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePassChange = this.handlePassChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleEmailChange(e) {
    this.setState({
      email: e.target.value,
      emailValidationState: null,
      inValidEmail: null
    });
  }

  handlePassChange(e) {
    this.setState({
      password: e.target.value,
      passwordValidationState: null,
      inValidPassword: null
    });
  }

  handleLogin() {
    let email = this.state.email;
    let password = this.state.password;

    let isValidEmail = validateEmail(email);
    let isValidPassword = validatePassword(password);

    this.setState({ showLoader: false });

    if (!isValidEmail) {
      this.setState({
        emailValidationState: 'error',
        inValidEmail: inValidEmail
      });
      return;
    } else if (!isValidPassword) {
      this.setState({
        passwordValidationState: 'error',
        inValidPassword: inValidPassword
      });
      return;
    } else {
      params.email = email;
      params.password = password;
      this.props.handleLogin(params);
      this.setState({ showLoader: true });
    }
  }
  handleForgotPassword = () => {
    window.location.replace(getForgetPasswordPath());
  };

  render() {
    let { loginError, loginClicked } = this.props;

    return (
      <div>
        <Modal
          show={this.props.show}
          className="custom-modal-login"
          centered
          size="md"
          animation={true}
          onHide={() => {}}
        >
          {loginClicked && loginError && loginError.message && (
            <Alert variant="danger">
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
              {loginError.message}
            </Alert>
          )}
          <Modal.Header className="custom-modal-header-login">
            <Modal.Title className="custom-title">Log-in to your Inbox</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form horizontal="true">
              <FormGroup controlId="formHorizontalEmail">
                <Col sm={6}>Email Address</Col>
                <Col sm={12}>
                  <FormControl
                    type="email"
                    value={this.state.email}
                    placeholder="Email Address"
                    onChange={this.handleEmailChange}
                  />
                  <Form.Text className="text-danger">{this.state.inValidEmail}</Form.Text>
                </Col>
              </FormGroup>

              <FormGroup controlId="formHorizontalPassword">
                <Col sm={2}>Password</Col>
                <Col sm={12}>
                  <FormControl
                    type="password"
                    value={this.state.password}
                    placeholder="password"
                    onChange={this.handlePassChange}
                  />
                  <Form.Text className="text-danger">{this.state.inValidPassword}</Form.Text>
                </Col>
              </FormGroup>

              <FormGroup>
                <Col sm={12}>
                  <div className="text-center">
                    {this.state.showLoader && !loginError && (
                      <Spinner animation="grow" variant="primary" />
                    )}
                  </div>
                  <Button
                    className="form-control m-0 mt-2"
                    variant="primary"
                    onClick={this.handleLogin}
                  >
                    Log in
                  </Button>
                </Col>
              </FormGroup>
              <Col sm={12}>
                <div className="login-link-container">
                  <span className="go-back-login" onClick={this.handleForgotPassword}>
                    Forgot password?
                  </span>
                </div>
              </Col>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
