import { db, firebaseDB } from '../javascripts/Firestore';
import { actionType } from '../constants';
import { conversation } from '../javascripts/models/Conversation';
import store from '../configuration/Store';
import { getStoreKey } from '../helpers/Common';
import { filter } from 'minimatch';
// import uuid from 'uuid';

let dbSubscribe = null;
let minLastSeenAt = null;
let minCreatedAt = null;
const loadMoreLimit = 20;
const chatTypes = ['open', 'closed'];
let latest_conversations_fetch_started_at = Date.now();
let latest_messages_fetch_started_at = Date.now();

const filterConversationsQuery = (query, filters) => {
  filters = filters || {};

  if (filters.chat_only) {
    let chat_status = chatTypes.includes(filters.chat_type.toLowerCase())
      ? [filters.chat_type.toLowerCase()]
      : chatTypes;
    query = query.where('inbox.status', 'in', chat_status);
  }

  if (filters.assignedTo && filters.assignedTo.name === 'me') {
    query = query
      .where('outIntegration.type', '==', 'InboxIntegration')
      .where('inbox.user.id', '==', filters.assignedTo.userId);
  } else if (filters.assignedTo && filters.assignedTo.name === 'unassigned') {
    query = query
      .where('outIntegration.type', '==', 'InboxIntegration')
      .where('inbox.user', '==', null);
  }

  return query;
};

export const getConversations = (inbox, filters) => {
  if (filters !== null && dbSubscribe) {
    dbSubscribe();
  }
  return dispatch => {
    if (filters !== null) {
      dispatch(setCurrentDisplay(filters.chat_only ? filters.chat_type : 'all'));
      dispatch(setCurrentAssigned(filters.assignedTo.name));
    }
    dispatch({
      type: actionType.LOAD_CONVERSATIONS_REQUEST,
      clearConversations: true
    });
    dispatch({
      type: actionType.ACTIVE_CONVERSATION
    });
    dispatch({
      type: actionType.GET_CURRENT_CUSTOMER_SUCCESS
    });
    let query = db()
      .collection('conversations')
      .where('inbox.id', '==', inbox.id)
      .where('inbox.secret_key', '==', inbox.secret_key)
      .where('updatedAt', '>', new Date());

    query = filterConversationsQuery(query, filters);

    minLastSeenAt = null;
    dispatch(loadConversationsOnRequest(inbox, filters));
    dbSubscribe = query.onSnapshot(
      querySnapshot => {
        let conversations = {};
        querySnapshot.forEach(function(doc) {
          let conversationData = conversation(doc.id, doc.data());
          conversations[conversationData.unique_id] = conversationData;
        });
        let { uniqueId } = store.getState().activeConversation;
        if (uniqueId && conversations[uniqueId]) {
          dispatch({
            type: actionType.ACTIVE_CONVERSATION,
            payload: conversations[uniqueId]
          });
        }
        dispatch({
          type: actionType.LOAD_CONVERSATIONS_SUCCESS,
          payload: conversations
        });
      },
      error => {
        console.log('Error getting documents: ', error);
        if (
          error.message === 'INVALID_CUSTOM_TOKEN : TOKEN_EXPIRED' ||
          error.message === 'TOKEN_EXPIRED'
        ) {
          dispatch({
            type: actionType.SESSION_EXPIRY,
            payload: error
          });
        } else {
          dispatch({
            type: actionType.LOAD_CONVERSATIONS_FAILED,
            payload: error
          });
        }
      }
    );
  };
};

export const loadConversationsOnRequest = (inbox, filters) => {
  let fetch_conversations_start_time = Date.now();
  latest_conversations_fetch_started_at = fetch_conversations_start_time;
  return dispatch => {
    dispatch({
      type: actionType.LOAD_CONVERSATIONS_REQUEST
    });
    let query = db()
      .collection('conversations')
      .where('inbox.id', '==', inbox.id)
      .where('inbox.secret_key', '==', inbox.secret_key)
      .orderBy('lastSeenAt', 'desc');

    if (filters && filters.live_visitors) {
      query = query.where('lastSeenAt', '>', new Date(new Date().getTime() - 1 * 60 * 1000));
    }
    query = filterConversationsQuery(query, filters);
    query = query.startAt(minLastSeenAt || new Date()).limit(loadMoreLimit);
    query
      .get()
      .then(function(querySnapshot) {
        //To check whether this result belongs to latest filter or not
        if (fetch_conversations_start_time !== latest_conversations_fetch_started_at) return;
        let conversations = {};
        querySnapshot.forEach(function(doc) {
          let conversationData = conversation(doc.id, doc.data());
          conversations[conversationData.unique_id] = conversationData;
          if (
            conversationData.data &&
            conversationData.data.lastSeenAt &&
            (!minLastSeenAt || minLastSeenAt > conversationData.data.lastSeenAt)
          ) {
            minLastSeenAt = conversationData.data.lastSeenAt;
          }
        });
        dispatch({
          type: actionType.LOAD_CONVERSATIONS_SUCCESS,
          payload: conversations
        });
      })
      .catch(function(error) {
        console.log('Error getting documents: ', error);
        if (
          error.message === 'INVALID_CUSTOM_TOKEN : TOKEN_EXPIRED' ||
          error.message === 'TOKEN_EXPIRED'
        ) {
          dispatch({
            type: actionType.SESSION_EXPIRY,
            payload: error
          });
        } else {
          dispatch({
            type: actionType.LOAD_CONVERSATIONS_FAILED,
            payload: error
          });
        }
      });
  };
};

export const updateActiveUsers = (conversation, user, type, time) => {
  return dispatch => {
    time = time || new Date().getTime();
    db()
      .collection('conversations')
      .doc(conversation.unique_id)
      .update({
        updatedAt: new Date(),
        ['inbox.activeUsers.' + user.id]: {
          type: type,
          at: time,
          name: user.name
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
};

const getMessageStructure = (message, messageId) => {
  // TODO change createdAt to firebase server timestamp
  const createdAt = {
    seconds: Math.round(new Date().getTime() / 1000)
  };
  const localMessageFormat = {
    message: {
      message: {
        text: message.message.text,
        type: message.message.type,
        attachment: message.message.attachment || []
      }
    }
  };

  const senderFormat = {
    sender: {
      data: {
        id: message.message.sender.id
      },
      inbox: {
        id: message.inbox.id
      },
      uniqueIdentifier: message.message.sender.id
    }
  };
  const sendingMessage = {
    createdAt,
    ...localMessageFormat,
    ...senderFormat,
    messageId
  };
  return sendingMessage;
};

let unsubscribe = null;
export const getMessages = id => {
  if (unsubscribe !== null && unsubscribe !== undefined) {
    unsubscribe();
  }

  return dispatch => {
    dispatch({
      type: actionType.LOAD_MESSAGE_REQUEST,
      clearOldMessages: true
    });

    minCreatedAt = null;
    dispatch(loadMessagesOnRequest(id));

    unsubscribe = db()
      .collection('conversations')
      .doc(id)
      .collection('messages')
      .orderBy('createdAt')
      .where('createdAt', '>', new Date())
      .onSnapshot(
        function(snapshot) {
          let messageArray = [];

          snapshot.docChanges().forEach(change => {
            if (change.type === 'added') {
              const changedData = {
                ...change.doc.data()
              };

              const { seconds } = changedData.createdAt;
              // const { data, ...remainingData } = changedData.sender;
              messageArray.push({
                ...change.doc.data(),
                createdAt: {
                  seconds
                },
                messageId: changedData.message.message.id || change.doc.id
                // sender: { ...remainingData }
              });
            }
          });
          if (messageArray.length > 0) {
            // debugger;
            dispatch({
              type: actionType.LOAD_MESSAGE_SUCCESS,
              payload: messageArray,
              newMessages: true
            });
          }
        },
        function(error) {
          if (
            error.message === 'INVALID_CUSTOM_TOKEN : TOKEN_EXPIRED' ||
            error.message === 'TOKEN_EXPIRED'
          ) {
            dispatch({
              type: actionType.SESSION_EXPIRY,
              payload: error
            });
          } else {
            dispatch({
              type: actionType.LOAD_MESSAGE_SUCCESS,
              payload: error,
              newMessages: true
            });
          }
        }
      );
  };
};
export const loadMessagesOnRequest = id => {
  let fetch_messages_start_time = Date.now();
  latest_messages_fetch_started_at = fetch_messages_start_time;
  return dispatch => {
    dispatch({
      type: actionType.LOAD_MESSAGE_REQUEST,
      clearOldMessages: minCreatedAt ? false : true
    });
    db()
      .collection('conversations')
      .doc(id)
      .collection('messages')
      .orderBy('createdAt', 'desc')
      .startAt(minCreatedAt || new Date())
      .limit(50)
      .get()
      .then(
        function(snapshot) {
          //To check whether this result belongs to latest selected conversation or not
          if (fetch_messages_start_time !== latest_messages_fetch_started_at) return;
          let messageArray = [];
          snapshot.docChanges().forEach(change => {
            if (change.type === 'added') {
              const changedData = {
                ...change.doc.data()
              };

              const { seconds } = changedData.createdAt;
              // const { data, ...remainingData } = changedData.sender;
              messageArray.push({
                ...change.doc.data(),
                createdAt: {
                  seconds
                },
                messageId: changedData.message.message.id || change.doc.id
                // sender: { ...remainingData }
              });

              if (
                changedData &&
                changedData.createdAt &&
                (!minCreatedAt || minCreatedAt > changedData.createdAt)
              ) {
                minCreatedAt = changedData.createdAt;
              }
            }
          });
          if (messageArray.length > 0) {
            // debugger;
            dispatch({
              type: actionType.LOAD_MESSAGE_SUCCESS,
              payload: messageArray,
              newMessages: false
            });
          }
        },
        function(error) {
          if (
            error.message === 'INVALID_CUSTOM_TOKEN : TOKEN_EXPIRED' ||
            error.message === 'TOKEN_EXPIRED'
          ) {
            dispatch({
              type: actionType.SESSION_EXPIRY,
              payload: error
            });
          } else {
            dispatch({
              type: actionType.LOAD_MESSAGE_SUCCESS,
              payload: error,
              newMessages: false
            });
          }
        }
      );
  };
};

export const resetPendingMessages = () => {
  return {
    type: 'RESET_PENDING_MESSAGES'
  };
};
const getInbox = () => {
  let session = store.getState().session;
  if (session && session.session && session.session.inbox) return session.session.inbox;
  return null;
};
export const loadStoreStatus = () => {
  return dispatch => {
    let inbox = getInbox();
    if (inbox) {
      var ref = firebaseDB().ref(`inbox/${inbox.id}`);
      try {
        ref.on('value', function(snapshot) {
          let data = snapshot.val();
          if (data === null) data = { offline: false };
          dispatch({
            type: actionType.LOAD_STORE_STATUS,
            payload: data
          });
        });
      } catch (e) {
        console.log('load store status error', e);
      }
    }
  };
};
export const updateStoreStatus = () => {
  const session = JSON.parse(localStorage.getItem(getStoreKey()));
  let inbox = getInbox();
  if (!inbox) return;
  let storeData = store.getState().storeData;
  let status = storeData.store && storeData.store.offline;
  return dispatch => {
    let user = {};
    if (session && session.user) {
      user.id = session.user.id;
      user.email = session.user.email;
    }
    inbox = { id: inbox.id, name: inbox.name };
    let data = {
      updatedBy: user,
      updatedAt: new Date().getTime(),
      offline: !status,
      inbox
    };
    firebaseDB()
      .ref(`/inbox/${inbox.id}`)
      .set(data)
      .then(res => {
        // console.log('store status updated', res);
      })
      .catch(e => {
        console.log('error in store status update', e);
      });
  };
};

export const addMessage = message => {
  // let temp ={
  //     text: 'Check out this products...',
  //     type: 'message',
  //     attachment: {
  //       type: 'CAROUSEL',
  //       items: [
  //         {
  //           title: 'Denims Regular Fit',
  //           subtitle: 'Price: INR2400',
  //           media: {
  //             type: 'THUMBNAIL',
  //             url: 'https://4.imimg.com/data4/RB/TK/MY-1136511/silky-lycra-mens-jeans-250x250.jpg'
  //           },
  //           actions: [
  //             {
  //               type: 'URL',
  //               text: 'Show Product',
  //               data: {
  //                 url: 'https://en.wikipedia.org/wiki/Jeans'
  //               }
  //             },
  //             {
  //               type: 'SHARE',
  //               text: 'Share',
  //               data: {
  //                 url: 'https://en.wikipedia.org/wiki/Jeans'
  //               }
  //             }
  //           ]
  //         },

  //         {
  //           title: 'Ripped jeans fit',
  //           subtitle: 'Price: INR2200',
  //           media: {
  //             type: 'THUMBNAIL',
  //             url: 'https://images-na.ssl-images-amazon.com/images/I/715O%2BFtMLmL._AC_UY1000_.jpg'
  //           },
  //           // actions: [
  //           //   {
  //           //     type: 'URL',
  //           //     text: 'Show Product',
  //           //     data: {
  //           //       url: 'https://en.wikipedia.org/wiki/Jeans#Recent_evolution'
  //           //     }
  //           //   },
  //           //   {
  //           //     type: 'SHARE',
  //           //     text: 'Share',
  //           //     data: {
  //           //       url: 'https://en.wikipedia.org/wiki/Jeans#Recent_evolution'
  //           //     }
  //           //   }
  //           // ]
  //         },
  //         {
  //           title: 'Dummy Jeans',
  //           subtitle: 'Price: INR9200',
  //           media: {
  //             type: 'THUMBNAIL',
  //             url: 'https://images-na.ssl-images-amazon.com/images/I/81kv6dIAc7L._UL1500_.jpg'
  //           },
  //           // actions: [
  //           //   {
  //           //     type: 'URL',
  //           //     text: 'Show Product',
  //           //     data: {
  //           //       url: 'https://en.wikipedia.org/wiki/Jeans#Recent_evolution'
  //           //     }
  //           //   },
  //           //   {
  //           //     type: 'SHARE',
  //           //     text: 'Share',
  //           //     data: {
  //           //       url: 'https://en.wikipedia.org/wiki/Jeans#Recent_evolution'
  //           //     }
  //           //   }
  //           // ]
  //         }
  //       ]
  //     },
  //     // actions: [
  //     //   {
  //     //     type: 'quick_reply',
  //     //     text: 'Show More',
  //     //     data: {
  //     //       payload: 'SHOW_MORE_PRODUCTS'
  //     //     }
  //     //   },

  //     //   {
  //     //     type: 'location_request',
  //     //     text: 'Send Location'
  //     //   }
  //     // ]
  //   }
  //   message.message.attachment=temp.attachment;
  // message.message.actions=temp.actions;

  return dispatch => {
    let key = firebaseDB()
      .ref('/messages')
      .push().key;

    // dispatch({ type: 'MESSAGE_UPDATE_LOCAL', payload: message });
    const messagefirebaseKey = firebaseDB(key).ref('/messages/' + key);

    if (message.message.type !== 'event') {
      dispatch({
        type: 'LOAD_MESSAGE_LOCAL',
        payload: [
          {
            ...getMessageStructure(message, key)
          }
        ]
      });
    }

    messagefirebaseKey
      .set(message)
      .then(res => {
        dispatch({
          type: actionType.ADD_CONVERSATION_SUCCESS,
          payload: res
        });
      })
      .catch(error => {
        if (
          error.message === 'INVALID_CUSTOM_TOKEN : TOKEN_EXPIRED' ||
          error.message === 'TOKEN_EXPIRED'
        ) {
          dispatch({
            type: actionType.SESSION_EXPIRY,
            payload: error
          });
        } else {
          dispatch({
            type: actionType.ADD_CONVERSATION_FAILED,
            payload: error
          });
        }
      });
  };
};

export const addAssignedMessage = (conversation, handoverto) => {
  return dispatch => {
    const assignee = {
      type: conversation.data.outIntegration.type,
      name: conversation.data.outIntegration.name
    };
    if (handoverto.inboxIntegration && handoverto.user) {
      assignee.agent = {
        name: handoverto.user.name,
        image: handoverto.user.image
      };
    }

    const recipient = conversation.data.sender;
    recipient.integration = conversation.data.inIntegration;
    recipient.conversationId = conversation.unique_id;

    const message = {
      type: 'event',
      events: [
        {
          name: 'assigned',
          data: assignee,
          text: `Assigned to ${assignee.agent ? assignee.agent.name : assignee.name}`
        }
      ],
      recipient,
      sender: {}
    };
    const session = JSON.parse(localStorage.getItem(getStoreKey()));
    if (session && session.user) {
      message.sender.id = session.user.id;
    }
    dispatch(
      addMessage({
        inbox: {
          id: conversation.data.inbox.id
        },
        message
      })
    );
  };
};

// const appendMessage = message => {
//   let key = firebaseDB()
//     .ref('/messages')
//     .push().key;
//   return firebaseDB(key)
//     .ref('/messages/' + key)
//     .set(message);
// };

export const activeConversation = curConversation => {
  let { conversation } = store.getState().activeConversation;
  if (
    conversation !== null &&
    conversation !== undefined &&
    conversation.hasOwnProperty('unique_id') &&
    unsubscribe !== null &&
    unsubscribe !== undefined
  ) {
    unsubscribe();
  }
  return dispatch => {
    dispatch({
      type: actionType.ACTIVE_CONVERSATION,
      payload: curConversation
    });
  };
};

export const activeHeaderConversation = conversation => {
  return dispatch => {
    dispatch({
      type: actionType.ACTIVE_HEADER_CONVERSATION,
      payload: conversation
    });
  };
};

export const handoverConversation = (conversation, handoverto) => {
  if (handoverto.inboxIntegration) {
    conversation.data.outIntegration = handoverto.inboxIntegration;
    if (handoverto.user) {
      conversation.data.inbox.user = handoverto.user;
    } else {
      delete conversation.data.inbox.user;
    }
  } else {
    conversation.data.outIntegration = handoverto;
    delete conversation.data.inbox.user;
  }

  const data = {
    inbox: conversation.data.inbox,
    outIntegration: conversation.data.outIntegration
  };
  return dispatch => {
    dispatch({
      type: actionType.HAND_OVER_CONVERSATION_REQUEST
    });
    db()
      .collection('conversations')
      .doc(conversation.unique_id)
      .update(data)
      .then(function(doc) {
        // if (doc.exists) {
        dispatch({
          type: actionType.HAND_OVER_CONVERSATION_SUCCESS
        });
        dispatch({
          type: actionType.ACTIVE_CONVERSATION,
          payload: conversation
        });
        dispatch(addAssignedMessage(conversation, handoverto));
        // } else {
        //     // doc.data() will be undefined in this case
        //     dispatch({type: actionType.UPDATE_CONVERSATION_FAILED})
        // }
      })
      .catch(function(error) {
        if (
          error.message === 'INVALID_CUSTOM_TOKEN : TOKEN_EXPIRED' ||
          error.message === 'TOKEN_EXPIRED'
        ) {
          dispatch({
            type: actionType.SESSION_EXPIRY,
            payload: error
          });
        } else {
          dispatch({
            type: actionType.HAND_OVER_CONVERSATION_FAILED
          });
        }
      });
  };
};

export const loadAssignee = conversationObj => {
  let payload = {
    conversation: conversationObj
  };
  if (conversationObj != null && conversationObj.data != null) {
    payload.conversation = conversation(conversationObj.unique_id, conversationObj.data);
  }

  return dispatch => {
    dispatch({
      type: actionType.LOAD_CONVERSATION_ASSIGNEE,
      payload: payload
    });
  };
};

export const unansweredConversations = inbox => {
  return dispatch => {
    let query = db()
      .collection('conversations')
      .where('inbox.id', '==', inbox.id)
      .where('inbox.secret_key', '==', inbox.secret_key)
      .where('responsePending', '==', true)
      .where('lastMessageAt', '>', new Date(new Date().getTime() - 24 * 3600 * 1000));
    // TODO : use select

    query.onSnapshot(querySnapshot => {
      querySnapshot.docChanges().forEach(change => {
        dispatch({
          type: actionType.UNANSWERED_COUNT,
          payload: change.type
        });
      });
    });
    return null;
  };
};

//export const filterConversations = (inbox, filters) => {
//  let conversations = [],
//    conversationData;
//  return dispatch => {
//    dispatch(setCurrentDisplay(filters.status));
//    dispatch({ type: actionType.FILTER_CONVERSATIONS_REQUEST });
//    let query = db()
//      .collection('conversations')
//      .where('inbox.id', '==', inbox.id)
//      .where('inbox.secret_key', '==', inbox.secret_key);
//    if (filters.status !== 'all') {
//      query = query.where('inbox.status', '==', filters.status.toLowerCase());
//    }
//
//    if (filters.assignedTo.name === 'me') {
//      query = query
//        .where('outIntegration.type', '==', 'InboxIntegration')
//        .where('inbox.user.id', '==', filters.assignedTo.userId);
//    } else if (filters.assignedTo.name === 'unassigned') {
//      query = query
//        .where('outIntegration.type', '==', 'InboxIntegration')
//        .where('inbox.user', '==', null);
//    }
//
//    query.onSnapshot(
//      snapshot => {
//        snapshot.forEach(doc => {
//          conversationData = conversation(doc.id, doc.data());
//          //console.log("Filtered data")
//
//          conversations.push(conversationData);
//        });
//        dispatch({
//          type: actionType.FILTER_CONVERSATIONS_SUCCESS,
//          payload: conversations
//        });
//        if (conversations.length > 0) {
//          dispatch({
//            type: actionType.ACTIVE_CONVERSATION,
//            payload: conversations[0],
//          });
//        } else {
//          dispatch({
//            type: actionType.ACTIVE_CONVERSATION,
//            payload: { notAvailable: true },
//          });
//        }
//        conversations = [];
//      },
//      error => {
//        console.log('error', error);
//        if (
//          error.message === 'INVALID_CUSTOM_TOKEN : TOKEN_EXPIRED' ||
//          error.message === 'TOKEN_EXPIRED'
//        ) {
//          dispatch({ type: actionType.SESSION_EXPIRY, payload: error });
//        } else {
//          dispatch({
//            type: actionType.FILTER_CONVERSATIONS_FAILED,
//            payload: error
//          });
//        }
//      }
//    );
//  };
//};

export const modifyConversationStatus = (_conversation, status) => {
  const data = { updatedAt: new Date(), 'inbox.status': status, responsePending: false };

  return (dispatch, getState) => {
    db()
      .collection('conversations')
      .doc(_conversation.unique_id)
      .update(data)
      .then(doc => {
        let currentDisplay = getState().conversation.currentDisplay;
        if (currentDisplay == 'all' || currentDisplay == 'live' || currentDisplay === status) {
          let conversationData = conversation(_conversation.unique_id, data);
          // TODO update conversation, status
          dispatch({
            type: actionType.ACTIVE_CONVERSATION
          });
          dispatch({
            type: actionType.LOAD_CONVERSATIONS_SUCCESS,
            payload: {
              [conversationData.unique_id]: conversationData
            }
          });
        } else {
          dispatch({
            type: actionType.ACTIVE_CONVERSATION
          });
          dispatch({
            type: actionType.REMOVE_CONVERSATION,
            payload: {
              conversationId: _conversation.unique_id
            }
          });
        }
      })
      .catch(function(error) {
        console.log('Error updating document:', error);
        if (
          error.message === 'INVALID_CUSTOM_TOKEN : TOKEN_EXPIRED' ||
          error.message === 'TOKEN_EXPIRED'
        ) {
          dispatch({
            type: actionType.SESSION_EXPIRY,
            payload: error
          });
        }
      });
  };
};

export const setCurrentDisplay = status => {
  return dispatch => {
    dispatch({
      type: actionType.SET_CURRENT_DISPLAY,
      payload: status
    });
  };
};
export const setCurrentAssigned = status => {
  return dispatch => {
    dispatch({
      type: actionType.SET_CURRENT_ASSIGNED,
      payload: status
    });
  };
};

export const getSearchProducts = (url, token) => {
  url = url + '&api_token=' + token;
  return dispatch => {
    dispatch({
      type: actionType.GET_SEARCH_PRODUCTS_REQUEST
    });
    fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(handleErrors)
      .then(res => res.json())
      .then(res => {
        dispatch({
          type: actionType.GET_SEARCH_PRODUCTS_SUCCESS,
          payload: res
        });
      })
      .catch(error => {
        dispatch({
          type: actionType.GET_SEARCH_PRODUCTS_ERROR,
          payload: error
        });
      });
  };
};

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
export const getCustomerOrders = (url, token) => {
  url = url + '?api_token=' + token;
  return dispatch => {
    dispatch({
      type: actionType.GET_CUSTOMER_ORDERS_REQUEST
    });
    fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(handleErrors)
      .then(res => res.json())
      .then(res => {
        dispatch({
          type: actionType.GET_CUSTOMER_ORDERS_SUCCESS,
          payload: res
        });
      })
      .catch(error => {
        dispatch({
          type: actionType.GET_CUSTOMER_ORDERS_ERROR,
          payload: error
        });
      });
  };
};

export const setFileUploadDetails = data => {
  return dispatch => {
    dispatch({
      type: actionType.SET_FILE_UPLOAD_BYTES,
      payload: data
    });
  };
};

export const setProductsToCarousel = data => {
  return dispatch => {
    dispatch({
      type: actionType.SET_PRODUCTS_TO_CAROUSEL,
      payload: data
    });
  };
};
