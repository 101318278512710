import React from 'react';
import { Modal } from 'react-bootstrap';
import store from '../../configuration/Store';
import { actionType } from '../../constants';
import ManageCannedResponse from './ManageCannedResponse';

export default function CannedResponseModal(props) {
  const handleClose = e => {
    store.dispatch({ type: actionType.TOGGLE_CANNED_MESSAGES });
  };
  const handleOnSelect = message => {
    if (store.getState().activeConversation.conversation) {
      handleClose();
      store.dispatch({ type: actionType.ADD_CANNED_MESSAGE_TO_INPUT, payload: message });
    }
  };
  return (
    <Modal
      show={true}
      className="canned-messages-modal"
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ManageCannedResponse onClose={handleClose} selectCannedMessage={handleOnSelect} />
    </Modal>
  );
}
