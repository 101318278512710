var _store_key = null;
var _slug = null;

export const setStoreKey = storeKey => {
  _store_key = storeKey + '-SessionData';
  return _store_key;
};

export const getStoreKey = () => {
  return _store_key;
};

export const setSlug = slug => {
  _slug = slug;
  return _slug;
};

export const getSlug = () => {
  return _slug;
};
const APP_ENV = `${process.env.REACT_APP_APP_PATH}`;
const API_ENV = `${process.env.REACT_APP_API_PATH}`;
const INBOX_PATH = APP_ENV + '/api/v1/inbox/';

export const sessionPath = () => {
  return INBOX_PATH + getSlug() + '/session';
};

export const loginPath = () => {
  return INBOX_PATH + getSlug() + '/login';
};
export const loginPathWithoutSlug = () => {
  return INBOXES_PATH + 'login';
};

export const usersPath = () => {
  return INBOX_PATH + getSlug() + '/users';
};

export const cannedMessagesPath = () => {
  return INBOX_PATH + getSlug() + '/canned_responses';
};

export const outIntegrationsPath = () => {
  return INBOX_PATH + getSlug() + '/out_integrations';
};

const INBOXES_PATH = APP_ENV + '/api/v1/inboxes/';

export const inboxesPath = () => {
  return INBOXES_PATH;
};

export const getLogoutPath = () => {
  return APP_ENV + '/api/v1/inboxes/logout';
};
export const getForgetPasswordPath = () => {
  return APP_ENV + '/users/password/new';
};

export const getUserUpdatePath = () => {
  return APP_ENV + '/api/v1/profile';
};

export const searchEcommerceProductsUrl = (conversationId, searchText) => {
  return API_ENV + '/v1/ecommerce/' + conversationId + '/products?query=' + searchText;
};

export const getCustomerOrdersUrl = conversationId => {
  return API_ENV + '/v1/ecommerce/' + conversationId + '/customer/orders';
};

export const getImage = (id, type) => {
  let val = id % 17;

  let maxSize = '210px';
  let maxcoords = {
    1: { position: '-80px -80px', src: 'profile_pack2.png' }, //  2,2
    2: { position: '-14px -14px', src: 'profile_pack2.png' }, //  1,1
    3: { position: '-14px -80px', src: 'profile_pack2.png' }, //  2,1
    4: { position: '-14px -145px', src: 'profile_pack2.png' }, //  3,1
    5: { position: '-80px -145px', src: 'profile_pack2.png' }, //  3,2
    6: { position: '-145px -145px', src: 'profile_pack2.png' }, //  3,3
    7: { position: '-15px -14px', src: 'profile_pack1.png' }, //  2,1
    8: { position: '-80px -15px', src: 'profile_pack1.png' }, //  1,2
    9: { position: '-146px -15px', src: 'profile_pack1.png' }, //  1,3
    10: { position: '-15px -80px', src: 'profile_pack1.png' }, //  2,1
    11: { position: '-80px -80px', src: 'profile_pack1.png' }, //  2,2
    12: { position: '-146px -80px', src: 'profile_pack1.png' }, //  2,3
    13: { position: '-15px -145px', src: 'profile_pack1.png' }, //  3,1
    14: { position: '-80px -145px', src: 'profile_pack1.png' }, //  3,2
    15: { position: '-146px -145px', src: 'profile_pack1.png' }, //  3,3
    16: { position: '-80px -14px', src: 'profile_pack2.png' }, //  1,2
    17: { position: '-145px -14px', src: 'profile_pack2.png' } //  1,3
  };
  let minSize = '137px';
  let mincoords = {
    1: { position: '-52px -52px', src: 'profile_pack2.png' }, //  2,2
    2: { position: '-10px -10px', src: 'profile_pack2.png' }, //  1,1
    3: { position: '-10px -52px', src: 'profile_pack2.png' }, //  2,1
    4: { position: '-10px -95px', src: 'profile_pack2.png' }, //  3,1
    5: { position: '-52px -95px', src: 'profile_pack2.png' }, //  3,2
    6: { position: '-95px -95px', src: 'profile_pack2.png' }, //  3,3
    7: { position: '-10px -10px', src: 'profile_pack1.png' }, //  2,1
    8: { position: '-53px -10px', src: 'profile_pack1.png' }, //  1,2
    9: { position: '-95px -10px', src: 'profile_pack1.png' }, //  1,3
    10: { position: '-10px -52px', src: 'profile_pack1.png' }, //  2,1
    11: { position: '-53px -52px', src: 'profile_pack1.png' }, //  2,2
    12: { position: '-95px -52px', src: 'profile_pack1.png' }, //  2,3
    13: { position: '-10px -95px', src: 'profile_pack1.png' }, //  3,1
    14: { position: '-53px -95px', src: 'profile_pack1.png' }, //  3,2
    15: { position: '-95px -95px', src: 'profile_pack1.png' }, //  3,3
    16: { position: '-52px -10px', src: 'profile_pack2.png' }, //  1,2
    17: { position: '-95px -10px', src: 'profile_pack2.png' } //  1,3
  };
  if (val === 0 || val > 17) val = 17;
  if (type === 'max') {
    return { ...maxcoords[val], size: maxSize };
  } else {
    return { ...mincoords[val], size: minSize };
  }
};
