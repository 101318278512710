//get team constants
export const GET_TEAMS_BEGIN = 'GET_TEAMS_BEGIN';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_FAILURE = 'GET_TEAMS_FAILURE';

//create team constants
export const CREATE_TEAM_BEGIN = 'CREATE_TEAM_BEGIN';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_FAILURE = 'CREATE_TEAM_FAILURE';

//edit team constants
export const EDIT_TEAM_BEGIN = 'EDIT_TEAM_BEGIN';
export const EDIT_TEAM_SUCCESS = 'EDIT_TEAM_SUCCESS';
export const EDIT_TEAM_FAILURE = 'EDIT_TEAM_FAILURE';

//get team constants
export const GET_TEAMMATES_BEGIN = 'GET_TEAMMATES_BEGIN';
export const GET_TEAMMATES_SUCCESS = 'GET_TEAMMATES_SUCCESS';
export const GET_TEAMMATES_FAILURE = 'GET_TEAMMATES_FAILURE';
