// message section
export const messageData = (inbox, message, isReply) => ({
  inbox: inbox,
  message: message,
  isReply: isReply
});
export const noteData = (inbox, note, isReply) => ({ inbox: inbox, note: note, isReply: isReply });
// message
export const message = (text, sender, attachment, actions, isImageUploaded, isMessageSent) => ({
  text: text,
  attachment: attachment,
  actions: actions,
  isImageUploaded: isImageUploaded,
  isMessageSent: isMessageSent,
  sender: sender
});
export const note = text => ({
  text: text
});

// attachment
export const attachment = (items, data, type) => ({ items: items, data: data, type: type });

//actions
export const action = (text, data, type) => ({ text: text, data: data, type: type });

//item
export const item = (title, sub_title, media, actions) => ({
  title: title,
  sub_title: sub_title,
  media: media,
  actions: actions
});

//data
export const data = (url, payload, latitude, longitude) => ({
  rurl: url,
  payload: payload,
  latitude: latitude,
  longitude: longitude
});

//media
export const media = (type, url) => ({ type: type, url: url });

//sender
export const sender = (id, name, imageUrl) => ({
  id: id,
  name: name,
  imageUrl: imageUrl
});
