import React, { Component } from 'react';
import CarouselView from './CarouselView';
import ImageView from './ImageView';
import VideoView from './VideoView';
import AudioView from './AudioView';
import DocumentView from './DocumentView';
import FileView from './FileView';
import { messageType } from '../constants';

export default class Attachment extends Component {
  render() {
    let attachment = this.props.message.attachment;
    if (attachment !== null && attachment !== undefined && attachment.type !== undefined) {
      if (attachment.type.toUpperCase() === messageType.CAROUSEL.toUpperCase()) {
        return (
          <div>
            <CarouselView items={attachment.items} />
          </div>
        );
      } else if (attachment.type.toUpperCase() === messageType.IMAGE.toUpperCase()) {
        return <ImageView message={this.props.message} />;
      } else if (attachment.type.toUpperCase() === messageType.VIDEO.toUpperCase()) {
        return <VideoView media={attachment.media} />;
      } else if (attachment.type.toUpperCase() === messageType.AUDIO.toUpperCase()) {
        return <AudioView media={attachment.media} />;
      } else if (attachment.type.toUpperCase() === messageType.DOCUMENT.toUpperCase()) {
        return <DocumentView media={attachment.media} name={this.props.message.text} />;
      } else if (attachment.type.toUpperCase() === messageType.FILES.toUpperCase()) {
        return <FileView media={attachment.media} name={this.props.message.text} />;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}
