import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { ButtonGroup } from 'react-bootstrap';
import { userLogout } from '../actions/Session';
import { getLogoutPath } from '../helpers/Common';
import Loader from './Loader.js';
import { Spinner } from 'react-bootstrap';
import store from '../configuration/Store';
import { actionType } from '../constants';
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <i className="fa fa-2x fa-cog" style={{ paddingRight: 4 }} />
  </a>
));

export class AccountHeader extends Component {
  handleLogout = () => {
    this.props.userLogout(getLogoutPath());
  };
  toggleCannedMessages = e => {
    store.dispatch({ type: actionType.TOGGLE_CANNED_MESSAGES });
  };
  render() {
    let { session } = this.props;
    let user = session.user;
    return (
      <div className="account-settings-wrapper">
        <div className="account-profile-name">
          <span>{user.name || ''}</span>
        </div>
        <div className="account-profile-settings">
          <Dropdown as={ButtonGroup} className="settings-menu">
            <Dropdown.Toggle
              as={CustomToggle}
              id="dropdown-custom-componentLogouts"
            ></Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={this.props.toggleEditProfile}>Profile</Dropdown.Item>
              <Dropdown.Item onClick={this.toggleCannedMessages}>Canned Response</Dropdown.Item>
              <Dropdown.Item onClick={this.handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    session: state.session.session
  };
};

export default connect(mapStateToProps, {
  userLogout
})(AccountHeader);
