import React, { Component } from 'react';

export default class SettingsTeam extends Component {
  render() {
    let team = this.props.team;

    return (
      <div>
        <div className="settings-header">{team.name}</div>
        <div className="hand-hover">Teammates</div>
        <div className="hand-hover">Responses</div>
        <div className="hand-hover">Tags</div>
      </div>
    );
  }
}
