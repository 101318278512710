import React from 'react';
import { messageType } from '../constants';
import CarouselView from './CarouselView';
import ImageView from './ImageView';
import VideoView from './VideoView';
import AudioView from './AudioView';
import DocumentView from './DocumentView';

export default function FileView(props) {
  return props.media.map(data => {
    if (data.type.toUpperCase() === messageType.CAROUSEL.toUpperCase()) {
      return (
        <div>
          <CarouselView items={data.items} />
        </div>
      );
    } else if (data.type.toUpperCase() === messageType.IMAGE.toUpperCase()) {
      return <ImageView media={data} />;
    } else if (data.type.toUpperCase() === messageType.VIDEO.toUpperCase()) {
      return <VideoView media={data} />;
    } else if (data.type.toUpperCase() === messageType.AUDIO.toUpperCase()) {
      return <AudioView media={data} />;
    } else if (data.type.toUpperCase() === messageType.DOCUMENT.toUpperCase()) {
      return <DocumentView media={data} name={this.props.name} />;
    } else return null;
  });
}
