import React, { Component } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getConversations, setCurrentDisplay } from '../actions/Conversation';

class ConversationFilters extends Component {
  constructor(props) {
    super(props);
    this.asignedToFilterValues = this.asignedToFilterValues.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChatTypeChange = this.handleChatTypeChange.bind(this);
  }

  handleChange(eventKey, event) {
    let values = this.asignedToFilterValues();
    let filters = this.props.filters;
    filters.assignedTo = values[event.target.title];
    this.props.onChange(filters);
  }

  asignedToFilterValues = () => {
    return {
      all: { name: 'all', userId: null, text: 'Assigned To Any' },
      me: { name: 'me', userId: this.props.currentUser.id, text: 'Assigned To Me' },
      unassigned: { name: 'unassigned', userId: null, text: 'Unassigned' }
    };
  };

  handleChatTypeChange(eventKey, event) {
    let filters = this.props.filters;
    filters.chat_type = event.target.title;
    this.props.onChange(filters);
    window.gtag('event', 'conversation-filter', { label: event.target.title });
  }

  chat_type_title(chat_type) {
    if (chat_type === 'all') {
      return 'All Chats';
    } else if (chat_type === 'open') {
      return 'Open Chats';
    } else if (chat_type === 'closed') {
      return 'Closed Chats';
    }
  }

  render() {
    let values = this.asignedToFilterValues();

    return (
      <div>
        <div className="conversation_filters">
          <DropdownButton
            id="dropDown-btn"
            title={this.chat_type_title(this.props.filters.chat_type)}
            alignRight
            disabled={!this.props.chatOnly}
          >
            <Dropdown.Item title="all" eventKey={0} onSelect={this.handleChatTypeChange}>
              All Chats
            </Dropdown.Item>
            <Dropdown.Item title="open" eventKey={1} onSelect={this.handleChatTypeChange}>
              Open Chats
            </Dropdown.Item>
            <Dropdown.Item title="closed" eventKey={2} onSelect={this.handleChatTypeChange}>
              Closed Chats
            </Dropdown.Item>
          </DropdownButton>

          <DropdownButton
            title={values[this.props.filters.assignedTo.name].text}
            id="dropDown-btn"
            style={{ color: 'black', background: 'white' }}
            alignRight
          >
            {Object.values(values).map((value, index) => {
              return (
                <Dropdown.Item key={index} title={value.name} onSelect={this.handleChange}>
                  {value.text}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.session.session.user || {},
    inbox: state.session.session.inbox || {},
    currentDisplay: state.conversation.currentDisplay
  };
};

export default connect(mapStateToProps, { getConversations, setCurrentDisplay })(
  ConversationFilters
);
