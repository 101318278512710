import React from 'react';

export default class ImageView extends React.Component {
  //debugger;
  constructor(props) {
    super(props);
    this.state = {
      display: false
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(status) {
    this.setState({
      display: status
    });
  }
  render() {
    let media = this.props.message.attachment.media;
    let self = this;
    document.addEventListener('keyup', event => {
      if (event.keyCode === 27) {
        self.handleClick(false);
      }
    });

    return (
      <React.Fragment>
        <div
          id="myModal"
          className={
            this.state.display ? 'imageModal modal_display_block' : 'modal modal_display_none'
          }
        >
          {media && media.url && (
            <img className="modal-content" src={media.url} alt="modal content" />
          )}

          <div className="headerViewBox">
            <span className="closeView" onClick={e => this.handleClick(false)}></span>
            <a className="downloadView" href={media ? media.url : ''}></a>
          </div>
          <div className="footerViewBox">
            <span className="titleView">
              <span className="is_title">{media ? this.props.message.text : ''}</span>
            </span>
          </div>
        </div>
        <div className="imageAttachment">
          <img
            src={media ? media.url : ''}
            onClick={e => this.handleClick(true)}
            alt={'attachment'}
          />
        </div>
      </React.Fragment>
    );
  }
}
