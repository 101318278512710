import React, { Component } from 'react';
import { PROFILE, RESPONSE, TAGS, TEAMMATES, TEAMS } from '../screens/Settings';
import './index.css';
import SettingsTeamContainer from './SettingsTeamContainer';

import { connect } from 'react-redux';
import { getTeamsAsync } from '../actions/SettingAction';

class SettingsContainer extends Component {
  constructor(props) {
    super(props);

    // this.state = {
    //   teams: []
    // };

    this.onHandle = this.onHandle.bind(this);
  }

  componentDidMount() {
    this.props.getTeamsAsync();
  }

  onHandle(TYPE) {
    this.props.setComponent(TYPE);
  }

  render() {
    return (
      <div className="settings-container">
        <div>
          <div className="settings-header">SETTINGS</div>
          <div className="hand-hover" onClick={() => this.onHandle(PROFILE)}>
            My Profile
          </div>
          <div className="hand-hover" onClick={() => this.onHandle(TAGS)}>
            My Tags
          </div>
          <div className="hand-hover" onClick={() => this.onHandle(RESPONSE)}>
            My Response
          </div>
        </div>

        <div>
          <div className="settings-header">COMPANY</div>
          <div className="hand-hover" onClick={() => this.onHandle(TEAMS)}>
            Teams
          </div>
          <div className="hand-hover" onClick={() => this.onHandle(TEAMMATES)}>
            Teammates
          </div>
        </div>

        <SettingsTeamContainer teams={this.props.teams} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { teams: state.teams.teams };
};

export default connect(
  mapStateToProps,
  { getTeamsAsync }
)(SettingsContainer);
