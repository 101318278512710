import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import {
  FormLabel,
  FormGroup,
  Col,
  FormControl,
  Form,
  OverlayTrigger,
  Tooltip,
  Button,
  Spinner
} from 'react-bootstrap';
import { clearUserUpdate, updateUser } from '../actions/Session';
import { getUserUpdatePath } from '../helpers/Common';
import { session } from '../reducers/Session';

let initState = {
  imageFile: null,
  image: '',
  isImageChanged: false,
  message: { hasMessage: false, text: null, color: '' },
  name: '',
  nameError: null,
  password: '',
  confirmPassword: '',
  passwordError: ''
};

export class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initState };
    this.clearTimeout = null;
  }
  onImageChange = event => {
    if (event.target.files && event.target.files[0]) {
      this.setState({ imageFile: event.target.files[0] });
      let reader = new FileReader();
      reader.onload = e => {
        this.setState({ image: e.target.result, isImageChanged: true });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  componentDidMount() {
    let { session } = this.props;
    let user = session.user;
    if (user.image) {
      this.setState({ image: user.image, name: user.name });
    }
  }
  componentWillUnmount() {
    this.props.clearUserUpdate();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.userUpdate.loading &&
      !nextProps.userUpdate.loading &&
      nextProps.userUpdate.success
    ) {
      let state = { ...initState };
      let user = nextProps.session.user;
      state.image = user.image;
      state.name = user.name;
      this.setState(state);
    }
  }
  getUser = () => {
    return this.props.session.user;
  };
  handleNameChange = e => {
    let state = { name: e.target.value };
    if (this.state.nameError) state.nameError = null;
    this.setState(state);
  };
  clearMessageTimeout = () => {
    this.clearTimeout = setTimeout(() => {
      this.setState({ message: { hasMessage: false, text: null, color: '' } });
      this.clearTimeout = null;
    }, 4000);
  };
  handleSubmit = e => {
    e.preventDefault();
    let { session } = this.props;
    let user = session.user;
    let { name, password, confirmPassword, imageFile, isImageChanged } = this.state;
    let params = [];
    if (!name || !name.trim()) {
      this.setState({ nameError: 'InValid Name' });
      this.props.clearUserUpdate();
      return;
    }
    if (password || confirmPassword) {
      if (password.trim().length < 6 || confirmPassword.trim().length < 6) {
        this.setState({ passwordError: 'Password should be 6 characters minimum' });
        this.props.clearUserUpdate();
        return;
      }
      if (password !== confirmPassword) {
        this.setState({ passwordError: 'Passwords are not matching...' });
        this.props.clearUserUpdate();
        return;
      } else {
        params.push({ key: 'password', value: password });
      }
    }
    if (isImageChanged) params.push({ key: 'image', value: imageFile });
    if (name !== user.name) params.push({ key: 'name', value: name });
    if (!params.length) {
      if (this.clearTimeout) clearTimeout(this.clearTimeout);
      this.clearMessageTimeout();
      this.props.clearUserUpdate();
      this.setState({
        message: { hasMessage: true, text: 'No changes to save...', color: 'black' }
      });
    } else {
      let form_data = new FormData();
      params.map(p => {
        form_data.append(p.key, p.value);
      });
      if (this.state.message && this.state.message.hasMessage) {
        this.setState({ message: { hasMessage: false, text: null, color: '' } });
        this.clearTimeout = null;
        if (this.clearTimeout) clearTimeout(this.clearTimeout);
      }
      this.props.updateUser(getUserUpdatePath, form_data);
    }
  };
  handlePassChange = e => {
    let state = { [e.target.name]: e.target.value };
    if (this.state.passwordError) state.passwordError = null;
    this.setState(state);
  };
  handleBackdropClick = e => {};
  render() {
    let { session, userUpdate } = this.props;
    let user = session.user;
    return (
      <div className="user-settings-popup">
        <Modal
          show={this.props.show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={this.handleBackdropClick}
          dialogClassName="user-settings-popup-modal"
        >
          <div className="user-settings-profile-wrapper">
            <div className="close-profile-settings">
              <i
                className="fa fa-lg fa-times-circle"
                aria-hidden="true"
                onClick={this.props.onClose}
                style={{ color: 'rgba(53, 15, 15,0.5)', fontSize: '25px' }}
              ></i>
            </div>

            <div className="modal-header">
              <span style={{ marginTop: '15px', fontSize: '18px' }}>Profile</span>
            </div>
            <div className="profile-input-div profile-image">
              <div className="image-upload">
                <OverlayTrigger
                  placement={'right'}
                  overlay={<Tooltip id={`tooltip-${'right'}`}>Upload profile picture</Tooltip>}
                >
                  <label htmlFor="profile-image-input">
                    <img className="profile-image-preview" src={this.state.image} />
                  </label>
                </OverlayTrigger>

                <input
                  id="profile-image-input"
                  type="file"
                  accept="image/*"
                  onChange={this.onImageChange}
                />
              </div>
            </div>
            <div className="profile-input-div profile-email">
              <FormGroup controlId="formHorizontalEmail" className="form-group-email">
                <Col sm={12}>Email Address</Col>
                <Col sm={12}>
                  <FormControl
                    type="email"
                    value={user.email}
                    placeholder="Email Address"
                    onChange={() => {}}
                    disabled
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="profile-input-div profile-name">
              <FormGroup controlId="formHorizontalEmail" className="form-group-other">
                <Col sm={12}>Name</Col>
                <Col sm={12}>
                  <FormControl
                    type="text"
                    value={this.state.name}
                    placeholder="Name"
                    onChange={this.handleNameChange}
                  />
                  <Form.Text className="text-danger">{this.state.nameError}</Form.Text>
                </Col>
              </FormGroup>
            </div>
            <div className="profile-input-div profile-password">
              <FormGroup controlId="formHorizontalPassword" className="form-group-other">
                <Col sm={12}>New Password</Col>
                <Col sm={12}>
                  <FormControl
                    type="password"
                    name="password"
                    value={this.state.password}
                    placeholder="New Password"
                    onChange={this.handlePassChange}
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="profile-input-div profile-confirm-password">
              <FormGroup controlId="formHorizontalPassword" className="form-group-other">
                <Col sm={12}>Confirm New Password</Col>
                <Col sm={12}>
                  <FormControl
                    type="password"
                    name="confirmPassword"
                    value={this.state.confirmPassword}
                    placeholder="Confirm New Password"
                    onChange={this.handlePassChange}
                  />
                  <Form.Text className="text-danger">{this.state.passwordError}</Form.Text>
                </Col>
              </FormGroup>
            </div>
            <div className="profile-input-div profile-name">
              <FormGroup className="form-group-other">
                <Col sm={12}>
                  <div className="text-center">
                    {userUpdate.loading && <Spinner animation="grow" variant="primary" />}
                    {this.state.message && this.state.message.hasMessage ? (
                      <p>{this.state.message.text}</p>
                    ) : userUpdate.error ? (
                      <p style={{ color: 'red' }}>{userUpdate.error}</p>
                    ) : (
                      userUpdate.message && <p style={{ color: 'green' }}>{userUpdate.message}</p>
                    )}
                  </div>
                  <button
                    className={
                      'update-profile-button ' +
                      (userUpdate.loading ? 'update-button-disabled' : '')
                    }
                    onClick={this.handleSubmit}
                    disabled={userUpdate.loading}
                  >
                    Update
                  </button>
                </Col>
              </FormGroup>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    session: state.session.session,
    userUpdate: state.session.userUpdate
  };
};

export default connect(mapStateToProps, { updateUser, clearUserUpdate })(EditProfile);
