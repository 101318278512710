import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { teams, createdTeams, editTeam, teammates } from './SettingReducer';
import {
  session,
  firebaseSignin,
  users,
  outIntegrations,
  inboxes,
  currentCustomer
} from './Session';
import {
  conversation,
  message,
  activeConversation,
  activeHeaderConversation,
  customerOrders,
  searchProducts,
  unansweredCount,
  storeData,
  cannedMessages
} from './Conversation';

export default combineReducers({
  routing: routerReducer,
  teams,
  createdTeams,
  editTeam,
  teammates,
  session,
  firebaseSignin,
  users,
  conversation,
  message,
  activeConversation,
  activeHeaderConversation,
  outIntegrations,
  inboxes,
  customerOrders,
  searchProducts,
  currentCustomer,
  storeData,
  cannedMessages
});
