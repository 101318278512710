import React from 'react';

const Actions = props => {
  let { actions } = props;
  if (actions != null) {
    return (
      <div className="actions">
        {actions.map((action, index) => (
          <div key={index} className="bot-actions">
            {action.text}
          </div>
        ))}
      </div>
    );
  } else {
    return null;
  }
};
export default Actions;
