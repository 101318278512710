import React from 'react';
import { Carousel } from 'react-bootstrap';
import { action } from '../javascripts/models/Message';

export default class ControlledCarousel extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      index: 0,
      direction: null
    };
  }

  handleSelect(selectedIndex, e) {
    // alert(`selected=${selectedIndex}, direction=${e.direction}`);
    this.setState({ index: selectedIndex });
  }
  titleCase(str) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      if (splitStr[i].length > 1) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }
  // createItems(){
  //     <div className = "carousel">
  //
  //     </div>
  // }
  lengthAdjust(str,length) {
    if (str && str.length > length) {
      return str.slice(0, length) + '...';
    } else {
      return str;
    }
  }
  getActionsOfTypeURL(actions){
    return actions && actions.map(action=>{
      if(action.type=='URL'){
        return <div className="action-type-btns">
                <a href={action.data.url} target="_blank">{action.text}</a>
              </div>
      }
    })
  }
  render() {
    const { index, direction } = this.state;
    let { items } = this.props;
    if (items != null && items.length > 0) {
      return (
        <div>
          <div className="content-center">
            <Carousel
              wrap={false}
              className="carousel"
              activeIndex={index}
              direction={direction}
              onSelect={this.handleSelect}
            >
              {items.map((item, index) => (
                <Carousel.Item key={index}>
                  <div className="ccImage">
                    <img className="fit-image" src={item.media && item.media.url} alt={''} />
                  </div>
                  <div className="carousel-caption">
                    <h2 className="carousel-title">
                      {this.lengthAdjust(this.titleCase(item.title,20))}
                    </h2>
                    <p>{this.lengthAdjust(item.subtitle,50)}</p>
                  </div>
                  {this.getActionsOfTypeURL(item.actions)}
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      );
    }
  }
}
