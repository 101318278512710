import React from 'react';
import { Button } from 'react-bootstrap';
import { ProfileImage } from '../helpers/ImageMapper';
import './index.css';
export default class SlugContainer extends React.Component {
  renderComponent(inboxes, handleClick) {
    return inboxes.map((inbox, index) => {
      return (
        <div className="flex-container well" key={index}>
          <div>
            <ProfileImage name="InboxIntegration" style={{ width: '50px', height: '50px' }} />
          </div>
          <div>
            <p>{inbox.name}</p>
          </div>
          <div>
            <Button onClick={slug => handleClick(inbox.slug)} variant={'outline-primary'}>
              Launch
            </Button>
          </div>
        </div>
      );
    });
  }
  render() {
    return (
      <div className="container slug-container">
        <p>Select one of the inboxes to continue:</p>
        {this.renderComponent(this.props.inboxes, this.props.handleClick)}
      </div>
    );
  }
}
