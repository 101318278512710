import React, { Component } from 'react';
import { Image, FormLabel, FormControl, Button } from 'react-bootstrap';
import Switch from './switch/Switch';

export default class Teammate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      userName: '',
      email: '',
      isAdmin: false
    };

    this.handleNameChange = this.handleNameChange.bind(this);

    this.handleUserNameChange = this.handleUserNameChange.bind(this);

    this.setAdmin = this.setAdmin.bind(this);
  }

  componentDidMount() {
    let teammate = this.props.teammate;
    this.setState({
      name: teammate.name,
      userName: teammate.userName,
      email: teammate.email,
      isAdmin: teammate.admin
    });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handleUserNameChange(e) {
    this.setState({ userName: e.target.value });
  }

  setAdmin(isAdmin) {
    this.setState({ isAdmin: isAdmin });
  }

  render() {
    return (
      <div className="Overview">
        <Image
          height={120}
          width={120}
          src="http://www.personalbrandingblog.com/wp-content/uploads/2017/08/blank-profile-picture-973460_640-300x300.png"
          circle
        />

        <div className="overview-label">
          <FormLabel>Full Name</FormLabel>
          <FormControl
            type="text"
            value={this.state.name}
            placeholder="Teammate Full Name"
            onChange={this.handleNameChange}
          />

          <br />

          <FormLabel>User Name</FormLabel>
          <FormControl
            type="text"
            value={this.state.userName}
            placeholder="Teammate User Name"
            onChange={this.handleUserNameChange}
          />
          <br />

          <FormLabel>Email</FormLabel>
          <FormControl type="text" value={this.state.email} disabled true />
          <br />

          <br />

          <div className="settings-object-list-item primary">
            <div>This will be a company administrator</div>

            <div className="end-data-container">
              <Switch setAdmin={this.setAdmin} isAdmin={this.state.isAdmin} />
            </div>
          </div>

          <Button variant="primary">Save</Button>
        </div>
      </div>
    );
  }
}
