import React, { Component } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { connect } from 'react-redux';
import Collapsible from 'react-collapsible';
import './index.css';
import { getCustomerOrdersUrl, searchEcommerceProductsUrl } from '../helpers/Common.js';
import {
  getCustomerOrders,
  getSearchProducts,
  setProductsToCarousel
} from '../actions/Conversation';
import { getConversationUser } from '../actions/Session';
import Loader from './Loader';

class StoreContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ecommerceVariable: 'Select     ',
      showProductSearch: false,
      customerOrdersSearch: false,
      customerOrderSearchText: '',
      searchProduct: '',
      curUser: null,
      showSearch: false
    };
    this.showSearchBox = this.showSearchBox.bind(this);
    this.isEcommercePresent = this.isEcommercePresent.bind(this);
    this.shouldDisplayProductSearch = this.shouldDisplayProductSearch.bind(this);
    this.getSearchProducts = this.getSearchProducts.bind(this);
    this.getCustomerOrders = this.getCustomerOrders.bind(this);
    this.shouldDisplayProducts = this.shouldDisplayProducts.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.conversation !== this.props.conversation) {
      this.setState({
        showSearch: false,
        customerOrdersSearch: false,
        showProductSearch: false,
        ecommerceVariable: 'Select',
        products: {},
        orders: {},
        searchProduct: ''
      });
    }
    // users
    if (
      this.props.conversation !== null &&
      this.props.conversation !== undefined &&
      this.props.conversation &&
      prevProps.conversation !== null &&
      prevProps.conversation !== undefined &&
      prevProps.conversation &&
      prevProps.conversation.unique_id !== this.props.conversation.unique_id &&
      this.props.conversation.hasOwnProperty('data')
    ) {
      this.props.getConversationUser(this.props.conversation.data.userId);
    }
  }
  getSearchProducts() {
    const url = searchEcommerceProductsUrl(
      this.props.conversation.unique_id,
      this.state.searchProduct
    );
    this.props.getSearchProducts(url, this.props.apiToken);
  }
  getCustomerOrders(eventKey, e) {
    this.setState({
      ecommerceVariable: e.target.title,
      showProductSearch: false,
      customerOrdersSearch: true
    });
    this.props.getCustomerOrders(
      getCustomerOrdersUrl(this.props.conversation.unique_id),
      this.props.apiToken
    );
  }
  showSearchBox(eventKey, e) {
    this.setState({
      showProductSearch: true,
      customerOrdersSearch: false,
      ecommerceVariable: e.target.title
    });
    this.getSearchProducts();
  }
  isEcommercePresent(conversation) {
    if (
      conversation !== undefined &&
      conversation.data !== undefined &&
      conversation.data.ecommerce !== undefined
    ) {
      return (
        <div className="store-search" style={{ display: 'flex' }}>
          <DropdownButton
            title={this.state.ecommerceVariable}
            id="dropDown-btn"
            style={{ color: 'black' }}
          >
            <Dropdown.Item eventKey={0} title="Products" onSelect={this.showSearchBox}>
              Products
            </Dropdown.Item>
            <Dropdown.Item
              eventKey={1}
              title="Orders"
              onSelect={this.getCustomerOrders}
              disabled={conversation.data.ecommerce.customer ? false : true}
            >
              Orders
            </Dropdown.Item>
          </DropdownButton>
          {this.shouldDisplayProductSearch()}
        </div>
      );
    }
    return null;
  }
  handleProductSearch = e => {
    this.setState({ searchProduct: e.target.value });
    if (!e.target.value) {
      this.getSearchProducts();
    }
  };

  checkKey(e) {
    if (e.target.keyCode === 13) {
      this.getSearchProducts();
    }
  }
  searchForProducts(e) {
    this.getSearchProducts();
    e.preventDefault();
  }

  shouldDisplayProductSearch() {
    return (
      <div className="store-search-box">
        <input
          autoFocus={true}
          className="form-control"
          type="text"
          value={
            this.state.showProductSearch
              ? this.state.searchProduct
              : this.state.customerOrderSearchText
          }
          placeholder="Search"
          onChange={
            this.state.showProductSearch ? this.handleProductSearch : this.handleOrderSearch
          }
          onKeyDown={this.checkKey}
        />
      </div>
    );
  }
  handleOrderSearch = e => {
    this.setState({
      customerOrderSearchText: e.target.value
    });
  };

  titleCase(str) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      if (splitStr[i].length > 1) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
    }
    return splitStr.join(' ');
  }
  lengthAdjust(str, length) {
    if (str.length > length) {
      return str.slice(0, length) + '...';
    }
    return str;
  }

  insertProductAsCarousel = product => {
    let productsCarousel = this.props.productsForCarousel || [];
    if (!productsCarousel.includes(product)) {
      productsCarousel.push(product);
      this.props.setProductsToCarousel(productsCarousel);
    }
  };
  getFilteredProducts = () => {
    let { products } = this.props.products;
    let { searchProduct } = this.state;
    let filteredProducts = products || [];
    let allProducts = products;
    if (searchProduct) {
      filteredProducts = [];
      for (let i = 0; i < allProducts.length; i++) {
        if (searchProduct.toLowerCase() == allProducts[i].name.toLowerCase()) {
          filteredProducts.push(allProducts[i]);
        }
      }
    }
    console.log(filteredProducts);
    return filteredProducts;
  };

  shouldDisplayProducts() {
    let filteredProducts = this.getFilteredProducts();
    if (
      this.props.products &&
      !this.props.products.products &&
      this.props.products.status !== 'success' &&
      this.props.products.status !== 'error' &&
      this.state.ecommerceVariable === 'Products'
    ) {
      return <Loader />;
    }

    if (
      this.props.products !== undefined &&
      this.props.products.status === 'success' &&
      this.state.ecommerceVariable === 'Products'
    ) {
      return (
        <div className="products-parent">
          {filteredProducts && filteredProducts.length > 0 ? (
            filteredProducts.map(product => {
              return (
                <div className="product-box" key={product.id}>
                  <div className="product-img">
                    <img src={product.imageUrl} />
                  </div>
                  <div className="product_details">
                    <article className="product-name">{product.name}</article>
                    <article
                      className="product-price"
                      style={{ marginTop: '6px', marginBottom: '8px' }}
                    >
                      <span className="qty">
                        Qty <b>{product.variants[0].stockQuantity}</b>
                      </span>
                      <span className="price" style={{ position: 'relative', left: '35px' }}>
                        {' '}
                        Price <b>$ {product.variants[0].price}</b>
                      </span>
                    </article>
                    <span
                      className="add-product-message"
                      onClick={() => this.insertProductAsCarousel(product)}
                    >
                      Add to Message
                    </span>
                  </div>
                  <br />
                </div>
              );
            })
          ) : (
            <div className="products_error_message p-1">No searched products</div>
          )}
        </div>
      );
    } else if (
      this.props.products &&
      this.state.ecommerceVariable === 'Products' &&
      this.props.products.status === 'error'
    ) {
      return <span className="products_error_message">{this.props.products.error}</span>;
    }
    return null;
  }
  getFilteredOrders = () => {
    let { orders } = this.props.orders;
    let { customerOrderSearchText } = this.state;
    let filteredOrders = orders || [];
    let allOrders = orders;

    if (customerOrderSearchText) {
      filteredOrders = [];
      for (let i = 0; i < allOrders.length; i++) {
        let slicedString = allOrders[i].id.toString().slice(0, customerOrderSearchText.length);

        if (customerOrderSearchText == slicedString) {
          filteredOrders.push(allOrders[i]);
        }
        allOrders[i].lineItems.forEach(item => {
          if (
            customerOrderSearchText.toLowerCase() ===
            item.name.slice(0, customerOrderSearchText.length).toLowerCase()
          ) {
            filteredOrders.push(allOrders[i]);
            return;
          }
        });
      }
    }
    return filteredOrders;
  };

  getSession = time => {
    let date = new Date(time).toUTCString();
    return date.toString().replace('GMT', '');
  };

  getVisit = time => {
    let arrayVisit = [];
    let date = new Date(time).toUTCString();
    arrayVisit = date
      .toString()
      .replace('GMT', '')
      .split(',')[1]
      .split(' ');
    return (
      <span className="order-text" title="Order Created Date">
        Date:{' '}
        <b>
          {arrayVisit[2]} {arrayVisit[1]}
          {', '}
          {arrayVisit[3]}
        </b>
      </span>
    );
  };

  shouldDisplayOrders() {
    let filteredOrders = this.getFilteredOrders();
    if (
      this.props.orders.status !== 'success' &&
      this.props.orders.status !== 'error' &&
      filteredOrders.length == 0 &&
      this.state.ecommerceVariable === 'Orders'
    ) {
      return <Loader />;
    }

    if (
      this.props.orders !== undefined &&
      this.props.orders.status === 'success' &&
      this.state.ecommerceVariable === 'Orders'
    ) {
      return (
        <div className="products-parent">
          {filteredOrders && filteredOrders.length > 0 ? (
            filteredOrders.map(order => {
              return (
                <div className="each-order p-3 mb-0" key={Date.now() + order.id}>
                  <div className="order-mini-details">
                    <span className="order-subtext" title="Order ID">
                      <b>#{order.id}</b>
                    </span>
                  </div>
                  <div className="order-mini-details">
                    <span className="order-text" title="Order Created Date">
                      {order.createdAt ? this.getVisit(order.createdAt) : 'Unknown'}
                    </span>
                    <span className="order-value" title="Total Price">
                      Amount:{' '}
                      <b>
                        {order.currency === 'USD'
                          ? `$${order.totalPrice}`
                          : `${order.currency}${order.totalPrice}`}
                      </b>
                    </span>
                  </div>
                  {order.lineItems && order.lineItems.length > 0
                    ? order.lineItems.map(eachItem => (
                        <div className="each-order-item" key={eachItem.productId}>
                          <div className="order-image">
                            <img
                              src={
                                eachItem.imageUrl
                                  ? eachItem.imageUrl
                                  : require('../public/icons/shoppingBag.svg')
                              }
                              alt=""
                            />
                          </div>
                          <div className="each-item-details">
                            <article className="product-name">{eachItem.name}</article>
                            <article className="product-price" style={{ marginTop: '6px' }}>
                              Qty <b style={{ marginRight: '30px' }}> {eachItem.quantity}</b>
                              Price{' '}
                              <b>
                                {order.currency === 'USD'
                                  ? `$${eachItem.price}`
                                  : `${order.currency}${eachItem.price}`}{' '}
                              </b>
                            </article>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              );
            })
          ) : (
            <div className="products_error_message p-1">No Orders</div>
          )}
        </div>
      );
    } else if (this.props.orders !== undefined && this.props.orders.status === 'error') {
      return <span className="products_error_message">{this.props.orders.error}</span>;
    }
    return null;
  }

  render() {
    const { conversation } = this.props;

    return (
      <div className=" storeContainer">
        {this.isEcommercePresent(conversation)}
        {this.state.showProductSearch || this.searchProduct ? (
          <div className="Product-Order-Head">
            Manage Products
            {this.shouldDisplayProducts()}
          </div>
        ) : (
          ''
        )}

        {this.state.customerOrdersSearch || this.customerOrderSearchText ? (
          <div className="Product-Order-Head">
            Manage Orders
            {this.shouldDisplayOrders()}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    conversation: state.activeConversation.conversation,
    products: state.searchProducts.products,
    orders: state.customerOrders.orders,
    users: state.users.users,
    curUser: state.currentCustomer.curUser,
    apiToken: state.session.session.api_token,
    productsForCarousel: state.message.addedProductsToCarousel
  };
};
export default connect(mapStateToProps, {
  getSearchProducts,
  getCustomerOrders,
  getConversationUser,
  setProductsToCarousel
})(StoreContainer);
