import React, { Component } from 'react';
import Assign from './Assign.js';
import { connect } from 'react-redux';
import { Tooltip } from 'react-bootstrap';
import { modifyConversationStatus } from '../actions/Conversation';

class ChatDetailHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conversation: props.conversation
    };
    this.modifyConversationClosedStatus = this.modifyConversationClosedStatus.bind(this);
  }

  modifyConversationClosedStatus() {
    const status = this.state.conversation.status === 'open' ? 'closed' : 'open';
    this.props.modifyConversationStatus(this.state.conversation, status);
  }
  tooltip(id, tooltip) {
    return <Tooltip id={id}>{tooltip}</Tooltip>;
  }
  status_action_name(str) {
    return str === 'open' ? 'Mark as Closed' : 'Reopen';
  }

  // TODO : whats the purpose of this ?
  static getDerivedStateFromProps(props, state) {
    if (props.uniqueId !== state.uniqueId) {
      return {
        conversation: props.conversation
      };
    }
    return null;
  }

  render() {
    return (
      <div>
        <div className="detail-header">
          <div className="glyphicons">
            {/* <OverlayTrigger overlay={this.tooltip('block', 'Mark as spam')} placement="bottom">
              <img src="/error-advise (1).svg" className="chatIcons" alt={'chat icon'} />
            </OverlayTrigger> */}
            {/* <OverlayTrigger overlay={this.tooltip('snooze', 'Snooze')} placement="bottom">
              <img src="/time (2).svg" className="chatIcons" alt={'chat icon'} />
            </OverlayTrigger> */}
            {/* {console.log('status', this.state.conversation.status)} */}
            {['open', 'closed'].includes(this.state.conversation.status) && (
              <div className="close-conversation" onClick={this.modifyConversationClosedStatus}>
                <img
                  src={
                    this.state.conversation.status === 'open'
                      ? require('../public/icons/closed.png')
                      : require('../public/icons/open.png')
                  }
                  className="closed-mark"
                  alt="chat icon"
                />
                <span className="mark-status-text">
                  {this.status_action_name(this.state.conversation.status)}
                </span>
              </div>
            )}
          </div>

          <div className="assign">
            <article className="assignee-sub-text">Assignee :</article>
            <Assign conversation={this.state.conversation} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { modifyConversationStatus })(ChatDetailHeader);
