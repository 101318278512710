import React, { Component } from 'react';

export default class Team extends Component {
  render() {
    let team = this.props.team;

    return (
      <div>
        <div className="team" onClick={() => this.props.handleEdit(team)}>
          {team.name}
        </div>
      </div>
    );
  }
}
