import React from 'react';

const MyPopover = props => {
  if (props.show) {
    return (
      <div className="popOver">
        <div className="popoverHeader">
          <h2 className={'popover-close'}>
            {props.title}
            <span onClick={props.handleClick}>X</span>
          </h2>
        </div>
        <div className="popoverContent">{props.children}</div>
      </div>
    );
  }
  return null;
};
export default MyPopover;
