import React, { Component } from 'react';
import './App.css';
import Dashboard from './screens/Dashboard';
import Home from './screens/Home';
import { Route } from 'react-router-dom';
import Settings from './screens/Settings';

import { init as firestoreInit } from './javascripts/Firestore';
// import Navigation from './components/Navigation';

class App extends Component {
  constructor(props) {
    super(props);
    firestoreInit();
  }

  render() {
    return (
      <div className="App">
        <div className="box">
          {/* <div className="row header">
            <Navigation />
          </div> */}
          <div className="row content parent-height">
            <main className="parent-height">
              <Route exact path="/" component={Home} />
              <Route exact path="/:slug" component={Dashboard} />
              <Route exact path="/settings/account" component={Settings} />
            </main>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
