import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ConversationContainer from '../components/ConversationContainer';
import MessageDetailContainer from '../components/MessageDetailContainer';
import { firebaseMessaging } from '../javascripts/Firestore';
import './index.css';
import firebase from 'firebase';
import Login from '../components/Login';
import { connect } from 'react-redux';
import {
  firebaseSignIn,
  getOutIntegrations,
  getSession,
  getUsers,
  getCannedMessages,
  setSession,
  setPushNotifications
} from '../actions/Session';
import { updateStoreStatus, loadStoreStatus } from '../actions/Conversation';
import AccountContainer from '../components/AccountContainer.js';
import StoreContainer from '../components/StoreContainer';
import Loader from '../components/Loader.js';
import {
  cannedMessagesPath,
  getStoreKey,
  loginPath,
  outIntegrationsPath,
  sessionPath,
  setSlug,
  setStoreKey,
  usersPath
} from '../helpers/Common';
import { Button } from 'react-bootstrap';
import AccountHeader from '../components/AccountHeader';
import EditProfile from '../components/EditProfile';
import CannedResponseModal from '../components/CannedResponse/CannedResponseModal';
import EditCannedResponse from '../components/CannedResponse/EditCannedResponse';
import SubscriptionNotification from '../components/SubscriptionNotification';

// session calls handling login details
class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLogin: false,
      isLogin: false,
      tabType: 'userInfo',
      loginClicked: false,
      showEditProfile: false
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.getSession = this.getSession.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.setLogin = this.setLogin.bind(this);
  }

  callSession() {
    let url = sessionPath();
    this.getSession(url, null);
  }

  getSession(url, params, isNewLogin) {
    this.props.getSession(url, params, isNewLogin);
  }

  handleLogin(params) {
    let url = loginPath();
    this.getSession(url, params, true);
    this.setState({ loginClicked: true });
  }

  handleClose() {
    this.setState({ showLogin: false });
  }

  handleShow() {
    this.setState({ showLogin: true });
  }

  setLogin(isLogin) {
    this.setState({ isLogin: isLogin, showLogin: !isLogin });
    if (isLogin) {
      this.afterLogin();
      this.loadOutIntegrations();
      this.loadTeamMembers();
      this.props.getCannedMessages(cannedMessagesPath());
      this.loadStoreStatus();
    }
  }

  loadStoreStatus() {
    this.props.loadStoreStatus();
  }

  loadTeamMembers() {
    // Intension is to fetch only once for app

    this.props.getUsers(usersPath());
  }

  loadOutIntegrations() {
    this.props.getOutIntegrations(outIntegrationsPath());
  }

  componentDidMount() {
    if (this.props.location.search) {
      let AdminPasswordEditUrl = `${process.env.REACT_APP_APP_PATH}/users/password/edit/`;
      window.location.href = `${AdminPasswordEditUrl}${this.props.location.search}&inbox=${this.props.match.params.slug}`;
    }
    let _slug = this.props.match.params.slug;
    if (_slug) {
      // setting values of slug and store keys
      setSlug(_slug);
      setStoreKey(_slug);
      this.callSession();
    }
  }
  afterLogin = () => {
    this.props.setPushNotifications();
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    let { session, error, isFirebaseLoggedIn } = nextProps;

    if (error && error.status === 'error') {
      this.setLogin(false);
      this.setState({ showLogin: true });
    } else {
      if (session && session.token) {
        // setter
        var sessionDetail = session;
        sessionDetail.time = new Date();
        localStorage.setItem(getStoreKey(), JSON.stringify(sessionDetail));
        if (isFirebaseLoggedIn === true && !this.props.isFirebaseLoggedIn) {
          this.setLogin(true);
        } else if (!this.state.isLogin) {
          // new base condition added
          // firebase login check

          var user = firebase.auth().currentUser;
          if (user !== undefined && user !== null) {
            if (user.displayName === session.user.name) {
              // User is signed in

              this.setLogin(true);
            } else {
              this.props.firebaseSignIn(session.token);
            }
          } else {
            this.props.firebaseSignIn(session.token);
          }
        }
      }
    }
  }
  handleTabClick = type => {
    this.setState({ tabType: type });
  };
  toggleEditProfile = () => {
    this.setState({ showEditProfile: !this.state.showEditProfile });
  };
  render() {
    let isStoreOffline = this.props.store ? this.props.store.offline : true;
    let { editCannedResponse, editCannedResponseId, showCannedResponse } = this.props;
    if (this.state.isLogin && !this.state.showLogin) {
      return (
        <div className="dashboard">
          {this.props.logoutLoading && (
            <div className="logout-loading-div">
              <Loader />
            </div>
          )}
          {this.state.showEditProfile && (
            <EditProfile
              onClose={this.toggleEditProfile}
              show={this.state.showEditProfile}
            ></EditProfile>
          )}
          {showCannedResponse && <CannedResponseModal />}
          {editCannedResponse && (
            <EditCannedResponse type={editCannedResponseId || 'new'} id={editCannedResponseId} />
          )}
          <Container className="container-fluid inbox-main-section">
            {!this.props.closeNotification && this.props.session && this.props.session.notice && (
              <SubscriptionNotification notice={this.props.session.notice} />
            )}
            <Row className="parent-height">
              <Col className="conversation-column parent-height">
                <ConversationContainer isLogin={this.state.isLogin} />
              </Col>

              <Col className="message-column">
                <MessageDetailContainer isLogin={this.state.isLogin} />
              </Col>
              <Col className="account-column">
                <div className="account-container-top">
                  <div className="account-settings-container">
                    <AccountHeader toggleEditProfile={this.toggleEditProfile}></AccountHeader>
                  </div>
                  <div className="online-filter">
                    <span
                      style={{
                        marginRight: '10px',
                        color: '#7480ff',
                        fontSize: '13px',

                        position: 'relative',
                        top: '-3px',
                        letterSpacing: '1px'
                      }}
                    >
                      Online
                    </span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={isStoreOffline}
                        onChange={() => {
                          this.props.updateStoreStatus();
                        }}
                      />
                      <span
                        className={
                          'slider round ' + (isStoreOffline ? 'store-offline' : 'store-online')
                        }
                      ></span>
                    </label>
                    <span
                      style={{
                        marginLeft: '10px',
                        color: '#686c7e',
                        fontSize: '13px',

                        position: 'relative',
                        top: '-3px',
                        letterSpacing: '1px'
                      }}
                    >
                      Offline
                    </span>
                  </div>
                  <div className="user-store-tabs">
                    <div
                      className={this.state.tabType === 'userInfo' ? 'tab-item active' : 'tab-item'}
                      onClick={() => this.handleTabClick('userInfo')}
                    >
                      User Info
                    </div>
                    <div
                      className={
                        this.state.tabType === 'storeInfo' ? ' tab-item active' : ' tab-item'
                      }
                      onClick={() => this.handleTabClick('storeInfo')}
                    >
                      Store Info
                    </div>
                  </div>
                  <hr
                    style={{ width: '90%', marginLeft: '14px', position: 'relative', top: '-6px' }}
                  ></hr>

                  {this.state.tabType === 'userInfo' && <AccountContainer />}
                  {this.state.tabType === 'storeInfo' && <StoreContainer />}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (this.state.showLogin) {
      return (
        <Login
          show={this.state.showLogin}
          onHide={this.handleClose}
          handleLogin={this.handleLogin}
          loginError={this.props.error}
          loginClicked={this.state.loginClicked}
        />
      );
    } else {
      return <Loader />;
    }
  }
}

const mapStateToProps = state => {
  return {
    store: state.storeData.store,
    session: state.session.session,
    error: state.session.error,
    isFirebaseLoggedIn: state.firebaseSignin.isFirebaseLoggedIn,
    logoutLoading: state.session.logoutLoading,
    showCannedResponse: state.cannedMessages.showCannedResponse,
    editCannedResponse: state.cannedMessages.editCannedResponse,
    editCannedResponseId: state.cannedMessages.editCannedResponseId,
    closeNotification: state.session.closeNotification
  };
};

export default connect(mapStateToProps, {
  getSession,
  firebaseSignIn,
  getUsers,
  getOutIntegrations,
  setSession,
  loadStoreStatus,
  updateStoreStatus,
  setPushNotifications,
  getCannedMessages
})(Dashboard);
