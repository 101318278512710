//conversation
export const conversation = (id, data) => ({
  unique_id: id,
  data: data,
  status: data.inbox.status
});

//sender
// export const sender = (id, name, imageUrl) => ({
//     id:id,
//     name:name,
//     imageUrl: imageUrl,
// })
