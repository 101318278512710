import React, { useEffect } from 'react';
import Conversations from './Conversations';
import './index.css';
import { connect } from 'react-redux';
import { getStoreKey } from '../helpers/Common';
import { getConversations } from '../actions/Conversation';

const ConversationTabs = props => {
  const triggerConversations = () => {
    let session = JSON.parse(localStorage.getItem(getStoreKey()));
    if (session != null && session.inbox != null) {
      // Read item: console.log("session", session.inbox)
      // props.getConversations(session.inbox, null);
      //console.log(session.inbox);
    }
  };

  useEffect(() => {
    triggerConversations();
  }, []);

  return (
    <div className="full-height scroll-y overflow-hidden">
      <Conversations filters={props.filters} />
    </div>
  );
};

const mapStateToProps = state => {
  return { conversations: state.conversation.conversations };
};

export default connect(mapStateToProps, { getConversations })(ConversationTabs);
