import React from 'react';

export default function CheckBox({ checked, onClick }) {
  return (
    <div className="custom-checkbox-wrapper" onClick={onClick}>
      {checked ? (
        <i className="fa fa-check-square custom-checkbox-check-icon" aria-hidden="true"></i>
      ) : (
        <i className="fa fa-square-o custom-checkbox-box-icon" aria-hidden="true"></i>
      )}
    </div>
  );
}
