import React, { Component } from 'react';
import { FormLabel, FormControl, Button } from 'react-bootstrap';
import Switch from './switch/Switch';
import TeamsAccess from './TeamsAccess';

export default class InviteTeammate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      name: '',
      isAdmin: false
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.setAdmin = this.setAdmin.bind(this);

    this.handleNext = this.handleNext.bind(this);

    this.props.detailHeader('Invite a teammate');
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  setAdmin(isAdmin) {
    this.setState({ isAdmin: isAdmin });
  }

  handleNext() {
    this.props.detailComponent(<TeamsAccess />);
  }

  render() {
    return (
      <div>
        <div className="overview-label">
          <FormLabel>Email</FormLabel>
          <FormControl
            type="text"
            value={this.state.userName}
            placeholder="name@company.com"
            onChange={this.handleEmailChange}
          />

          <br />

          <FormLabel>Full Name</FormLabel>
          <FormControl
            type="text"
            value={this.state.userName}
            placeholder="full name"
            onChange={this.handleNameChange}
          />

          <br />

          <div className="settings-object-list-item primary">
            <div>This will be a company administrator</div>

            <div className="end-data-container">
              <Switch setAdmin={this.setAdmin} />
            </div>
          </div>

          <br />

          <Button variant="primary" onClick={this.handleNext}>
            Next
          </Button>
        </div>
      </div>
    );
  }
}
