import React, { Component } from 'react';
import Profile from './Profile';
import Teams from './Teams';
import Teammates from './Teammates';

import { PROFILE, RESPONSE, TAGS, TEAMMATES, TEAMS } from '../screens/Settings';

export default class SettingsDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      component: <Profile />,
      detailHeader: 'Profile'
    };

    this.handleHeader = this.handleHeader.bind(this);

    this.handleComponent = this.handleComponent.bind(this);
  }

  handleHeader(header) {
    this.setState({ detailHeader: header });
  }

  handleComponent(component) {
    this.setState({ component: component });
  }

  componentDidUpdate(prevProps, prevState) {
    let selectedComponent = <Profile />;
    let header = 'Profile';

    if (this.props.component === PROFILE) {
      header = 'Profile';
      selectedComponent = (
        <Profile detailHeader={this.handleHeader} detailComponent={this.handleComponent} />
      );
    } else if (this.props.component === TAGS) {
      header = 'Tags';
      selectedComponent = <div>tages</div>;
    } else if (this.props.component === RESPONSE) {
      header = 'Responses';
      selectedComponent = <div>response</div>;
    } else if (this.props.component === TEAMS) {
      header = 'Teams';
      selectedComponent = (
        <Teams detailHeader={this.handleHeader} detailComponent={this.handleComponent} />
      );
    } else if (this.props.component === TEAMMATES) {
      selectedComponent = (
        <Teammates detailHeader={this.handleHeader} detailComponent={this.handleComponent} />
      );
    } else {
      header = 'Profile';
      selectedComponent = (
        <Profile detailHeader={this.handleHeader} detailComponent={this.handleComponent} />
      );
    }
    if (prevState.component !== this.props.component)
      this.setState({ component: selectedComponent, detailHeader: header });
  }

  render() {
    return (
      <div className="settins-detail">
        <div className="settins-detail-header">
          <div>{this.state.detailHeader}</div>

          {/* <div className="end-data-container">
                        <Button variant="primary" onClick={this.handleInvite}>Next</Button>
                    </div> */}
        </div>

        {this.state.component}
      </div>
    );
  }
}
