import React from 'react';
import { updateActiveUsers } from '../actions/Conversation';
import { connect } from 'react-redux';
import { updateTime } from '../constants/index';

class Status extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: 0
    };
    this.createStatus = this.createStatus.bind(this);

    this.tick = this.tick.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  tick() {
    this.setState(prevState => ({
      time: prevState.time + 1
    }));
  }
  shouldComponentUpdate(nextProps, nextState) {
    return nextState.time >= updateTime.typing;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.setState({ time: 0 });
  }

  createStatus() {
    const activeUsers = this.props.activeUsers;
    const viewing = [];
    const typing = [];
    for (let key in activeUsers) {
      const at = new Date(activeUsers[key].at);
      const currentTime = new Date();
      const difference = (currentTime.getTime() - at.getTime()) / 1000;

      if (key === this.props.currentUser.id) {
        if (difference > updateTime.viewing) {
          this.props.updateActiveUsers(this.props.conversation, this.props.currentUser, 'viewing');
        }
      } else {
        if (difference < updateTime.viewing + 20) {
          if (activeUsers[key].type === 'typing' && difference < updateTime.typing + 10) {
            typing.push(activeUsers[key].name);
          } else {
            viewing.push(activeUsers[key].name);
          }
        }
      }
    }
    let viewingStatus;
    let typingStatus;
    if (viewing.length > 1) {
      viewingStatus = <span>{viewing.join(', ')} are viewing</span>;
    } else if (viewing.length === 1) {
      viewingStatus = <span>{viewing} is viewing</span>;
    } else {
      viewingStatus = null;
    }
    if (typing.length > 0) {
      typingStatus = <span>{typing} is typing</span>;
    } else {
      typingStatus = null;
    }
    if (typingStatus != null && viewingStatus != null) {
      return (
        <div className="collisionDetector">
          {viewingStatus}, {typingStatus}
        </div>
      );
    } else if (viewingStatus != null) {
      return <div className="collisionDetector">{viewingStatus}</div>;
    } else if (typingStatus != null) {
      return <div className="collisionDetector">{typingStatus}</div>;
    } else {
      return null;
    }
  }
  render() {
    return this.createStatus();
  }
}
const mapStateToProps = state => {
  const { conversations, conversation_ids } = state.conversation;
  let activeConversation = conversations[state.activeConversation.conversation.unique_id];
  let activeUsers;
  if (activeConversation !== null && activeConversation !== undefined) {
    activeUsers = activeConversation.data.inbox.activeUsers;
  }
  return {
    conversation: state.activeConversation.conversation,
    activeUsers,
    currentUser: state.session.session.user,
    conversations: state.conversation.conversations
  };
};
export default connect(mapStateToProps, { updateActiveUsers })(Status);
