export const getAssignee = (conversation, currentUser, integrations) => {
  let assigneeName = 'NA';

  if (conversation.data && conversation.data.outIntegration) {
    let outIntegration = conversation.data.outIntegration;
    if (!outIntegration.type) {
      return outIntegration.name || 'NA';
    }

    if (outIntegration.type === 'InboxIntegration') {
      let inbox = conversation.data.inbox;
      if (inbox && inbox.user) {
        if (currentUser && inbox.user.id === currentUser.id) {
          assigneeName = 'Me';
        } else {
          assigneeName = inbox.user.name;
        }
      } else {
        assigneeName = 'Agents';
      }
    } else {
      let integration =
        integrations &&
        integrations.find(i => i.type === outIntegration.type && i.id === outIntegration.id);
      if (integration) {
        assigneeName = integration.name || getIntegrationName(integration.type);
      } else {
        assigneeName = outIntegration.name || getIntegrationName(outIntegration.type);
      }
    }
  }

  return assigneeName;
};

export const getIntegrationName = name => {
  return name ? name.replace('Integration', '') : '';
};
